import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import theme from "./../../theme/theme.js";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from "@material-ui/core/IconButton";

import DrawerComp from "./DrawerComp";
import CollapseOnScroll from "./CollapseOnScroll";

import Back from "../../assets/images/back.svg";

import Hamburgerplaceholder from "../../assets/images/hamburgerplaceholder.svg";

import config from './../../config.js';

const useStyles = makeStyles({

  logoDiv: {
    textAlign: "center",
    [theme.breakpoints.down('md')]: {
      borderTop: `1px solid transparent`,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    flex: 8,
    maxWidth: "1260px",
  },



  logoDivText: {
    fontFamily: "Fontspring-DEMO-panton-bold",
    paddingLeft: "10px",
    paddingRight: "10px",
    fontWeight: "bold",
    lineHeight: "1",
    color: theme.palette.secondary.main
  },
});



function handleBack () {
  window.history.back(1);
  window.scrollTo(0, 0);
}

function AppBarComp(props) {
    const classes = useStyles();

    return (
        <CollapseOnScroll component={AppBar} threshold={86}>
              <Toolbar className={"appbar"}>
                {(((window.location.origin + "/") === window.location.href) || ((window.location.pathname) === "/eom-check")) &&
                <IconButton className={"placeholder-button"}>
                  <div style={{display: "flex", justifyContent: "center"}}><img alt="Hamburger" className={"app-bar-icon"} src={Hamburgerplaceholder}/></div>
                </IconButton>
                }
                {(((window.location.origin + "/") !== window.location.href) && ((window.location.pathname) !== "/eom-check")) &&
                <IconButton edge="start" color="inherit" aria-label="Menu" onClick={handleBack} className={"back-button"}>
                  <div style={{display: "flex", justifyContent: "center"}}><img alt="Back" className={"app-bar-icon--back"} src={Back}/></div>
                </IconButton>
                }
                {/*SEARCH: if search is needed in the future*/}
                {/*{props.appUserLoggedIn === true &&
                <IconButton color="primary" aria-label="Search">
                  <SearchIcon/>
                </IconButton>
                }

                {props.appUserLoggedIn === false &&
                <div className={classes.placeholderDiv}/>
                }*/}



                <div className={classes.logoDiv} onClick={()=> window.location.href = `${config.base_frontend}`}>
                  <h2 style={{marginTop: 12, letterSpacing: 4}}>bellydays</h2>
                  {/*<SvgIcon viewBox="0 0 67 67" className={"logo-appbar"}>*/}
                  {/*  <LogoIcon/>*/}
                  {/*</SvgIcon>*/}

                  {/*<br/>*/}

                  {/*<SvgIcon viewBox="0 0 260.133 27.18" className={"logo-text-appbar"}>*/}
                  {/*  <LogoIconText/>*/}
                  {/*</SvgIcon>*/}

                </div>

                {props.appUserLoggedIn === true &&
                  <DrawerComp
                      appUserLoggedIn={props.appUserLoggedIn}
                      handleLogout={props.handleLogout}
                      eomType={props.eomType}/>
                }
                {props.appUserLoggedIn === false &&
                <IconButton className={"placeholder-button"}>
                    <div style={{display: "flex", justifyContent: "center"}}><img alt="Hamburger" className={"app-bar-icon"} src={Hamburgerplaceholder}/></div>
                </IconButton>
                  }

              </Toolbar>
        </CollapseOnScroll>
    );
  }

export default AppBarComp;
