import React, {Component} from 'react';
import config from '../../config.js';
const JSONAPI_ROOT = `${config.base}/jsonapi/`;

class ReduzierenComp extends Component {
    constructor() {
        super();
        this.state = {
            eomType: "initial state",
            nodeTitle: "",
            nodeBody: "",

            nodeText1A: "",
            nodeText1B: "",
            nodeImageUrl1: "",
            image1: "",

            nodeText2: "",
            nodeImageUrl2: "",
            image2: "",

            nodeText3: "",
            nodeImageUrl3: "",
            image3: "",

            nodeEomTypes: "",
        };
    }

    loadNodeRezeptData =()=> {
        this.fetchJsonApiGetRezept('data', `${JSONAPI_ROOT}node/${this.props.contentType}/${this.props.id}`, true);
    };

    loadImageData1 =(image)=> {
        this.fetchJsonApiGetImage1('data', image, true);
    };

    loadImageData2 =(image)=> {
        this.fetchJsonApiGetImage2('data', image, true);
    };

    loadImageData3 =(image)=> {
        this.fetchJsonApiGetImage3('data', image, true);
    };


    fetchJsonApiGetRezept =(destination, url)=> {
        fetch(url, {
            method: 'GET',
            //mode: 'cors',
        })
            .then(function(response) {
                return response.json();

            })
            .then(data => {
                if (this.state.eomType.indexOf("A") !== -1
                    || this.state.eomType.indexOf("B") !== -1 ||
                    this.state.eomType.indexOf("G") !== -1) {
                    this.setState({
                        nodeEomTypes: data.data.attributes.field_rezeptzuordnung_eom_typ,
                        nodeTitle: data.data.attributes.title,
                        nodeText1A: data.data.attributes.field_text1a.value,
                        nodeText1B: data.data.attributes.field_text1b.value,
                        nodeImageUrl1: data.data.relationships.field_bild1.links.related.href,
                        nodeText2: data.data.attributes.field_text2.value,
                        nodeImageUrl2: data.data.relationships.field_bild2.links.related.href,
                        nodeText3: data.data.attributes.field_text3.value,
                        nodeImageUrl3: data.data.relationships.field_bild3.links.related.href,

                    });
                    this.loadImageData1(this.state.nodeImageUrl1);
                    this.loadImageData2(this.state.nodeImageUrl2);
                    this.loadImageData3(this.state.nodeImageUrl3);
                }

                if (this.state.eomType.indexOf("D") !== -1
                    || this.state.eomType.indexOf("E") !== -1 ||
                    this.state.eomType.indexOf("F") !== -1 ) {
                    this.setState({
                        nodeEomTypes: data.data.attributes.field_rezeptzuordnung_eom_typ,
                        nodeTitle: data.data.attributes.title,
                        nodeText1A: data.data.attributes.field_text1a.value,
                        nodeText1B: data.data.attributes.field_text1b.value,
                        nodeImageUrl1: data.data.relationships.field_bild1.links.related.href,
                        nodeText2: data.data.attributes.field_text2.value,
                        nodeImageUrl2: data.data.relationships.field_bild2.links.related.href,
                    });
                    this.loadImageData1(this.state.nodeImageUrl1);
                    this.loadImageData2(this.state.nodeImageUrl2);
                }

                if (this.state.eomType.indexOf("C") !== -1) {
                    this.setState({
                        nodeEomTypes: data.data.attributes.field_rezeptzuordnung_eom_typ,
                        nodeTitle: data.data.attributes.title,
                        nodeText1A: data.data.attributes.field_text1a.value,
                    });
                }
            })
            .catch(err => console.log('API error:', err));
    };

    componentWillMount(){
        const eom_type = localStorage.getItem("eom-type");
        this.setState({
            eomType: eom_type
        });
    }

    componentDidMount() {
        this.loadNodeRezeptData();
    }

    fetchJsonApiGetImage1 =(destination, url)=> {
        fetch(url, {
            method: 'GET',
            //mode: 'cors',
        })

            .then(function(response) {
                return response.json();
            })
            .then(data => {
                this.setState({
                    image1: data.data.attributes.uri.url
                });
            })
            .catch(err => console.log('API error:', err));
    };

    fetchJsonApiGetImage2 =(destination, url)=> {
        fetch(url, {
            method: 'GET',
            //mode: 'cors',
        })

            .then(function(response) {
                return response.json();
            })
            .then(data => {
                this.setState({
                    image2: data.data.attributes.uri.url
                });
            })
            .catch(err => console.log('API error:', err));
    };

    fetchJsonApiGetImage3 =(destination, url)=> {
        fetch(url, {
            method: 'GET',
            //mode: 'cors',
        })

            .then(function(response) {
                return response.json();
            })
            .then(data => {
                this.setState({
                    image3: data.data.attributes.uri.url
                });
            })
            .catch(err => console.log('API error:', err));
    };


    render() {
        return (
            <div>
                {this.state.nodeEomTypes.indexOf(this.state.eomType) !== -1 &&
                <div style={{marginBottom: "70px"}}>
                    {this.state.nodeText1A !== "" && this.state.nodeText1A !== null &&
                    <div className={"article-inner"}>
                        <div className="h1--lined--dark--multiline" dangerouslySetInnerHTML={{__html: this.state.nodeText1A}}/>
                    </div>
                    }
                    {this.state.nodeText1B !== "" && this.state.nodeText1B !== null &&
                    <div className={"article-inner"}>
                        <div className="h1--lined--dark--multiline" dangerouslySetInnerHTML={{__html: this.state.nodeText1B}}/>
                    </div>
                    }
                    {this.state.image1 !== "" && this.state.image1 !== null &&
                    <div className={"image-wrapper"} style={{background: `url(${config.base}${this.state.image1})`}}/>
                    }



                    {this.state.nodeText2 !== "" && this.state.nodeText2 !== null &&
                    <div className={"article-inner"}>
                        <div className="h1--lined--dark--multiline" dangerouslySetInnerHTML={{__html: this.state.nodeText2}}/>
                    </div>
                    }
                    {this.state.image2 !== "" && this.state.image2 !== null &&
                    <div className={"image-wrapper"} style={{background: `url(${config.base}${this.state.image2})`}}/>
                    }



                    {this.state.nodeText3 !== "" && this.state.nodeText3 !== null &&
                    <div className={"article-inner"}>
                        <div className="h1--lined--dark--multiline"
                             dangerouslySetInnerHTML={{__html: this.state.nodeText3}}/>
                    </div>
                    }
                    {this.state.image3 !== "" && this.state.image3 !== null &&
                    <div className={"image-wrapper"}
                         style={{background: `url(${config.base}${this.state.image3})`}}/>
                    }
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
                }
            </div>
        );
    }
}

export default ReduzierenComp;

