import React from 'react';
import Slider from '@material-ui/core/Slider';

import Up from "./../../../assets/images/up.svg"
import Down from "./../../../assets/images/down.svg"

class EomNewWheelHeight extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 170
        };
    }

    handleSliderChange = (event, newValue) => {
        this.setState({
            value: newValue
        });
        localStorage.setItem("height", newValue);
    };

    componentWillMount() {
        let height = localStorage.getItem("height");
        if(height !== null) {
            this.setState({
                value: height,
            });
        }
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        localStorage.setItem("height", this.state.value);
    }

    render() {
        return (
            <div className={"article-wrapper-stepper_select"}>
                <div className={"fade"} style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                }}>
                    <React.Fragment>
                        <img src={Up} alt={"up"} className={"up-down-button"}/>
                        <div style={{
                            height: "50vh",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            width: "100%"
                        }}>
                            <p id="vertical-slider" style={{
                                lineHeight: 1,
                                marginBottom: 0,
                                color: "white",
                                flex: 1,
                                display: "flex",
                                justifyContent: "flex-end",
                                textAlign: "center"
                            }}>
                                Größe<br/>(cm)
                            </p>
                            <Slider
                                style={{flex: 1}}
                                orientation="vertical"
                                onChange={this.handleSliderChange}
                                defaultValue={170}
                                aria-labelledby="vertical-slider"
                                min={140}
                                max={210}
                            />
                            <p id="vertical-slider" style={{
                                lineHeight: 1,
                                marginBottom: 0,
                                color: "white",
                                flex: 1,
                                display: "flex",
                                justifyContent: "flex-start",
                                textAlign: "center"
                            }}>
                                {this.state.value}
                            </p>
                        </div>
                        <img src={Down} alt={"up"} className={"up-down-button"}/>
                    </React.Fragment>
                </div>
            </div>
        );
    }
}

export default EomNewWheelHeight;



/*
import React from 'react';
import Picker from 'react-mobile-picker';
import MediaQuery from 'react-responsive';

const styles= {

    scrollButton: {
        color: "#ffffff",
        background: "transparent",
        border: "none",
        padding: "10px",
        width: "100%",
        outline: "none",
        fontSize: "20px"
    },

};

class EomNewWheelHeight extends React.Component {
    constructor(props) {
        super(props);

        let range = this.props.range;
        let startingAt= this.props.startingAt;
        let startingpoint = this.props.startingPoint;

        let options = [];
        for (let i = range; i >= startingAt; i--) {
            options.push(i);
        }

        this.state = {
            valueGroups: {
                /!*        title: 'Mr.',
                        firstName: 'Micheal',*!/
                measure: startingpoint
            },
            optionGroups: {
                /!*        title: ['Mr.', 'Mrs.', 'Ms.', 'Dr.'],
                        firstName: ['John', 'Micheal', 'Elizabeth'],*!/
                measure: options
            },
        };
    }

    // Update the value in response to user picking event
    handleChange = (measure, value) => {
        this.setState(({valueGroups}) => ({
            valueGroups: {
                ...valueGroups,
                [measure]: value
            }
        }));
        localStorage.setItem(this.props.localStorageItem, value);
    };

    scrollUp = () => {
        if (this.state.valueGroups.measure > this.props.startingAt) {
            this.setState({
                valueGroups: {
                    measure: this.state.valueGroups.measure + 1
                }
            })
        }
        localStorage.setItem(this.props.localStorageItem, this.state.valueGroups.measure + 1);
    };

    scrollDown = () => {
        if (this.state.valueGroups.measure < (this.props.range - 1)) {
            this.setState({
                valueGroups: {
                    measure: this.state.valueGroups.measure - 1
                }
            })
        }
        localStorage.setItem(this.props.localStorageItem, this.state.valueGroups.measure - 1);
    };

    componentDidMount() {
        // First time the app is used, set respective localStorageItem to startingpoint
        const item = localStorage.getItem(this.props.localStorageItem);

        if (item === null) {
            localStorage.setItem(this.props.localStorageItem, this.state.valueGroups.measure);
        } else {
            this.setState({
                valueGroups: {
                    measure: parseInt(item)
                }
            })
        }
    }

    componentWillMount() {
/!*        (function reloadPage ()  {
            if (!window.location.hash) {
                window.location = window.location + '#loaded';
                window.location.reload();
            }
        })();
        window.history.back(1);*!/
        this.handleBrowserBar();
        window.scrollTo(0, 1);

        this.handleOverflow();
        this.hideAppBar();
    }

    handleOverflow =()=> {
        document.getElementsByTagName("HTML")[0].style.overflowY = "hidden";
        document.getElementById("root").style.overflowY = "hidden";
    };

    hideAppBar =()=> {
        document.getElementsByTagName("HEADER")[0].style.display = "none";
        document.getElementsByTagName("ARTICLE")[0].style.paddingTop = "50px";
    };

    handleBrowserBar =()=> {
        document.getElementsByTagName("HTML")[0].style.overflow = "hidden";
        document.getElementsByTagName("HTML")[0].style.height = "100%";
        document.getElementsByTagName("BODY")[0].style.overflow = "hidden";
        document.getElementsByTagName("BODY")[0].style.height = "100%";
    };


    render() {
        const { optionGroups, valueGroups } = this.state;


        return (
            <div className={"select-wrapper"}>
                <div style={styles.selectWrapperInner}>
                    <div id={"number-block"}>
                        <div className={"fade"} >
                            <p className={"selectWrapperHeadline"}>{this.props.question}<br/></p>

                            <div className={"scrollButtonWrapper"}>
                                <button onClick={this.scrollUp} style={styles.scrollButton}>▲</button>
                            </div>

                            {/!*mobile*!/}
                            <MediaQuery maxWidth={767}>
                                <Picker
                                    optionGroups={optionGroups}
                                    valueGroups={valueGroups}
                                    height={370}
                                    itemHeight={26}
                                    onChange={this.handleChange}
                                />
                            </MediaQuery>

                            {/!*ipad and ipad mini*!/}
                            <MediaQuery minWidth={768} maxWidth={1023}>
                                <Picker
                                    optionGroups={optionGroups}
                                    valueGroups={valueGroups}
                                    height={700}
                                    itemHeight={50}
                                    onChange={this.handleChange}
                                />
                            </MediaQuery>

                            {/!*ipad pro*!/}
                            <MediaQuery minWidth={1024} maxWidth={1279}>
                                <Picker
                                    optionGroups={optionGroups}
                                    valueGroups={valueGroups}
                                    height={900}
                                    itemHeight={60}
                                    onChange={this.handleChange}
                                />
                            </MediaQuery>

                            {/!*desktop*!/}
                            <MediaQuery minWidth={1280}>
                                <Picker
                                    optionGroups={optionGroups}
                                    valueGroups={valueGroups}
                                    height={500}
                                    itemHeight={32}
                                    onChange={this.handleChange}
                                />
                            </MediaQuery>

                            <div className={"scrollButtonWrapper"}>
                                <button onClick={this.scrollDown} style={styles.scrollButton}>▼</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EomNewWheelHeight;
*/
