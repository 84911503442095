import React, {Component} from 'react';

import AppBarComp from "../components/global/AppBarComp";
import DoEomFirst from "../components/doEomFirst/doEomFirst";
import EomSnackbar from "../components/global/EomSnackbar";

class Cart extends Component {
    constructor() {
        super();
        this.state = {
            eomType: "initial state",
        };
    }

    componentWillMount() {
        const eom_type = localStorage.getItem("eom-type");
        this.setState({
            eomType: eom_type
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    eomColor=()=> {
        if (this.state.eomType.indexOf("A") !== -1) {
            return(
                "eom-a"
            )
        }
        else if (this.state.eomType.indexOf("B") !== -1) {
            return(
                "eom-b"
            )
        }
        else if (this.state.eomType.indexOf("C") !== -1) {
            return(
                "eom-c"
            )
        }
        else if (this.state.eomType.indexOf("D") !== -1) {
            return(
                "eom-d"
            )
        }
        else if (this.state.eomType.indexOf("E") !== -1) {
            return(
                "eom-e"
            )
        }
        else if (this.state.eomType.indexOf("F") !== -1) {
            return(
                "eom-f"
            )
        }
        else if (this.state.eomType.indexOf("G") !== -1) {
            return(
                "eom-g"
            )
        }
    };

    render() {
        return (
            <div className={"app-wrapper"}>
                <main className={"main"}>
                    <AppBarComp appUserLoggedIn={this.props.appUserLoggedIn} handleLogout={this.props.handleLogout}/>
                    <article className={"article article--with-stripe fade"}>

                        {(this.state.eomType === null || this.state.eomType === undefined || this.state.eomType === "initial state") &&
                        <DoEomFirst />
                        }

                        {(this.state.eomType !== null && this.state.eomType !== undefined && this.state.eomType !== "initial state") &&

                        <div>
                            <div className={this.eomColor()}>
                                <div className={"rezepte-colored-stripe"}/>
                            </div>

                            <div className={"article-inner"}>
                                <h1 className={"h1--lined--dark"} style={{marginBottom: "20px"}}>Einkaufsliste</h1>
                                <div className={"cart-top"}>
                                    <p>Lebensmittel suchen</p>
                                    <p className={"cart-top-elem-withborder"}>Lebensmittel eingeben</p>
                                    <p>Kategorie</p>
                                    <p className={"cart-top-elem-withborder"}>bitte auswählen</p>
                                    <p>Lebensmittel</p>
                                    <p className={"cart-top-elem-withborder"}>bitte auswählen</p>
                                </div>

                                <p className={"cart-table"}>
                                    <span className={"cart-row-1"}>Lebensmittel</span>
                                    <span className={"cart-row-1"}>Menge</span>
                                </p>
                                <p className={"cart-table"}>
                                    <span className={"cart-row-1"}>Reis</span>
                                    <span className={"cart-row-1"}>1 kg</span>
                                </p>
                                <p className={"cart-table"}>
                                    <span className={"cart-row-1"}>Karotten</span>
                                    <span className={"cart-row-1"}>1 kg</span>
                                </p>
                                <p className={"cart-table"}>
                                    <span className={"cart-row-1"}>Stangensellerie</span>
                                    <span className={"cart-row-1"}>1 Stk</span>
                                </p>
                                <p className={"cart-table"}>
                                    <span className={"cart-row-1"}>Kirschen</span>
                                    <span className={"cart-row-1"}>1 kg</span>
                                </p>
                                <p className={"cart-table"}>
                                    <span className={"cart-row-1"}>Birnen</span>
                                    <span className={"cart-row-1"}>1 kg</span>
                                </p>
                                <p className={"cart-table"}>
                                    <span className={"cart-row-1"}>Lebensmittel anfügen</span>
                                    <span className={"cart-row-1"}>Liste leeren</span>
                                </p>
                            </div>
                        </div>
                        }
                    </article>
                </main>

                <EomSnackbar/>
            </div>
        );
    }
}

export default Cart;

