import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import 'whatwg-fetch';

import theme from "./theme/theme.js";
import { MuiThemeProvider } from '@material-ui/core/styles';

import FrontpageLocked from "./screens/FrontpageLocked";
import FrontpageUnLocked from "./screens/FrontpageUnLocked";
import Rezepte from "./screens/Rezepte";
import Profil from "./screens/Profil";
import EomNew from "./components/EomNew/EomNew";


import config from './config.js';
import Tipps from "./screens/Tipps";
import Cart from "./screens/Cart";
import GutFuerDichMain from "./screens/GutFuerDichMain";
import Gut from "./screens/Gut";
import Phase from "./screens/Phase";
import Type from "./screens/Type";
import Reduzieren from "./screens/Reduzieren";
import CircleLarge from "./assets/images/circle2.svg";
import DeinTagesablauf from "./screens/DeinTabesablauf";
import DeinMorgenritual from "./screens/DeinMorgenritual";
import DeinAbendritual from "./screens/DeinAbendritual";
import DeineBauchmassage from "./screens/DeineBauchmassage";
import DeineNahrungsergaenzung from "./screens/DeineNahrungsergaenzung";
import AnalyseFirst from "./screens/AnalyseFirst";
import Meiden from "./screens/Meiden";
import Tutorial from "./screens/Tutorial";

const DRUPAL_API_ROOT = `${config.base}/`;
const JSONAPI_ROOT = `${config.base}/jsonapi/`;
const CONTENT_TYPE = `tcm_evaluierung`;

class App extends Component {
    constructor() {
        super();
        this.state = {
            appUserLoggedIn: false,
            data: null,
            token: localStorage.getItem('token') !== null ? JSON.parse(localStorage.getItem('token')) : null,
            username: '',
            password: '',
            eomType: localStorage.getItem("eom-type"),
            bellyType: localStorage.getItem('belly-type') !== null ? localStorage.getItem('belly-type') : null,
            appState: [],
        };

        this.getOauthToken = this.getOauthToken.bind(this);
        this.getRefreshToken = this.getRefreshToken.bind(this);
        this.fetchOauthToken = this.fetchOauthToken.bind(this);
        this.refreshOauthToken = this.refreshOauthToken.bind(this);

        this.buildHeader = this.buildHeader.bind(this);
        this.postNode = this.postNode.bind(this);
        this.patchNode = this.patchNode.bind(this);
        this.deleteNode = this.deleteNode.bind(this);
        this.fetchJsonApiGet = this.fetchJsonApiGet.bind(this);
        this.fetchJsonApiPost = this.fetchJsonApiPost.bind(this);
        this.fetchJsonApiPatch = this.fetchJsonApiPatch.bind(this);
        this.fetchJsonApiDelete = this.fetchJsonApiDelete.bind(this);
        this.updateData = this.updateData.bind(this);
        this.checkInvalidData = this.checkInvalidData.bind(this);
        this.handleLogout = this.handleLogout.bind(this);

        this.getTCMData = this.getTCMData.bind(this);
        this.fetchJsonApiGetTCMData = this.fetchJsonApiGetTCMData.bind(this);
        this.loadNodeTCMData = this.loadNodeTCMData.bind(this);

        this.getUserUserData = this.getUserUserData.bind(this);
        this.getAppStateData = this.getAppStateData.bind(this);
        this.fetchJsonApiGetAppState = this.fetchJsonApiGetAppState.bind(this);
        this.loadNodeAppStateData = this.loadNodeAppStateData.bind(this);

    }

    componentWillMount() {
        this.setState(({
            token: null,
            appUserLoggedIn: false
        }));
        // If there is an existing token, but it's expired, update it.
        if (this.state.token !== null && this.state.token.expirationDate > Math.floor(Date.now() / 1000)) {
            // localStorage.clear();
            this.getRefreshToken();

        }


        // If there is an existing token use it to load node data.
        if (this.state.token !== null) {
            this.setState({'appUserLoggedIn': true});
        }
    }

    getOauthToken(username, password) {
        // console.log('getting oauth token');
        this.fetchOauthToken('token', `${DRUPAL_API_ROOT}oauth/token`, username, password);
    }

    getRefreshToken() {
        // console.log('getting refresh token');
        this.refreshOauthToken('token', `${DRUPAL_API_ROOT}oauth/token`);
    }

    fetchOauthToken(destination, url, username, password) {
        // console.log('getting oauth token');
        let formData = new FormData();
        formData.append('grant_type', 'password');
        formData.append('client_id', config.oauth.client_id);
        formData.append('client_secret', config.oauth.client_secret);
        formData.append('scope', config.oauth.scope);
        formData.append('username', username);
        formData.append('password', password);
        fetch(url, {
            method: 'post',
            headers: new Headers({
                'Accept': 'application/json',
            }),
            body: formData,
        })
            .then(function(response) {
                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    // console.log('Error retrieving token', data);
                    console.log('Error retrieving token');
                    return false;
                }

                let token = Object.assign({}, data); // Make a copy of data object.
                // Convert the date to a UNIX timestamp.
                token.date = Math.floor(Date.now() / 1000);
                token.expirationDate = token.date + token.expires_in;
                this.setState({'token': token});
                this.setState({'appUserLoggedIn': true});
                localStorage.setItem('token', JSON.stringify(token));
                localStorage.setItem('username', username);
                // After getting a new token we should also refresh the node data since
                // different users might have access to different content.
            })
            .catch(err => console.log('API got an error', err));
    }

    refreshOauthToken(destination, url) {
        //console.log("getting refresh token");
        if (this.state.token !== null) {
            let formData = new FormData();
            formData.append('grant_type', 'refresh_token');
            formData.append('client_id', config.oauth.client_id);
            formData.append('client_secret', config.oauth.client_secret);
            formData.append('scope', config.oauth.scope);
            formData.append('refresh_token', this.state.token.refresh_token);

            fetch(url, {
                method: 'post',
                headers: new Headers({
                    'Accept': 'application/json',
                }),
                body: formData,
            })
                .then(function(response) {
                    return response.json();
                })
                .then((data) => {
                    // console.log('refresh token', data);
                    let token = Object.assign({}, data);
                    // Convert the date to a UNIX timestamp.
                    token.date = Math.floor(Date.now() / 1000);
                    token.expirationDate = token.date + token.expires_in;
                    this.setState({'token': token});
                    localStorage.setItem('token', JSON.stringify(token));
                })
                .catch(err => console.log('API got an error', err));
        }
    }

    buildHeader() {
        let header = new Headers({
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
            'Authorization': `${this.state.token.token_type} ${this.state.token.access_token} `
        });
        return header;
    }

    // GET
    // Get list of node content & store in this.state.data
    loadNodeData() {
        //this.fetchJsonApiGet('data', `${JSONAPI_ROOT}node/${CONTENT_TYPE}?filter[uid.name][value]=${foo}`, true);
        this.fetchJsonApiGet('data', `${JSONAPI_ROOT}node/${CONTENT_TYPE}`, true);
    }


    // Update the data object in state, optionally validating.
    updateData(destination, responseData, validate = true) {
        const validatedData = this.checkInvalidData(responseData, validate);
        if (validatedData || validate === false) {
            this.setState( { [destination]: responseData }, () => console.log('this.state'));
        }
    }

    // Check that the data response is in the format we expect.
    checkInvalidData(data, validate = true) {
        if (validate) {
            if (data === null) {
                return false;
            }
            if (data.data === undefined ||
                data.data === null ) {
                return false;
            }
            return true;
        }
        return true;
    }

    fetchJsonApiGet(destination, url) {
        if (this.state.token !== null) {
            fetch(url, {
                method: 'GET',
                headers: this.buildHeader(),
                mode: 'cors',
            })

                .then(function(response) {
                    return response.json();
                })
                .then((data) => this.updateData(destination, data))
                .catch(err => console.log('API error:', err));
        }
    }


    // POST
    postNode(data) {
        this.fetchJsonApiPost('patch', `${JSONAPI_ROOT}node/${CONTENT_TYPE}`, data);
    }

    fetchJsonApiPost(destination, url, postData) {
        if (this.state.token !== null) {
            fetch(url, {
                method: 'POST',
                headers: this.buildHeader(),
                mode: 'cors',
                body: JSON.stringify(postData)
            })
                .then(function(response) {
                    return response.json();
                })
                .then((data) => {
                    this.updateData(destination, data, false);
                    /*                    this.loadNodeData();*/
                })
                .catch(err => console.log('API error:', err));
        }
    }

    // PATCH
    patchNode(id, data) {
        if (id !== undefined &&
            id !== null &&
            data !== undefined &&
            data !== null) {
            this.fetchJsonApiPatch('patch', `${JSONAPI_ROOT}node/${CONTENT_TYPE}/${id}`, data);
        }
    }

    fetchJsonApiPatch(destination, url, update) {
        if (this.state.token !== null) {
            fetch(url, {
                method: 'PATCH',
                headers: this.buildHeader(),
                mode: 'cors',
                body: JSON.stringify(update)
            })
                .then(function(response) {
                    return response.json();
                })
                .then((data) => {
                    this.updateData(destination, data, false);
                    this.loadNodeData();
                })
                .catch(err => console.log('API error:', err));
        }
    }

    // DELETE
    deleteNode(id) {
        if (id !== undefined && id !== null) {
            this.fetchJsonApiDelete('delete', `${JSONAPI_ROOT}node/${CONTENT_TYPE}/${id}`);
        }
    }

    fetchJsonApiDelete(destination, url) {
        if (this.state.token !== null) {
            fetch(url, {
                method: 'DELETE',
                headers: this.buildHeader(),
                mode: 'cors',
            })
                .then(function(response) {
                    // Should be 204
                    return response;
                })
                .then((data) => {
                    this.fetchJsonApiGet('data', `${JSONAPI_ROOT}node/${CONTENT_TYPE}`);
                })
                .catch(err => console.log('API error:', err));
        }
    }

    handleLogout() {
        this.setState(({
            token: null,
            appUserLoggedIn: false
        }));
        localStorage.clear();
        sessionStorage.clear();
        window.location.href='/';
    }

    loadNodeTCMData =()=> {
        this.fetchJsonApiGetTCMData('data', `${JSONAPI_ROOT}node/tcm_evaluierung?sort[sort-created][path]=created&sort[sort-created][direction]=DESC`, true);
    };

    fetchJsonApiGetTCMData=(destination, url)=> {
        fetch(url, {
            method: 'GET',
            headers: this.buildHeader(),
            mode: 'cors',
        })
            .then(function(response) {
                return response.json();
            })
            .then(data => {
                let bellyType = data.data[0].attributes.field_eom_typ_computed;
                let allergieServ = data.data[0].attributes.field_allergie;
                let ausschlussServ = data.data[0].attributes.field_ausschluss;
                this.setState({'bellyType': bellyType});
                localStorage.setItem('belly-type', bellyType);
                localStorage.setItem('eom-done', bellyType);
                localStorage.setItem('allergieServ', allergieServ);
                if (ausschlussServ !== null) {
                    localStorage.setItem('ausschlussServ', ausschlussServ);
                } else {
                    localStorage.setItem('ausschlussServ', '');
                }



                // for (let i = 0; i < data.data.length; i++) {
                //     appState.push(data.data[i].attributes.field_phase1, data.data[i].attributes.field_phase2, data.data[i].attributes.field_phase3);
                // }
                // this.setState({'appState': appState});
                // localStorage.setItem('appState', JSON.stringify(appState));
            })
            .catch(err => console.log('API error:', err));
    };

    getTCMData=()=> {
        this.loadNodeTCMData();
        // console.log("foo");
    }

    loadNodeAppStateData =()=> {
        this.fetchJsonApiGetAppState('data', `${JSONAPI_ROOT}node/app_state?filter[title]=App%20State`, true);
    };

    fetchJsonApiGetAppState =(destination, url)=> {
        fetch(url, {
            method: 'GET',
            //mode: 'cors',
        })
            .then(function(response) {
                return response.json();
            })
            .then(data => {
                let appState=[];
                let startDate;
                for (let i = 0; i < data.data.length; i++) {
                    appState.push(data.data[i].attributes.field_phase1, data.data[i].attributes.field_phase2, data.data[i].attributes.field_phase3);
                    startDate = data.data[i].attributes.field_startdate;
                }
                this.setState({'appState': appState});
                localStorage.setItem('appState', JSON.stringify(appState));
                sessionStorage.setItem('startDate', JSON.stringify(startDate));
            })
            .catch(err => console.log('API error:', err));
    };

    getAppStateData=()=> {
        this.loadNodeAppStateData();
    }

    loadUserUserData =()=> {
        this.fetchJsonApiGetUserUserData('data', `${JSONAPI_ROOT}user/user?filter[name]=${localStorage.getItem('username')}`, true);
    };

    fetchJsonApiGetUserUserData=(destination, url)=> {
        fetch(url, {
            method: 'GET',
            headers: this.buildHeader(),
            mode: 'cors',
        })
            .then(function(response) {
                // if user is blocked in drupal
                if (!response.ok) {
                    return response.ok;
                } else {
                    return response.json();
                }
            })
            .then(data => {
                if(data === false) {
                    this.setState({appUserLoggedIn: false});
                    this.handleLogout()
                } else {
                    let premium = data.data[0].attributes.field_premium;
                    let premiumString = premium.toString();
                    sessionStorage.setItem('premium', premiumString);
                }
            })
            .catch(err => {
                console.log('API error:', err)
            });
    };

    getUserUserData=()=> {
        this.loadUserUserData();
    }

    render() {
        if ((this.state.token !== null && localStorage.getItem('username') === null) || (this.state.token !== null && sessionStorage.getItem('startDate') === null) ) {
            this.getAppStateData()
        }

        if (this.state.token !== null && localStorage.getItem('belly-type') === null) {
            this.getTCMData()
        }

        if (this.state.token !== null) {
            this.getUserUserData()
        }
        return (
            <>

            <MuiThemeProvider theme={theme}>
                <Router>
                    {this.state.appUserLoggedIn === false &&
                    <Route
                        path="/"
                        exact
                        render={() => (
                            <FrontpageLocked
                                circleLarge={CircleLarge}
                                appUserLoggedIn={this.state.appUserLoggedIn}
                                loginFunction={this.getOauthToken}/>
                        )}/>
                    }

                    {this.state.appUserLoggedIn === true &&
                    <Route
                        path="/"
                        exact
                        render={() => (
                            <div>
                                <FrontpageUnLocked
                                    appUserLoggedIn={this.state.appUserLoggedIn}
                                    handleLogout={this.handleLogout}
                                    postNode={this.postNode}
                                    eomType={this.state.bellyType}
                                />
                            </div>
                        )}/>
                    }

                    {this.state.appUserLoggedIn === true &&
                    <Route
                        path="/analyse"
                        exact
                        render={() => (
                            <EomNew appUserLoggedIn={true}
                                    handleLogout={this.handleLogout}
                                    postNode={this.postNode}/>
                        )}/>
                    }

                    {this.state.appUserLoggedIn === true &&
                    <Route
                        path="/profil"
                        exact
                        render={() => (
                            <Profil appUserLoggedIn={this.state.appUserLoggedIn}
                                    handleLogout={this.handleLogout}
                                    postNode={this.postNode}/>
                        )}/>
                    }

                    {this.state.appUserLoggedIn === true &&
                    <Route
                        path="/typisierung"
                        exact
                        render={() => (
                            <Type appUserLoggedIn={this.state.appUserLoggedIn}
                                  handleLogout={this.handleLogout}
                                  postNode={this.postNode}
                            />
                        )}/>
                    }

                    {this.state.appUserLoggedIn === true &&
                    <Route
                        path="/rezepte"
                        exact
                        render={() => (
                            <Rezepte
                                appUserLoggedIn={this.state.appUserLoggedIn}
                                handleLogout={this.handleLogout}
                                contentType="rezept"/>
                        )}/>
                    }

                    {this.state.appUserLoggedIn === true &&
                    <Route
                        path="/tipp"
                        exact
                        render={() => (
                            <Tipps
                                appUserLoggedIn={this.state.appUserLoggedIn}
                                handleLogout={this.handleLogout}
                                contentType="tipp"/>
                        )}/>
                    }

                    {this.state.appUserLoggedIn === true &&
                    <Route
                        path="/cart"
                        exact
                        render={() => (
                            <Cart
                                appUserLoggedIn={this.state.appUserLoggedIn}
                                handleLogout={this.handleLogout}
                            />
                        )}/>
                    }



                    {this.state.appUserLoggedIn === true &&
                    <Route
                        path="/gut-fuer-dich-main"
                        exact
                        render={() => (
                            <GutFuerDichMain
                                appUserLoggedIn={this.state.appUserLoggedIn}
                                handleLogout={this.handleLogout}
                            />
                        )}/>
                    }

                    {this.state.appUserLoggedIn === true &&
                    <Route
                        path="/gut-fuer-dich"
                        exact
                        render={() => (
                            <Gut
                                appUserLoggedIn={this.state.appUserLoggedIn}
                                handleLogout={this.handleLogout}
                            />
                        )}/>
                    }

                    {this.state.appUserLoggedIn === true &&
                    <Route
                        path="/vermeiden"
                        exact
                        render={() => (
                            <Reduzieren
                                appUserLoggedIn={this.state.appUserLoggedIn}
                                handleLogout={this.handleLogout}
                            />
                        )}/>
                    }


{/*                    // <Route
                    //     path="/coming-soon"
                    //     exact
                    //     render={() => (
                    //         <FreeContent
                    //             appUserLoggedIn={this.state.appUserLoggedIn}
                    //             handleLogout={this.handleLogout}
                    //             contentType="coming_soon"/>
                    //     )}/>*/}

                    {this.state.appUserLoggedIn === true &&
                    <Route
                        path="/phase1"
                        exact
                        render={() => (
                            <Phase
                                appUserLoggedIn={this.state.appUserLoggedIn}
                                handleLogout={this.handleLogout}
                                phase={1}
                                days={"Phase 1"}
                                premium={this.state.premium}
                            />
                        )}/>
                    }

                    {this.state.appUserLoggedIn === true &&
                    <Route
                        path="/phase2"
                        exact
                        render={() => (
                            <Phase
                                appUserLoggedIn={this.state.appUserLoggedIn}
                                handleLogout={this.handleLogout}
                                phase={2}
                                days={"Phase 2"}
                                premium={this.state.premium}
                            />
                        )}/>
                    }

                    {this.state.appUserLoggedIn === true &&
                    <Route
                        path="/phase3"
                        exact
                        render={() => (
                            <Phase
                                appUserLoggedIn={this.state.appUserLoggedIn}
                                handleLogout={this.handleLogout}
                                phase={3}
                                days={"Phase 3"}
                                premium={this.state.premium}
                            />
                        )}/>
                    }
                    {this.state.appUserLoggedIn === true &&
                    <Route
                        path="/tagesablauf"
                        exact
                        render={() => (
                            <DeinTagesablauf
                                appUserLoggedIn={this.state.appUserLoggedIn}
                                handleLogout={this.handleLogout}
                            />
                        )}/>
                    }
                    {this.state.appUserLoggedIn === true &&
                    <Route
                        path="/morgenritual"
                        exact
                        render={() => (
                            <DeinMorgenritual
                                appUserLoggedIn={this.state.appUserLoggedIn}
                                handleLogout={this.handleLogout}
                                foo={"foo2"}
                            />
                        )}/>
                    }
                    {this.state.appUserLoggedIn === true &&
                    <Route
                        path="/abendritual"
                        exact
                        render={() => (
                            <DeinAbendritual
                                appUserLoggedIn={this.state.appUserLoggedIn}
                                handleLogout={this.handleLogout}
                                foo={"foo1"}
                            />
                        )}/>
                    }
                    {this.state.appUserLoggedIn === true &&
                    <Route
                        path="/bauchmassage"
                        exact
                        render={() => (
                            <DeineBauchmassage
                                appUserLoggedIn={this.state.appUserLoggedIn}
                                handleLogout={this.handleLogout}
                            />
                        )}/>
                    }
                    {this.state.appUserLoggedIn === true &&
                    <Route
                        path="/nahrungsergaenzung"
                        exact
                        render={() => (
                            <DeineNahrungsergaenzung
                                appUserLoggedIn={this.state.appUserLoggedIn}
                                handleLogout={this.handleLogout}
                            />
                        )}/>
                    }

                    {this.state.appUserLoggedIn === true &&
                    <Route
                        path="/deine-analyse"
                        exact
                        render={() => (
                            <AnalyseFirst
                                appUserLoggedIn={this.state.appUserLoggedIn}
                                handleLogout={this.handleLogout}
                            />
                        )}/>
                    }

                    {this.state.appUserLoggedIn === true &&
                    <Route
                        path="/meiden"
                        exact
                        render={() => (
                            <Meiden
                                appUserLoggedIn={this.state.appUserLoggedIn}
                                handleLogout={this.handleLogout}
                            />
                        )}/>
                    }

                    {/*{this.state.appUserLoggedIn === true &&*/}
                    {/*<Route*/}
                    {/*    path="/tutorial"*/}
                    {/*    exact*/}
                    {/*    render={() => (*/}
                    {/*        <Tutorial*/}
                    {/*            appUserLoggedIn={this.state.appUserLoggedIn}*/}
                    {/*            handleLogout={this.handleLogout}*/}
                    {/*        />*/}
                    {/*    )}/>*/}
                    {/*}*/}
                </Router>


            </MuiThemeProvider>

          </>
        );
    }
}

export default App;
