import React, {Component} from 'react';

import AppBarComp from "../components/global/AppBarComp";
import EomSnackbar from "../components/global/EomSnackbar";
import AnalyseFirst from "./AnalyseFirst";


class Meiden extends Component {
    constructor() {
        super();
        this.state = {
            eomType: "initial state",
        };
    }

    componentWillMount() {
        const eom_type = localStorage.getItem("belly-type");
        this.setState({
            eomType: eom_type
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    eomColor=()=> {
        if (this.state.eomType.indexOf("A") !== -1) {
            return(
                "eom-a"
            )
        }
        else if (this.state.eomType.indexOf("B") !== -1) {
            return(
                "eom-b"
            )
        }
        else if (this.state.eomType.indexOf("C") !== -1) {
            return(
                "eom-c"
            )
        }
        else if (this.state.eomType.indexOf("D") !== -1) {
            return(
                "eom-d"
            )
        }
        else if (this.state.eomType.indexOf("E") !== -1) {
            return(
                "eom-e"
            )
        }
        else if (this.state.eomType.indexOf("F") !== -1) {
            return(
                "eom-f"
            )
        }
        else if (this.state.eomType.indexOf("G") !== -1) {
            return(
                "eom-g"
            )
        }
    };

    render() {
        return (
            <div>
                {this.state.eomType !== "initial state" && this.state.eomType !== null && this.state.eomType !== undefined &&
                <div className={"app-wrapper"}>
                    <main className={"main"}>
                        <AppBarComp appUserLoggedIn={this.props.appUserLoggedIn}
                                    handleLogout={this.props.handleLogout}/>
                        <article className={"article article--with-stripe"}>
                            <div>
                                <div className={this.eomColor()}>
                                    <div className={"rezepte-colored-stripe"}/>
                                </div>
                                <div className={"article-inner"}>
                                    <h1 className={"h1--lined--dark"} style={{marginTop: "20px"}}>
                                        Was du während der bellydays meiden solltest
                                    </h1>
                                    <br/>
                                    <div>
                                        <ul style={{textAlign: "left", marginBottom: "30px"}}>
                                            <li><p>Kaffee, Schwarztee, Grüntee, Früchtetee</p></li>
                                            <li><p>Alkohol</p></li>
                                            <li><p>Gesüßte Getränke, Fruchtsäfte</p></li>
                                            <li><p>Kohlensäurehältige Getränke</p></li>
                                            <li><p>Blähende Lebensmittel (z.B. Kohl,
                                                Sauerkraut, Bohnen)</p></li>
                                            <li><p>Zucker, Süßigkeiten, Süßspeisen jeder
                                                Art</p></li>
                                            <li><p>Zwiebel, Knoblauch</p></li>
                                            <li><p>Frittiertes, Gebratenes, erhitzte Fette</p></li>
                                            <li><p>Speisen mit künstlichen Aromen,
                                                Geschmacksverstärkern oder
                                                Konservierungsstoffen</p></li>
                                            <li><p>Fertigprodukte, Konserven</p></li>
                                            <li><p>Späte Mahlzeiten</p></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </main>

                    <EomSnackbar/>
                </div>
                }

                {this.state.eomType === "initial state" &&
                <AnalyseFirst
                    appUserLoggedIn={this.props.appUserLoggedIn} handleLogout={this.props.handleLogout}
                />
                }
            </div>

        );
    }
}

export default Meiden;

