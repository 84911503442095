import React, {Component} from 'react';
import config from '../../config.js';
import dompurify from 'dompurify';

const JSONAPI_ROOT = `${config.base}/jsonapi/`;
const sanitizer = dompurify.sanitize;

class GutComp extends Component {
    constructor() {
        super();
        this.state = {
            eomType: "initial state",
            nodeTitle: "",
            nodeBody: "",

            nodeGetreide: "",
            nodeImageUrlGetreide: "",
            imageGetreide: "",

            nodeGemuese: "",
            nodeImageUrlGemuese: "",
            imageGemuese: "",

            nodeProteine: "",
            nodeImageUrlProteine: "",
            imageProteine: "",

            nodeGetrank: "",
            nodeImageUrlGetranke: "",
            imageGetranke: "",

            nodeEomTypes: "",
        };
    }

    loadNodeRezeptData =()=> {
        this.fetchJsonApiGetRezept('data', `${JSONAPI_ROOT}node/${this.props.contentType}/${this.props.id}`, true);
    };

    loadImageDataGetreide =(image)=> {
        this.fetchJsonApiGetImageGetreide('data', image, true);
    };

    loadImageDataGemuese =(image)=> {
        this.fetchJsonApiGetImageGemuese('data', image, true);
    };

    loadImageDataProteine =(image)=> {
        this.fetchJsonApiGetImageProteine('data', image, true);
    };

    loadImageDataGetranke =(image)=> {
        this.fetchJsonApiGetImageGetranke('data', image, true);
    };


    fetchJsonApiGetRezept =(destination, url)=> {
        fetch(url, {
            method: 'GET',
            //mode: 'cors',
        })
            .then(function(response) {
                return response.json();
            })
            .then(data => {
                this.setState({
                    nodeTitle: data.data.attributes.title,
                    nodeGetreide: data.data.attributes.field_getreide.value,
                    nodeImageUrlGetreide: data.data.relationships.field_getreide_bild.links.related.href,
                    nodeGemuese: data.data.attributes.field_gemuse.value,
                    nodeImageUrlGemuese: data.data.relationships.field_gemuse_bild.links.related.href,
                    nodeProteine: data.data.attributes.field_proteine.value,
                    nodeImageUrlProteine: data.data.relationships.field_proteine_bild.links.related.href,
                    nodeGetrank: data.data.attributes.field_getrank.value,
                    nodeImageUrlGetranke: data.data.relationships.field_getranke_bild.links.related.href,
                    nodeEomTypes: data.data.attributes.field_rezeptzuordnung_eom_typ,
                });
                this.loadImageDataGetreide(this.state.nodeImageUrlGetreide);
                this.loadImageDataGemuese(this.state.nodeImageUrlGemuese);
                this.loadImageDataProteine(this.state.nodeImageUrlProteine);
                this.loadImageDataGetranke(this.state.nodeImageUrlGetranke);
            })
            .catch(err => console.log('API error:', err));
    };

    componentWillMount(){
        const eom_type = localStorage.getItem("eom-type");
        this.setState({
            eomType: eom_type
        });
    }

    componentDidMount() {
        this.loadNodeRezeptData();
    }

    fetchJsonApiGetImageGetreide =(destination, url)=> {
        fetch(url, {
            method: 'GET',
            //mode: 'cors',
        })

            .then(function(response) {
                return response.json();
            })
            .then(data => {
                this.setState({
                    imageGetreide: data.data.attributes.uri.url
                });
            })
            .catch(err => console.log('API error:', err));
    };

    fetchJsonApiGetImageGemuese =(destination, url)=> {
        fetch(url, {
            method: 'GET',
            //mode: 'cors',
        })

            .then(function(response) {
                return response.json();
            })
            .then(data => {
                this.setState({
                    imageGemuese: data.data.attributes.uri.url
                });
            })
            .catch(err => console.log('API error:', err));
    };

    fetchJsonApiGetImageProteine =(destination, url)=> {
        fetch(url, {
            method: 'GET',
            //mode: 'cors',
        })

            .then(function(response) {
                return response.json();
            })
            .then(data => {
                this.setState({
                    imageProteine: data.data.attributes.uri.url
                });
            })
            .catch(err => console.log('API error:', err));
    };

    fetchJsonApiGetImageGetranke =(destination, url)=> {
        fetch(url, {
            method: 'GET',
            //mode: 'cors',
        })

            .then(function(response) {
                return response.json();
            })
            .then(data => {
                this.setState({
                    imageGetranke: data.data.attributes.uri.url
                });
            })
            .catch(err => console.log('API error:', err));
    };


    render() {
        return (
            <div>
                {this.state.nodeEomTypes.indexOf(this.state.eomType) !== -1 &&
                <div>
                    <div className={"article-inner"}>
                        <h1 className={"h1--lined--dark"} style={{marginTop: "20px"}}>
                            Das richtige Getreide für dich
                        </h1>
                        <div className="h1--lined--dark--multiline" dangerouslySetInnerHTML={{__html: sanitizer(this.state.nodeGetreide)}}/>
                    </div>
                    <div className={"image-wrapper"} style={{background: `url(${config.base}${this.state.imageGetreide})`}}/>

                    <div className={"article-inner"}>
                        <h1 className={"h1--lined--dark"} style={{marginTop: "20px"}}>
                            Das richtige Gemüse für dich
                        </h1>
                        <div className="h1--lined--dark--multiline" dangerouslySetInnerHTML={{__html: sanitizer(this.state.nodeGemuese)}}/>
                    </div>
                    <div className={"image-wrapper"} style={{background: `url(${config.base}${sanitizer(this.state.imageGemuese)})`}}/>

                    <div className={"article-inner"}>
                        <h1 className={"h1--lined--dark"} style={{marginTop: "20px"}}>
                            Die richtigen Proteine für dich
                        </h1>
                        <div className="h1--lined--dark--multiline" dangerouslySetInnerHTML={{__html: sanitizer(this.state.nodeProteine)}}/>
                    </div>
                    <div className={"image-wrapper"} style={{background: `url(${config.base}${this.state.imageProteine})`}}/>

                    <div className={"article-inner"}>
                        <h1 className={"h1--lined--dark"} style={{marginTop: "20px"}}>
                            Die richtigen Getränke für dich
                        </h1>
                        <div className="h1--lined--dark--multiline" dangerouslySetInnerHTML={{__html: sanitizer(this.state.nodeGetrank)}}/>
                    </div>
                    <div className={"image-wrapper"} style={{marginBottom: "70px", background: `url(${config.base}${this.state.imageGetranke})`}}/>

                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
                }
            </div>
        );
    }
}

export default GutComp;

