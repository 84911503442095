import React, {Component} from 'react';
import config from '../../config.js';
import Clock from "../../assets/images/clock.png";
import Chef from "../../assets/images/chef.png";
import Togo from "../../assets/images/2go.png";
import Warning from '../../assets/images/warning.svg';
import dompurify from 'dompurify';

const sanitizer = dompurify.sanitize;

const JSONAPI_ROOT = `${config.base}/jsonapi/`;

class Rezept extends Component {
    constructor() {
        super();
        this.state = {
            eomType: "initial state",
            allergien: [],
            ausschluss: [],
            addIt: [],
            nodeTitle: "",
            nodeImageUrl: "",
            image: "",
            nodeZubereitungsniveau: "",
            nodeKalorien: "",
            nodeKalorienZusatztext: "",
            nodeTogo: "",
            nodeBody: "",
            nodeEomTypes: "",
            allergieShow: [],
            ausschlussShow: [],
        };
    }

    loadNodeRezeptData =()=> {
        this.fetchJsonApiGetRezept('data', `${JSONAPI_ROOT}node/${this.props.contentType}/${this.props.id}`, true);
    };

    loadImageData =(image)=> {
        this.fetchJsonApiGetImage('data', image, true);
    };

    fetchJsonApiGetRezept =(destination, url)=> {
        fetch(url, {
            method: 'GET',
            //mode: 'cors',
        })
            .then(function(response) {
                return response.json();
            })
            .then(data => {
                let cat = data.data.attributes.field_ausschluss_lebensmittel;
                let addIt = [];

                //allergien
                let allergien = [];
                for (let x = 0; x < data.data.attributes.field_unvertraglichkeiten.length; x++) {
                    allergien.push(data.data.attributes.field_unvertraglichkeiten[x]);
                }
                let md1 = allergien; // Allergien of the recipie
                let md2 = this.state.allergien; // MY allergien
                let addAllergie =[];

                for (let x = 0; x < md2.length; x++) {
                    if (md1.indexOf(md2[x]) !== -1) {
                        addIt.push("true");
                        addAllergie.push(md2[x]);
                    } else {
                        addIt.push("true");
                    }
                }

                //ausschluss
                let ausschluss = [];
                for (let x = 0; x < cat.length; x++) {
                    ausschluss.push(cat[x]);
                }
                let ad1 = ausschluss;
                let ad2 = this.state.ausschluss;
                let addAusschluss =[];

                for (let x = 0; x < ad2.length; x++) {
                    if (ad1.indexOf(ad2[x]) !== -1) {
                        addIt.push("true");
                        addAusschluss.push(ad2[x]);
                    } else {
                        addIt.push("true");
                    }
                }



                this.setState({
                    allergieShow: addAllergie,
                    ausschlussShow: addAusschluss,
                    addIt: addIt,
                    nodeTitle: data.data.attributes.title,
                    nodeImageUrl: data.data.relationships.field_bild.links.related.href,
                    nodeZubereitungszeit: data.data.attributes.field_zubereitungszeit,
                    nodeZubereitungsniveau: data.data.attributes.field_zubereitungsniveau,
                    nodeKalorien: data.data.attributes.field_kalorien,
                    nodeKalorienZusatztext: data.data.attributes.field_kalorien_zusatztext,
                    nodeTogo: data.data.attributes.field_zum_mitnehmen,
                    nodeBody: data.data.attributes.body.value,
                    nodeEomTypes: data.data.attributes.field_rezeptzuordnung_eom_typ,
                });
                this.loadImageData(this.state.nodeImageUrl);
            })
            .catch(err => console.log('API error:', err));
    };

    componentWillMount(){
        const eom_type = localStorage.getItem("belly-type");
        const allergie = localStorage.getItem('allergieServ');
        const ausschl = localStorage.getItem('ausschlussServ');

        let allergien = [];

        if (allergie.indexOf("Laktose") !== -1) {
            allergien.push("Laktose");
        }
        if (allergie.indexOf("Fruktose") !== -1) {
            allergien.push("Fruktose");
        }
        if (allergie.indexOf("Gluten") !== -1) {
            allergien.push("Gluten");
        }
        if (allergie.indexOf("Histamin") !== -1) {
            allergien.push("Histamin");
        }

        let ausschluss = [];

        if (ausschl.indexOf("Fleisch") !== -1) {
            ausschluss.push("Fleisch");
        }
        if (ausschl.indexOf("Fisch") !== -1) {
            ausschluss.push("Fisch");
        }
        if (ausschl.indexOf("Milch und Milchprodukte") !== -1) {
            ausschluss.push("Milchprodukt");
        }
        if (ausschl.indexOf("Eier") !== -1) {
            ausschluss.push("Ei");
        }

        this.setState({
            eomType: eom_type,
            allergien: allergien,
            ausschluss: ausschluss
        });
    }

    componentDidMount() {
        this.loadNodeRezeptData();
    }

    fetchJsonApiGetImage =(destination, url)=> {
        fetch(url, {
            method: 'GET',
            //mode: 'cors',
        })

            .then(function(response) {
                return response.json();
            })
            .then(data => {
                this.setState({
                    image: data.data.attributes.uri.url
                });
                // console.log(this.state.image);
            })
            .catch(err => console.log('API error:', err));
    };


    render() {
        return (
            <>
                <div>
                    {this.state.nodeEomTypes.indexOf(this.state.eomType) !== -1 && this.state.addIt.indexOf("false") === -1 &&
                    <div>
                        <div className={"image-wrapper"}
                             style={{background: `url(${config.base}${this.state.image})`}}/>

                        <div className={"article-inner"}>
                            <h1 className={"h1--notlined"}
                                style={{textAlign: "left"}}>{this.state.nodeTitle}</h1>
                            <p style={{color: "red", fontWeight: "bold"}}>
                                {(this.state.allergieShow.length !== 0 || this.state.ausschlussShow.length !== 0) &&
                                <span><img alt="Rezept" src={Warning}
                                           style={{maxHeight: "17px"}}/> Achtung dieses Rezept enthält:<br/></span>
                                }
                                {(this.state.allergieShow.length !== 0) &&
                                <span> {(this.state.allergieShow.toString()).replace(/,/g, ", ")}</span>
                                }
                                {(this.state.allergieShow.length !== 0 && this.state.ausschlussShow.length !== 0) &&
                                <span>, </span>
                                }
                                {(this.state.ausschlussShow.length !== 0) &&
                                <span>{(this.state.ausschlussShow.toString()).replace(/,/g, ", ")}</span>
                                }
                            </p>
                            <p className="rezepte-meta p--small">
                                <span className="rezepte-meta-elem"><img
                                    src={Clock} alt={"clock"}
                                    className={"rezept-icon"}/> <span
                                    className="meta--clock">{this.state.nodeZubereitungszeit} Min</span>,</span>
                                <span className="rezepte-meta-elem"><img
                                    src={Chef} alt={"chef"}
                                    className={"rezept-icon"}/> {this.state.nodeZubereitungsniveau},</span>
                                {/*<span className="rezepte-meta-elem"><img src={Kcal} alt={"kcal"} className={"rezept-icon"}/> {this.state.nodeKalorien} kcal{this.state.nodeKalorienZusatztext !== null &&<span> ({this.state.nodeKalorienZusatztext})</span>}{this.state.nodeTogo === true &&<span>,</span>}</span>*/}
                                {this.state.nodeTogo === true &&
                                <span className="rezepte-meta-elem"><img
                                    src={Togo} alt={"togo"}
                                    className={"rezept-icon togo"}/> zum Mitnehmen</span>
                                }
                            </p>
                            <div
                                dangerouslySetInnerHTML={{__html: sanitizer(this.state.nodeBody)}}/>
                        </div>
                        <br/>
                        <br/>
                    </div>
                    }
                </div>

                </>
        );
    }
}

export default Rezept;
