import React from 'react';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import theme from "./../../theme/theme.js";

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from "@material-ui/core/IconButton";
import Hamburger from "../../assets/images/hamburger.svg";

const useStyles = makeStyles({
    /*    list: {
            textAlign: "center"
        },*/
    link: {
        textDecoration: "none",
        color: "inherit",
        width: "100%",
        textAlign: "center"

    },
    text: {
        fontWeight: "100",
        textAlign: "center",
    },

    logoDiv: {
        textAlign: "center",
        [theme.breakpoints.down('md')]: {
            borderTop: `1px solid transparent`,
            borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
        padding: "0px",
        flex: 8,
        maxWidth: "1260px",
    },

    logoDivText: {
        // borderBottom: `1px solid ${theme.palette.primary.main}`,
        paddingLeft: "10px",
        paddingRight: "10px",
        fontWeight: "bold",
        lineHeight: "1",
        color: theme.palette.secondary.main
    },

    logoIcon: {
        fontSize: "40px",
        [theme.breakpoints.down('md')]: {
            fontSize: "30px",
        },
    },
});

function SwipeableTemporaryDrawer(props) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        right: false,
    });

    const toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [side]: open });
    };


    const sideList = side => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
            id={"#drawer"}
        >
            <List style={{padding: 0, marginBottom: "30px"}}>
                <ListItem button key={"close"} style={{display: "flex"}}>
                    <p style={{flex: 1, fontWeight: "bold", color: "#34292D"}}>Fertig</p>
                    <div className={classes.logoDiv}>
                        <h2 style={{marginTop: 7, letterSpacing: 4}}>bellydays</h2>

                    </div>
                    <p style={{flex: 1, fontWeight: "bold", color: "#ffffff"}}>Fertig</p>


                </ListItem>
            </List>

            <h1 className={"h1--notlined h1--drawer"} style={{textAlign: "center"}}>Navigation</h1>
            <br/>


            <List>
                {props.appUserLoggedIn === true &&
                <div>
                    <Link to="/" title="startseite" className={classes.link}><h1 className={"h1--lined h1--drawer"} style={{textAlign: "center", letterSpacing: 4}}>bellydays</h1></Link>
                    {props.eomType === null &&
                    <Link to="/deine-analyse" title="profil" className={classes.link}>
                        <ListItem button key={"profil"}>
                            <ListItemText className={classes.text}>
                                <p className={"drawer-link"}>Dein Profil</p>
                            </ListItemText>
                        </ListItem>
                    </Link>
                    }
                    {props.eomType !== null &&
                    <Link to="/profil" title="profil" className={classes.link}>
                        <ListItem button key={"profil"}>
                            <ListItemText className={classes.text}>
                                <p className={"drawer-link"}>Dein Profil</p>
                            </ListItemText>
                        </ListItem>
                    </Link>
                    }
                    <Link to="/tagesablauf" title="tagesablauf" className={classes.link}>
                        <ListItem button key={"tagesablauf"}>
                            <ListItemText className={classes.text}>
                                <p className={"drawer-link"}>Dein Tagesablauf</p>
                            </ListItemText>
                        </ListItem>
                    </Link>
                    <Link to="/morgenritual" title="morgenritual" className={classes.link}>
                        <ListItem button key={"morgenritual"}>
                            <ListItemText className={classes.text}>
                                <p className={"drawer-link"}>Dein Morgenritual</p>
                            </ListItemText>
                        </ListItem>
                    </Link>
                    <Link to="/abendritual" title="abendritual" className={classes.link}>
                        <ListItem button key={"abendritual"}>
                            <ListItemText className={classes.text}>
                                <p className={"drawer-link"}>Dein Abendritual</p>
                            </ListItemText>
                        </ListItem>
                    </Link>
                    <Link to="/bauchmassage" title="bauchmassage" className={classes.link}>
                        <ListItem button key={"bauchmassage"}>
                            <ListItemText className={classes.text}>
                                <p className={"drawer-link"}>Deine Bauchmassage</p>
                            </ListItemText>
                        </ListItem>
                    </Link>
                    <Link to="/nahrungsergaenzung" title="nahrungsergÃ¤nzung" className={classes.link}>
                        <ListItem button key={"nahrungsergaenzung"}>
                            <ListItemText className={classes.text}>
                                <p className={"drawer-link"}>Deine Nahrungsergänzung</p>
                            </ListItemText>
                        </ListItem>
                    </Link>

                    <br/>
                    <br/>

                    <h1 className={"h1--lined h1--drawer"} style={{textAlign: "center"}}>Empfehlungen</h1>

                    {props.eomType === null &&
                    <div>
                        <Link to="/deine-analyse" title="gut" className={classes.link}>
                            <ListItem button key={"gut"}>
                                <ListItemText className={classes.text}>
                                    <p className={"drawer-link"}>Gut fÃ¼r dich</p>
                                </ListItemText>
                            </ListItem>
                        </Link>
                        <Link to="/deine-analyse" title="meiden" className={classes.link}>
                            <ListItem button key={"meiden"}>
                                <ListItemText className={classes.text}>
                                    <p className={"drawer-link"}>Meiden</p>
                                </ListItemText>
                            </ListItem>
                        </Link>
                        <Link to="/deine-analyse" title="rezepte" className={classes.link}>
                            <ListItem button key={"rezepte"}>
                                <ListItemText className={classes.text}>
                                    <p className={"drawer-link"}>Rezepte</p>
                                </ListItemText>
                            </ListItem>
                        </Link>
                    </div>

                    }
                    {props.eomType !== null &&
                    <div>
                        <Link to="/gut-fuer-dich-main" title="gut" className={classes.link}>
                            <ListItem button key={"gut"}>
                                <ListItemText className={classes.text}>
                                    <p className={"drawer-link"}>Gut für dich</p>
                                </ListItemText>
                            </ListItem>
                        </Link>
                        <Link to="/meiden" title="meiden" className={classes.link}>
                            <ListItem button key={"meiden"}>
                                <ListItemText className={classes.text}>
                                    <p className={"drawer-link"}>Meiden</p>
                                </ListItemText>
                            </ListItem>
                        </Link>
                        <Link to="/rezepte" title="rezepte" className={classes.link}>
                            <ListItem button key={"rezepte"}>
                                <ListItemText className={classes.text}>
                                    <p className={"drawer-link"}>Rezepte</p>
                                </ListItemText>
                            </ListItem>
                        </Link>
                    </div>
                    }


                    <br/>
                    <br/>


                    <Link to="/" onClick={props.handleLogout} className={classes.link}>
                        <ListItem button key={"logout"}>
                            <ListItemText className={classes.text}>
                                <p className={"drawer-link"}>Ausloggen</p>
                            </ListItemText>
                        </ListItem>
                    </Link>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
                }

                {/*{props.appUserLoggedIn === false &&*/}
                {/*<div>*/}
                {/*    <h1 className={"h1--lined h1--drawer"} style={{textAlign: "center"}}>bellydays</h1>*/}
                {/*    <Link to="/about" title="about" className={classes.link}>*/}
                {/*        <ListItem button key={"about"}>*/}
                {/*            <ListItemText className={classes.text}>*/}
                {/*                <p className={"drawer-link"}>Ãœber Dr. med. Alexandra Knauer</p>*/}
                {/*            </ListItemText>*/}
                {/*        </ListItem>*/}
                {/*    </Link>*/}
                {/*    <Link to="/wissenswertes" title="wissenswertes" className={classes.link}>*/}
                {/*        <ListItem button key={"wissenswertes"}>*/}
                {/*            <ListItemText className={classes.text}>*/}
                {/*                <p className={"drawer-link"}>Wissenswertes</p>*/}
                {/*            </ListItemText>*/}
                {/*        </ListItem>*/}
                {/*    </Link>*/}
                {/*    <Link to="/tutorial" title="tutorial" className={classes.link}>*/}
                {/*        <ListItem button key={"tutorial"}>*/}
                {/*            <ListItemText className={classes.text}>*/}
                {/*                <p className={"drawer-link"}>So einfach funktioniert die Analyse</p>*/}
                {/*            </ListItemText>*/}
                {/*        </ListItem>*/}
                {/*    </Link>*/}
                {/*    <Link to="/" title="login" className={classes.link}>*/}
                {/*        <ListItem button key={"login"}>*/}
                {/*            <ListItemText className={classes.text}>*/}
                {/*                <p className={"drawer-link"}>Login</p>*/}
                {/*            </ListItemText>*/}
                {/*        </ListItem>*/}
                {/*    </Link>*/}
                {/*</div>*/}
                {/*}*/}

            </List>
        </div>
    );


    return (

        <div>
            <IconButton onClick={toggleDrawer('right', true)} color="secondary" aria-label="Menu" className={"hamburger-button"}>
                <div className={classes.placeholderDiv} style={{display: "flex", justifyContent: "center"}}><img alt="Hamburger" className={"app-bar-icon"} src={Hamburger}/></div>
            </IconButton>

            <Drawer anchor="right" open={state.right} onClose={toggleDrawer('right', false)}>
                {sideList('right')}
            </Drawer>
        </div>
    );
}

export default SwipeableTemporaryDrawer;
