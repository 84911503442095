import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { useEffect } from 'react'
import './App.scss';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import useGTM from '@elgorditosalsero/react-gtm-hook'

const AppWrapper = () => {
	const { init, UseGTMHookProvider } = useGTM()
   
	useEffect(() => init({ id: 'GTM-K62F2GQ' }), [])
   
	return (
	  <UseGTMHookProvider>
		<App />
	  </UseGTMHookProvider>
	)
  }

ReactDOM.render(<AppWrapper />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

