import React from 'react';
import AppBarComp from "./../components/global/AppBarComp";
import CircleLargeCommented from "../assets/images/circle.svg";
import CircleLargeCommentedNoG from "../assets/images/circle-large.svg";
import EomSnackbar from "../components/global/EomSnackbar";

class Tutorial extends React.Component {
    componentDidMount(){
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className={"app-wrapper"}>
                <div id={"select"}></div>
                <main className={"main"}>
                    <AppBarComp appUserLoggedIn={this.props.appUserLoggedIn}
                                handleLogout={this.props.handleLogout}/>
                    <article className={"article article--with-stripe fade"}>
                        <div className={"article-inner"}>
                            <h1 className={"h1--lined--dark"}>So einfach
                                funktioniert</h1>
                            <h1 className={"h1--lined--dark"}>die Typisierung</h1>
                            <br/><br/>

                            <div className={"logoIconLargeContainer"}>
                                <div className={"iconLargeWrapper"}>
                                    <img src={CircleLargeCommented}
                                         alt={"circle-large"}
                                         style={{width: "100%"}}/>
                                </div>
                                <div className={"logoIconLargeText"}>
                                    <div className={"arrowUp"}/>
                                    <p className={"tooltip"}>Aufgrund deiner Antworten, während der Analyse, berechne ich eine Typisierung: A bis G — jeweils
                                        abgestuft von 3 bis 1. Je näher du zu
                                        deiner Mitte findest, desto gesünder und
                                        glücklicher bist du. Dadurch erweitert
                                        sich auch die Vielfalt an Lebensmitteln,
                                        die du essen kannst.</p>
                                </div>
                            </div>

                            <br/>
                            <br/>

                            <h1 className={"h1--lined--dark"}
                                style={{marginBottom: "10px"}}>Das heisst im
                                Detail</h1>
                            <p>Ein gesunder Körper kann sich an äußere
                                Lebensumstände anpassen. Deshalb ist es nicht
                                erforderlich, sich immer zu optimal zu ernähren.
                                Jedoch lassen bei Krankheit oder Stress,
                                falscher Ernährung und zunehmendem Alter deine
                                Kompensationsmechanismen nach.
                                Dein Körper gibt dir Signale, er spricht
                                mit dir in Form von Empfindungen und
                                Symptomen. Durch deine Wahrnehmungen
                                kann festgestellt werden, in welchem
                                körperlichen Zustand du dich befindest.</p>
                            <p>Anhand deiner Körperreaktionen wird die Typisierung A bis G erfasst, die dir aufzeigt, in
                                welchem Gesundheitszustand du dich
                                momentan befindest und zu welchen
                                Erkrankungsmustern du neigst.
                                Die Ausprägung von 3 bis 1 zeigt an,
                                wie weit du dich von deiner Mitte und
                                somit aus deiner inneren Balance entfernt
                                hast. Je näher du der Mitte bist,
                                desto gesünder bist du.</p>
                            <p>Durch die Typisierung kann ermittelt werden,
                                mit welcher Ernährungsweise du deine
                                Gesundheit fördern kannst. Dabei werden
                                Lebensmittelempfehlungen individuell
                                auf deinen Zustand abgestimmt.
                                Je mehr du in deiner Mitte bist, desto
                                größer wird die Auswahl dessen,
                                was du essen kannst.</p>

                            <br/>
                            <br/>

                            <div className={"logoIconLargeContainer"}
                                 id={"no-g2"}>
                                <div className={"iconLargeWrapper"}>
                                    <img src={CircleLargeCommentedNoG}
                                         alt={"circle-large"}
                                         style={{width: "100%"}}/>
                                </div>
                                <div className={"logoIconLargeText"}>
                                    <div className={"arrowUp"}/>
                                    <p className={"tooltip"}
                                       style={{textAlign: "center"}}>Bist du in
                                        deiner Mitte,
                                        fühlst du dich wohl und bleibst
                                        gesund. Hier gibt es keine
                                        Ernährungseinschränkungen –
                                        iss, worauf du Lust hast!</p>
                                </div>
                            </div>

                            <br/>
                            <br/>

                            <p>Mit der bellydays-Typsierung kann ich feststellen,
                                in welchem Element du bist, welche Lebensmittel
                                dir gut tun und was du zu dir nehmen solltest,
                                um gesund und fit zu bleiben.</p>

                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <EomSnackbar />
                        </div>
                    </article>
                </main>
            </div>
        );
    }
}

export default Tutorial;
