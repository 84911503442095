import React from 'react';
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Checkbox from '@material-ui/core/Checkbox';

import FormLabel from '@material-ui/core/FormLabel';

import Divider from "@material-ui/core/Divider";
import {withStyles} from "@material-ui/styles";

const styles = theme => ({
    selectWrapperInner: {
        padding: "20px",
        height: "100%"
    },

    backgroundLight:  {
        backgroundColor: "#ffffff"
    },

    formControlLabel: {
        display: "flex",
        flexDirection: "column-reverse",
        paddingTop: "20px",
        paddingBottom: "20px"
    },
});


class EomNewOptionsAllergie extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            laktose: false,
            fruktose: false,
            gluten: false,
            histamin: false,
            andere: false,

            allergie: []
        };
    }

    hideAppBar =()=> {
        document.getElementsByTagName("HEADER")[0].style.display = "none";
        document.getElementsByTagName("ARTICLE")[0].style.paddingTop = "50px";
    };

    componentWillMount() {
        let allergie = JSON.parse(localStorage.getItem("allergie"));
        if(allergie !== null) {
            this.setState({
                laktose: allergie[0],
                fruktose: allergie[1],
                gluten: allergie[2],
                histamin: allergie[3],
                andere: allergie[4],
            });
        }
    }

    componentDidMount=()=> {
        window.scrollTo(0, 1);
        // this.hideAppBar();
    };

    handleChange=(value)=> {
        switch(value) {
            case "laktose":
                this.setState({
                    laktose: !this.state.laktose
                });
                break;

            case "fruktose":
                this.setState({
                    fruktose: !this.state.fruktose
                });
                break;

            case "gluten":
                this.setState({
                    gluten: !this.state.gluten
                });
                break;

            case "histamin":
                this.setState({
                    histamin: !this.state.histamin
                });
                break;

            case "andere":
                this.setState({
                    andere: !this.state.andere
                });
                break;

            default:
            console.log("nothing valuable clicked")
        }
    };

    componentWillUnmount() {
        localStorage.setItem("allergie", JSON.stringify([
            this.state.laktose, this.state.fruktose, this.state.gluten, this.state.histamin, this.state.andere
        ]))
    }

    render() {
        return (
            <div className={"article-wrapper-stepper_select"}>
                <div className={"fade"} style={{width: "100%"}}>
                    <div>
                        <div className={"checkbox-group"} >
                            <FormLabel component="legend">Laktose</FormLabel>
                            <FormControlLabel
                                id={"select-allergie"}
                                value={this.state.laktose}
                                checked={this.state.laktose}
                                control={<Checkbox color="primary"/>}
                                label=""
                                labelPlacement="top"
                                onChange={() => this.handleChange("laktose")}
                            />
                        </div>

                            <Divider className={"select-wrapper-divider"}/>

                        <div className={"checkbox-group"}>
                            <FormLabel component="legend">Fruktose</FormLabel>
                            <FormControlLabel
                                value={this.state.fruktose}
                                checked={this.state.fruktose}
                                control={<Checkbox color="primary" />}
                                label=""
                                labelPlacement="top"
                                onChange={() => this.handleChange("fruktose")}
                            />
                        </div>

                            <Divider className={"select-wrapper-divider"}/>

                         <div className={"checkbox-group"}>
                            <FormLabel component="legend">Gluten</FormLabel>
                            <FormControlLabel
                                value={this.state.gluten}
                                checked={this.state.gluten}
                                control={<Checkbox color="primary" />}
                                label=""
                                labelPlacement="top"
                                onChange={() => this.handleChange("gluten")}
                            />
                         </div>

                            <Divider className={"select-wrapper-divider"}/>

                          <div className={"checkbox-group"}>
                            <FormLabel component="legend">Histamin</FormLabel>
                            <FormControlLabel
                                value={this.state.histamin}
                                checked={this.state.histamin}
                                control={<Checkbox color="primary" />}
                                label=""
                                labelPlacement="top"
                                onChange={() => this.handleChange("histamin")}
                            />
                          </div>

                            <Divider className={"select-wrapper-divider"}/>

                          <div className={"checkbox-group"}>
                            <FormLabel component="legend">Andere</FormLabel>
                            <FormControlLabel
                                value={this.state.andere}
                                checked={this.state.andere}
                                control={<Checkbox color="primary" />}
                                label=""
                                labelPlacement="top"
                                onChange={() => this.handleChange("andere")}
                            />
                          </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default withStyles(styles) (EomNewOptionsAllergie);
