import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import Rezepte from "../../assets/images/rezepte.jpg";
import Belly from "../../assets/images/belly.jpg";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles({
    main: {
        textAlign: "center",
        margin: "0 auto",
        width: "100%",
        minHeight: "calc(100vh - 84px)",
        background: "#ffffff"
    },
    link: {
        textDecoration: "none",
        color: "inherit",
        width: "100%",
        textAlign: "center"

    },
});

// function startBellydays() {
//     //Start Date
//     let startDate = JSON.stringify(new Date().getTime());
//     localStorage.setItem("startDate", startDate);
//     window.location.href='/profil';
// }

function EomSnackbar(props) {

    const classes = useStyles();

    const [state] = React.useState({
        open: true,
        vertical: 'bottom',
        horizontal: 'center',
        startDate: JSON.parse(localStorage.getItem("startDate"))
    });



    const { vertical, horizontal, open } = state;

    function renderSnackbar () {
        const eom_type = localStorage.getItem("belly-type");
        const startDate1 = JSON.parse(sessionStorage.getItem("startDate"));
        // console.log(startDate1);
        // console.log(new Date(startDate1).getTime())
        // console.log(new Date().getTime())
        const startDate = new Date(startDate1).getTime();

        // Get today's date and time
        let now = new Date().getTime();

        // Find the distance between now and the count down date
        let difference =  now - startDate;

        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(difference / (1000 * 60 * 60 * 24));

        // As we start with day 1 we have to add 1, otherwise start would be at 0
        let day = days + 1;


        if (localStorage.getItem("belly-type") === null) {
            return (
                <Snackbar
                    className={"snackbar"}
                    anchorOrigin={{ vertical, horizontal }}
                    key={`${vertical},${horizontal}`}
                    open={open}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={
                        <Button
                            style={{margin: "0 auto", width: "100%"}}
                            variant="contained"
                            color="primary"
                            className={"button--primary"}
                            name="submit"
                            type="submit">
                            <Link to="/analyse" title="analyse" className={classes.link}>Zur Analyse</Link>
                        </Button>}
                />
            );
        }



        else {
            return (
                <Snackbar
                    style={{background: "#ffffff", borderTop: "1px solid black", padding: "5px 20px"}}
                    className={"snackbar snackbar--profile"}
                    anchorOrigin={{ vertical, horizontal }}
                    key={`${vertical},${horizontal}`}
                    open={open}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={
                        <div>
                            {/*<Button*/}
                            {/*    style={{margin: "0 auto", width: "100%"}}*/}
                            {/*    variant="contained">*/}
                            {/*    <Link to={"/favourites"} className={"cleaned-link"}>*/}
                            {/*        <img src={Favourites} alt={"favouriten"} className={"navi-icon-profile"}/>*/}
                            {/*    </Link>*/}
                            {/*</Button>*/}
                            <Button
                                style={{margin: "0 auto", width: "100%"}}
                                variant="contained">
                                <Link to={"/profil"} className={"cleaned-link"}>
                                    <img src={Belly} alt={"belly"} className={"navi-icon-profile"}/>
                                </Link>
                            </Button>
                            {/*<Button*/}
                            {/*    style={{margin: "0 auto", width: "100%"}}*/}
                            {/*    variant="contained">*/}
                            {/*    <Link to={"/cart"} className={"cleaned-link"}>*/}
                            {/*        <img src={Cart} alt={"cart"} className={"navi-icon-profile"}/>*/}
                            {/*    </Link>*/}
                            {/*</Button>*/}

                            {(startDate !== null && day > 0) &&
                            <Button
                                style={{margin: "0 auto", width: "100%"}}
                                variant="contained">
                                <a href={"/gut-fuer-dich-main"} className={"cleaned-link"}
                                      style={{flexDirection: "row", justifyContent: "center", fontSize: "18px"}}>
                                    <span>Tag </span>
                                    <Badge badgeContent={day} color="primary"><span style={{color: "white"}}>Tag</span></Badge>
                                </a>
                            </Button>
                            }
                            {(startDate !== null && day <= 0) &&
                            <Button
                                style={{margin: "0 auto", width: "100%"}}
                                variant="contained">
                                <a href={"/gut-fuer-dich-main"} className={"cleaned-link"}
                                   style={{flexDirection: "row", justifyContent: "center", fontSize: "18px"}}>
                                    <span>Gut für mich </span>
                                </a>
                            </Button>
                            }

                            <Button
                                style={{margin: "0 auto", width: "100%"}}
                                variant="contained">
                                <Link to={"/rezepte"} className={"cleaned-link"}>
                                    <img src={Rezepte} alt={"rezepte"} className={"navi-icon-profile"}/>
                                </Link>
                            </Button>
                            {/*<Button*/}
                            {/*    style={{margin: "0 auto", width: "100%"}}*/}
                            {/*    variant="contained">*/}
                            {/*    <Link to={"/calories"} className={"cleaned-link"}>*/}
                            {/*        <img src={Kalorien} alt={"kalorien"} className={"navi-icon-profile"}/>*/}
                            {/*    </Link>*/}
                            {/*</Button>*/}
                            {/*<Button*/}
                            {/*    style={{margin: "0 auto", width: "100%"}}*/}
                            {/*    variant="contained">*/}
                            {/*    <Link to={"/reminders"} className={"cleaned-link"}>*/}
                            {/*        <img src={Reminder} alt={"reminders"} className={"navi-icon-profile"}/>*/}
                            {/*    </Link>*/}
                            {/*</Button>*/}
                        </div>
                        }
                />
            )
        }
    }

    return (
        renderSnackbar()
    );
}


export default EomSnackbar;

