import React, {Component} from 'react';

import AppBarComp from "../components/global/AppBarComp";
import EomSnackbar from "../components/global/EomSnackbar";

import {Link} from "react-router-dom";
import AnalyseFirst from "./AnalyseFirst";
import config from "../config";
const JSONAPI_ROOT = `${config.base}/jsonapi/`;

class GutFuerDichMain extends Component {
    constructor() {
        super();
        this.state = {
            eomType: "initial state",
            appState: [],
            token: JSON.parse(localStorage.getItem('token')),
            premium: sessionStorage.getItem('premium')
        };
    }

    loadNodeAppStateData =()=> {
        this.fetchJsonApiGetAppState('data', `${JSONAPI_ROOT}node/app_state?filter[title]=App%20State`, true);
    };

    fetchJsonApiGetAppState =(destination, url)=> {
        fetch(url, {
            method: 'GET',
            //mode: 'cors',
        })
            .then(function(response) {
                return response.json();
            })
            .then(data => {
                let appState=[];
                for (let i = 0; i < data.data.length; i++) {
                    appState.push(data.data[i].attributes.field_phase1, data.data[i].attributes.field_phase2, data.data[i].attributes.field_phase3);
                }
                this.setState({'appState': appState});
                localStorage.setItem('appState', JSON.stringify(appState));
            })
            .catch(err => console.log('API error:', err));
    };

    componentWillMount() {
        const eom_type = localStorage.getItem("belly-type");
        this.setState({
            eomType: eom_type
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.loadNodeAppStateData();
    }

    eomColor=()=> {
        if (this.state.eomType.indexOf("A") !== -1) {
            return(
                "eom-a"
            )
        }
        else if (this.state.eomType.indexOf("B") !== -1) {
            return(
                "eom-b"
            )
        }
        else if (this.state.eomType.indexOf("C") !== -1) {
            return(
                "eom-c"
            )
        }
        else if (this.state.eomType.indexOf("D") !== -1) {
            return(
                "eom-d"
            )
        }
        else if (this.state.eomType.indexOf("E") !== -1) {
            return(
                "eom-e"
            )
        }
        else if (this.state.eomType.indexOf("F") !== -1) {
            return(
                "eom-f"
            )
        }
        else if (this.state.eomType.indexOf("G") !== -1) {
            return(
                "eom-g"
            )
        }
    };

    render() {
        return (
            <div>
                {this.state.eomType !== "initial state" && this.state.eomType !== null && this.state.eomType !== undefined &&

                <div className={"app-wrapper"}>
                <main className={"main"}>
                    <AppBarComp appUserLoggedIn={this.props.appUserLoggedIn} handleLogout={this.props.handleLogout}/>
                    <article className={"article article--with-stripe fade"}>
                        <div>
                            <div className={this.eomColor()}>
                                <div className={"rezepte-colored-stripe"}/>
                            </div>
                            <div className={"article-inner"}>
                                <h1 className={"h1--lined--dark"}>
                                    <div>Gut für dich</div>
                                </h1>
                            </div>

                            <div className={"article-inner"}>
                                {this.state.appState[0] === true &&
                                <>
                                    <Link to="/phase1" title="Phase 1" className={"cleaned-link"}>
                                        <h1 className={"h1--lined--dark drawer-link--dark"}>Phase 1</h1>
                                        {this.state.appState[0]}
                                    </Link>
                                    <br/>
                                </>
                                }
                                {/*{this.state.appState[0] === false &&*/}
                                {/*<>*/}
                                {/*    <span>*/}
                                {/*        <h1 style={{borderBottom: '1px solid #c6c6c6'}} className={"h1--lined--dark"}><span style={{color: '#c6c6c6'}}>Tag 1 - 10</span></h1>*/}
                                {/*        {this.state.appState[0]}*/}
                                {/*    </span>*/}
                                {/*    <br/>*/}
                                {/*</>*/}
                                {/*}*/}

                                {(this.state.appState[1] === true && this.state.premium === "true") &&
                                <>
                                    <Link to="/phase2" title="Phase 2"
                                          className={"cleaned-link"}>
                                        <h1 className={"h1--lined--dark drawer-link--dark"}>Phase 2</h1>
                                    </Link>
                                    <br/>
                                </>
                                }

                                {(this.state.appState[2] === true && this.state.premium === "true") &&
                                <>
                                    <Link to="/phase3" title="Phase 3"
                                          className={"cleaned-link"}>
                                        <h1 className={"h1--lined--dark drawer-link--dark"}>Phase 3</h1>
                                    </Link>
                                    <br/>
                                </>
                                }

                            </div>
                        </div>

                    </article>
                </main>

                <EomSnackbar/>
            </div>
                }
                {this.state.eomType === "initial state" &&
                <AnalyseFirst
                    appUserLoggedIn={this.props.appUserLoggedIn} handleLogout={this.props.handleLogout}
                />
                }
            </div>

        );
    }
}

export default GutFuerDichMain;

