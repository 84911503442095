import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import theme from "./../theme/theme.js";


import AppBarComp from "../components/global/AppBarComp";
import Login from "../components/login/login";

const useStyles = makeStyles({
    logoIconLargeContainer: {
        position: "relative",
        textAlign: "center",
        margin: "0 auto"
    },
    logoIconLarge: {
        [theme.breakpoints.up('lg')]: {
            width: "100%",
        },
        width: "100%",
        padding: "20px 10% !important"
    },
    logoIconLargeText: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%"
    },
});

function FrontpageLocked(props) {
    const classes = useStyles();
    const [completed, setCompleted] = React.useState(0);
    const [buffer, setBuffer] = React.useState(10);
    const [visibleTop1, setVisibleTop1] = React.useState(true);
    const [visibleTop2, setVisibleTop2] = React.useState(false);
    const [visibleBottom, setVisibleBottom] = React.useState(true);
    const [visibleLogin, setVisibleLogin] = React.useState(false);
    const [visibleImage, setVisibleImage] = React.useState(false);


    const progress = React.useRef(() => {});
    React.useEffect(() => {
        function tick() {
            progress.current();
        }
        const timer = setInterval(tick, 500);

        progress.current = () => {
            if (completed === 100) {
                setCompleted(100);
                setBuffer(10);
                clearInterval(timer);
            } else {
                const diff = 2 * 10;
                const diff2 = 2 * 10;
                setCompleted(completed + diff);
                setBuffer(completed + diff + diff2);
            }
        };
        return () => {
            clearInterval(timer);
        };
    });

    if (completed === 100) {
        (function visibilityTop() {
            setTimeout(
                function() {
                    setVisibleTop1(false);
                    setVisibleTop2(true);
                }, 4500);
        })();
    }

    function loadLogin() {
        setVisibleTop1(false);
        setVisibleTop2(false);
        setVisibleBottom(false);
        setVisibleLogin(true);
        document.getElementById("fade-wrapper--secondtext").style.display = "none";
    }

    return (
        <div>
            {/*{completed < 100 &&*/}
            {/*<div className={"app-wrapper--logged"}>*/}
            {/*    <div className={"app"}>*/}
            {/*        <main className={"main-landingpage-loading"}>*/}
            {/*            <article className={"main-landingpage-loading-article"}>*/}
            {/*                <div>*/}
            {/*                    <SvgIcon viewBox="0 0 67 67" className={"logo-icon"}>*/}
            {/*                        <path*/}
            {/*                            d=" M 67 33.5 C 67 14.999 52.001 0 33.5 0 C 14.998 0 0 14.999 0 33.5 C 0 52.001 14.998 67 33.5 67 C 52.001 67 67 52.001 67 33.5 Z  M 58.579 28.776 C 59.154 30.546 57.384 31.121 57.384 31.121 L 41.781 36.191 L 51.424 49.463 C 52.518 50.969 51.012 52.063 51.012 52.063 L 45.743 55.891 C 44.237 56.985 43.143 55.48 43.143 55.48 L 33.5 42.207 L 23.857 55.48 C 22.763 56.985 21.257 55.891 21.257 55.891 L 15.988 52.063 C 14.482 50.969 15.576 49.463 15.576 49.463 L 25.219 36.191 L 9.616 31.121 C 7.846 30.546 8.421 28.776 8.421 28.776 L 10.434 22.581 C 11.009 20.811 12.779 21.386 12.779 21.386 L 28.382 26.456 L 28.382 10.05 C 28.382 8.189 30.243 8.189 30.243 8.189 L 36.757 8.189 C 38.618 8.189 38.618 10.05 38.618 10.05 L 38.618 26.456 L 54.221 21.386 C 55.991 20.811 56.566 22.581 56.566 22.581 L 58.579 28.776 Z "*/}
            {/*                            fill="rgb(254,254,254)"/>*/}
            {/*                    </SvgIcon>*/}
            {/*                    <br/>*/}

            {/*                    <h1 className={"logo-text"}>*/}
            {/*                        element of mine*/}
            {/*                    </h1>*/}

            {/*                    <h2 className={"logo-text-small"}>*/}
            {/*                        nach Dr. med. Alexandra Knauer*/}
            {/*                    </h2>*/}
            {/*                </div>*/}

            {/*                <div className={"progress-bar"}>*/}
            {/*                    <h2 className={"logo-text-small"} style={{letterSpacing: "0 !important"}}>*/}
            {/*                        gesund und fit bleiben — jung alt werden*/}
            {/*                    </h2>*/}
            {/*                    <LinearProgress id={"progress-bar"} color="secondary" variant="buffer" value={completed} valueBuffer={buffer}/>*/}
            {/*                </div>*/}
            {/*            </article>*/}
            {/*        </main>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*}*/}


            {/*{completed >= 99 &&*/}

            <div className={"app-wrapper"}>
                <div className={"app"}>
                    <main
                        className={"main-landingpage-loaded"}
                        style={{

                        }}>
                        <AppBarComp
                            appUserLoggedIn={props.appUserLoggedIn}
                            handleLogout={props.handleLogout}/>
                        {/*<Fade in={true} timeout={2500}>*/}
                        <div className={"login-wrapper"} >
                            {/*<div className={"fade-wrapper--secondtext"}>*/}
                            {/*    <div className={visibleTop1?'fadeIn':'fadeOut'}>*/}
                            {/*        <div style={{marginTop: "20px", paddingLeft: "20px", paddingRight: "20px"}}>*/}
                            {/*            <h1 className={"h1--lined--dark h1--large"}>Sag mir wie du</h1>*/}
                            {/*            <h1 className={"h1--lined--dark h1--large"}>dich fühlst,</h1>*/}
                            {/*            <h1 className={"h1--lined--dark h1--large"}>dann bringe ich dich</h1>*/}
                            {/*            <h1 className={"h1--lined--dark h1--large"}>in deine Mitte</h1>*/}
                            {/*        </div>*/}
                            {/*        <div className={classes.logoIconLargeContainer}>*/}
                            {/*            <Button size="small" className={classes.logoIconLarge} >*/}
                            {/*                <img src={props.circleLarge} alt={"circle-large"} className={"landingpage-circle fade"}/>*/}
                            {/*                <div className={classes.logoIconLargeText} id={"logo-icon-large-text"}>*/}
                            {/*                    <h1 className={"h1--lined--dark h1--large"} style={{borderBottom: "none", textTransform: "none"}}>Hier geht es</h1>*/}
                            {/*                    <h1 className={"h1--lined--dark h1--large"} style={{borderBottom: "none", textTransform: "none"}}>zu deinem</h1>*/}
                            {/*                    <h1 className={"h1--lined--dark h1--large"} style={{borderBottom: "none", textTransform: "none"}}>persönlichen</h1>*/}
                            {/*                    <h1 className={"h1--lined--dark h1--large"} style={{borderBottom: "none", textTransform: "none"}}>EOM–PreCheck</h1>*/}
                            {/*                </div>*/}
                            {/*            </Button>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className={visibleTop2?'fadeIn':'fadeOut'} id={"fade-wrapper--secondtext"}>*/}
                            {/*        <div className={"landingpage-foo"}>*/}
                            {/*            <h1 className={"h1--notlined align--left"}>Schön, dass du da bist!</h1>*/}
                            {/*            <p>Ich bin dein persönlicher EOM–Coach. Dich ganz in deinem Element zu fühlen, ist ab sofort unser gemeinsames Ziel. Ich helfe dir gesund zu bleiben und dich wohl zu fühlen, ohne auf Etwas verzichten zu müssen.</p>*/}
                            {/*        </div>*/}

                            {/*        <div className={classes.logoIconLargeContainer}>*/}
                            {/*            <Button size="small" className={classes.logoIconLarge} onClick={loadLogin}>*/}
                            {/*                <img src={CircleLarge} alt={"circle-large"} className={"landingpage-circle"}/>*/}
                            {/*                <div className={classes.logoIconLargeText} id={"logo-icon-large-text"}>*/}
                            {/*                    <h1 className={"h1--lined--dark h1--large"} style={{borderBottom: "none", textTransform: "none"}}>Hier geht es</h1>*/}
                            {/*                    <h1 className={"h1--lined--dark h1--large"} style={{borderBottom: "none", textTransform: "none"}}>zu deinem</h1>*/}
                            {/*                    <h1 className={"h1--lined--dark h1--large"} style={{borderBottom: "none", textTransform: "none"}}>persönlichen</h1>*/}
                            {/*                    <h1 className={"h1--lined--dark h1--large"} style={{borderBottom: "none", textTransform: "none"}}>EOM–PreCheck</h1>*/}
                            {/*                </div>*/}
                            {/*            </Button>*/}
                            {/*        </div>*/}

                            {/*    </div>*/}
                            {/*</div>*/}


                            {/*                                <div className={visibleTop1?'fadeIn':'fadeOut'}>
                                    <div className={classes.logoIconLargeContainer}>
                                        <Button size="small" className={classes.logoIconLarge} onClick={loadLogin}>
                                            <img src={CircleLarge} alt={"circle-large"} className={"landingpage-circle"}/>
                                            <div className={classes.logoIconLargeText} id={"logo-icon-large-text"}>
                                                <h1 className={"h1--lined--dark h1--large"} style={{borderBottom: "none", textTransform: "none"}}>Hier geht es</h1>
                                                <h1 className={"h1--lined--dark h1--large"} style={{borderBottom: "none", textTransform: "none"}}>zu deinem</h1>
                                                <h1 className={"h1--lined--dark h1--large"} style={{borderBottom: "none", textTransform: "none"}}>persönlichen</h1>
                                                <h1 className={"h1--lined--dark h1--large"} style={{borderBottom: "none", textTransform: "none"}}>EOM–Check</h1>
                                            </div>
                                        </Button>
                                    </div>
                                </div>*/}

                            <div style={{padding: "10px 10%"}}>
                                <div className={"fade-wrapper--login"}>
                                    <Login
                                        appUserLoggedIn={props.appUserLoggedIn}
                                        loginFunction={props.loginFunction}/>

                                </div>

                            </div>

                        </div>
                        {/*<div className={"article-inner frontpage-name--desktop"} style={{paddingTop: "0", paddingBottom: "0"}}>*/}
                        {/*    <h1 className={"h1--lined--dark p--small"} style={{marginTop: 0, lineHeight: 2, textAlign: "center", paddingLeft: "10px"}}>Dr. med. Alexandra Knauer</h1>*/}
                        {/*</div>*/}
                        {/*</Fade>*/}
                    </main>
                </div>
            </div>

            {/*}*/}

        </div>
    );
}


export default FrontpageLocked;
