import React from 'react';
import Slider from '@material-ui/core/Slider';

import Up from "./../../../assets/images/up.svg"
import Down from "./../../../assets/images/down.svg"

class EomNewWheelCold extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 5
        };
    }

    handleSliderChange = (event, newValue) => {
        this.setState({
            value: newValue
        });
        localStorage.setItem("coldsense", newValue);
    };

    componentWillMount() {
        let coldsense = localStorage.getItem("coldsense");
        if(coldsense !== null) {
            this.setState({
                value: coldsense,
            });
        }
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        localStorage.setItem("coldsense", this.state.value);
    }

    render() {
        return (
            <div className={"article-wrapper-stepper_select"}>
                <div className={"fade"} style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                }}>
                    <React.Fragment>
                        <p id="vertical-slider" style={{
                            lineHeight: 1,
                            marginBottom: 0,
                            color: "white",
                            flex: 1,
                            display: "flex",
                            justifyContent: "flex-end",
                            textAlign: "center"
                        }}>
                            Mir ist meistens zu warm
                        </p>
                        <img src={Up} alt={"up"} className={"up-down-button"}/>
                        <div className={"slider-balken"} style={{
                            height: "50vh",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            width: "100%"
                        }}>

                            <Slider
                                style={{flex: 1}}
                                orientation="vertical"
                                onChange={this.handleSliderChange}
                                defaultValue={5}
                                aria-labelledby="vertical-slider"
                                min={0}
                                max={10}
                            />
                        </div>
                        <img src={Down} alt={"up"} className={"up-down-button"}/>
                        <p id="vertical-slider" style={{
                            lineHeight: 1,
                            marginBottom: 0,
                            color: "white",
                            flex: 1,
                            display: "flex",
                            justifyContent: "flex-start",
                            textAlign: "center"
                        }}>
                            Ich friere schnell
                        </p>
                    </React.Fragment>
                </div>
            </div>
        );
    }
}

export default EomNewWheelCold;


