import {createMuiTheme} from "@material-ui/core";

/*
Breakpoints(

Matieral UI:
xs: 0
sm: 600
md: 960
lg: 1280
xl: 1920

iPad Pro:
1024
)*/



const theme = createMuiTheme({
  palette: {
    primary: {
      //light: '#fff',
      main: '#34292D',
      //dark: '#000'
    },
    secondary: {
      main: '#ffffff',
    },

    error: {
      main: "#b71c1c"
    },

    background: {
      default: "#cfd8dc"
    },
  },
  props: {
    MuiTypography: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h2',
        h4: 'h2',
        h5: 'h2',
        h6: 'h2',
        subtitle1: 'h2',
        subtitle2: 'h2',
        body1: 'span',
        body2: 'p',
      },
    },
  },
  overrides: {
    // Name of the component ⚛️ / style sheet
/*    MuiButton: {
      // Name of the rule
      text: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        //borderRadius: 3,
        //border: 0,
        //color: 'white',
        //height: 48,
        //padding: '0 30px',
        //boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      },
    },*/
    MuiAppBar: {
      root: {
        transition: "top 0.3s",
      },
      colorDefault: {
        backgroundColor: "#34292D"
      }
    },
    MuiDrawer: {
      paper: {
        padding: "20px",
        margin: "0 auto",
        backgroundColor: "#34292D",
        color: "#ffffff",
        minHeight: "100vh !important",
      }
    },

    MuiTypography: {
      root: {
        fontFamily: "Stag_Sans-Light"
      },
      body1: {
        fontFamily: "Stag_Sans-Light"
      },
      body2: {
        fontFamily: "Stag_Sans-Light"
      },
      h1: {
        fontFamily: "Stag_Sans-Light",
        fontSize: "2.5rem"
      },
    },
    MuiLinearProgress: {
      root: {
        height: "2px"
      }
    },
    MuiMobileStepper: {
      root: {
        background: "transparent",
        justifyContent: "center"
      },
      progress: {
        display: "none"
      }
    },
    MuiButton: {
      label: {
        fontFamily: "Stag_Sans-Light"
      }
    },
    MuiInputBase: {
      root: {
        fontFamily: "Stag_Sans-Light"
      },
      input: {
        fontFamily: "Stag_Sans-Light"
      }
    },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
        backgroundColor: "#ffffff",
        borderRadius: "12px",
        border: "1px solid #34292D"
      }
    },
    MuiFormLabel: {
      root: {
        fontFamily: "Stag_Sans-Light",
      },
    },
    MuiFormControl: {
      root: {
        width: "100%"
      },
    },
    MuiFormControlLabel: {
      root: {
        fontFamily: "Stag_Sans-Light",
        color: "#ffffff",
      },
    },
    PrivateRadioButtonIcon: {
      root: {
        color: "#ffffff"
      }
    },
    MuiRadio: {
      root:  {
        display: "block",

      }
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      }
    },
    MuiListItem: {
/*      root: {
        borderBottom: "1px solid #ffffff"
      },*/
      gutters: {
        padding: 0,
        paddingLeft:0,
        paddingRight:0
      }
    },
    MuiListItemText: {
      root: {
        marginBottom: 0,
        marginTop: 0,
      }
    },
    MuiSnackbar: {
      root:  {
        left: 0,
        right: 0,
        bottom: 0,
      },
      anchorOriginBottomCenter: {
        left: "0 !important",
        right: "0 !important",
        bottom: "0 !important",
        transform: "none !important"
      }
    },
    MuiSnackbarContent: {
      root: {
        borderRadius: 0,
        border: "none",
        backgroundColor: "#34292D",
        color: "#ffffff",
        padding: 0
      },
      message: {
        width: "100%",
        padding: 0
      }
    },
    PrivateSwitchBase: {
      root: {
        padding: 0
      }
    },
    MuiToolbar: {
      root: {
        backgroundColor: "#34292D",
        color: "white",
        maxHeight: '86px',
      },
      regular: {
        maxWidth: "1110px",
        margin: "0 auto",
      },
    },
/*    MuiIconButton: {
      label: {
        transform: "scale(1.5)"
      }
    }*/
    MuiBadge: {
      badge: {
        transform: "scale(1.5)",
      },
      anchorOriginTopRightRectangle: {
        transform: 'scale(1.5)',
        right: '-15px'
      }
    },
    // MuiTabs: {
    //   flexContainer: {
    //     flexDirection: "column"
    //   }
    // }

    MuiSlider: {
      track: {
        display: 'inline',
      },
      rail: {
        display: 'inline',
      }
    },
  },
});

export default theme;
