import React from "react";
import {makeStyles} from "@material-ui/core";

import LoaderIcon from "../../assets/images/loader.svg";

const useStyles = makeStyles({
    loader: {
        margin: "0 auto",
        display: "flex",
flexDirection: "column",
justifyContent: "center",
// height: "60vh"
},
    loaderText: {
        textAlign: "center"
    }
});


function Loader(props) {
    const classes = useStyles();

    return (
        <div className={props.fade} style={{
            height: "100vh",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        }}>
            <div className={classes.loader}>
                <div className={"logo-spin"}>
                    <img src={LoaderIcon} alt={"circle-large"} style={{width: "100%"}}/>
                </div>
                <br/>

            </div>
            <p className={classes.loaderText}>Bitte um etwas Geduld,<br/>
                ich ermittle deine Typisierung</p>
        </div>
    );
}
export default Loader;
