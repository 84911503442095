import React from 'react';
import Slider from '@material-ui/core/Slider';

import Up from "./../../../assets/images/up.svg"
import Down from "./../../../assets/images/down.svg"

class EomNewWheelTemperature extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 21
        };
    }

    handleSliderChange = (event, newValue) => {
        this.setState({
            value: newValue
        });
        localStorage.setItem("temperature", newValue);
    };

    componentWillMount() {
        let temperature = localStorage.getItem("temperature");
        if(temperature !== null) {
            this.setState({
                value: temperature,
            });
        }
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        localStorage.setItem("temperature", this.state.value);
    }

    render() {
        return (
            <div className={"article-wrapper-stepper_select"}>
                <div className={"fade"} style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                }}>
                    <React.Fragment>
                        <img src={Up} alt={"up"} className={"up-down-button"}/>
                        <div style={{
                            height: "50vh",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            width: "100%"
                        }}>
                            <p id="vertical-slider" style={{
                                lineHeight: 1,
                                marginBottom: 0,
                                color: "white",
                                flex: 1,
                                display: "flex",
                                justifyContent: "flex-end",
                                textAlign: "center"
                            }}>
                                Grad<br/>(°C)
                            </p>
                            <Slider
                                style={{flex: 1}}
                                orientation="vertical"
                                onChange={this.handleSliderChange}
                                defaultValue={21}
                                aria-labelledby="vertical-slider"
                                min={-10}
                                max={42}
                            />
                            <p id="vertical-slider" style={{
                                lineHeight: 1,
                                marginBottom: 0,
                                color: "white",
                                flex: 1,
                                display: "flex",
                                justifyContent: "flex-start",
                                textAlign: "center"
                            }}>
                                {this.state.value}
                            </p>
                        </div>
                        <img src={Down} alt={"up"} className={"up-down-button"}/>
                    </React.Fragment>
                </div>
            </div>
        );
    }
}

export default EomNewWheelTemperature;


