import React from 'react';
import AppBarComp from "./../components/global/AppBarComp";
import Video4 from "../assets/videos/bellydays4.m4v";
import EomSnackbar from "../components/global/EomSnackbar";

function DeinMorgenritual(props) {

    return (
        <>
            <div className={"app-wrapper"}>
                <div id={"select"}> </div>
                <main className={"main"}>
                    <AppBarComp appUserLoggedIn={props.appUserLoggedIn}
                                handleLogout={props.handleLogout}/>
                    <article className={"article article--with-stripe fade"}>
                        <div className={"article-inner staticcontent"}>
                            <h1 className={"h1--lined--dark"}>Dein
                                Morgenritual</h1>
                            <br/><br/>
                            <div className={"aut-video-container"}>
                                <div className="container">
                                    <div className='Flexible-container'>
                                        <video width="500" height="322"
                                               controls>
                                            <source src={Video4}/>
                                        </video>
                                    </div>
                                </div>
                            </div>
                            <br/>

                            <ul>
                                <li>Beginne deinen Tag mit der Bauchmassage im
                                    Bett.
                                </li>
                                <li>Gleich anschließend nach dem Aufstehen ist
                                    das Bittersalz mit Basenpulver zu
                                    trinken. Bis zum Frühstück sollte ein
                                    Abstand von 30 min eingehalten werden.
                                </li>
                                <li>Die Morgenhygiene beginnt mit der
                                    wohltuenden Massage durch die Körperbürste.
                                    Danach folgt die Dusche – erst mit warmen
                                    und abschließend mit kaltem Wasser!
                                </li>
                                <li>Vor dem Frühstück trinke 1-2 Tassen
                                    Basenkräutertee und nimm deine empfohlenen
                                    Nahrungsergänzungsmittel ein (siehe Deine
                                    Nahrungsergänzung).
                                </li>
                                <li>Nimm dir für dein Frühstück mindestens eine
                                    halbe Stunde Zeit und denk daran, dass
                                    du pro Bissen 30-40 x kauen solltest!
                                </li>
                                <li>Achte auf einen regelmäßigen Stuhlgang. Wenn
                                    der Stuhl bereits flüssig ist, kannst du
                                    das Bittersalz am nächsten Tag reduzieren
                                    oder durch Magnesiumcitrat ersetzen.
                                </li>
                                <li>Achte im Laufe des Tages auf deine
                                    Flüssigkeitszufuhr!
                                </li>
                            </ul>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                    </article>
                </main>
                <EomSnackbar/>
            </div>

            </>
    );
}

export default DeinMorgenritual;
