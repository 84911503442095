import React from 'react';
import AppBarComp from "./../components/global/AppBarComp";
import Video5 from "../assets/videos/bellydays5.m4v";
import EomSnackbar from "../components/global/EomSnackbar";

function DeinAbendritual(props) {
    return (
        <>
        <div className={"app-wrapper"}>
            <div id={"select"}> </div>
            <main className={"main"}>
                <AppBarComp appUserLoggedIn={props.appUserLoggedIn} handleLogout={props.handleLogout}/>
                <article className={"article article--with-stripe fade"}>
                    <div className={"article-inner staticcontent"}>
                        <h1 className={"h1--lined--dark"}>Dein Abendritual</h1>
                        <br/><br/>
                        <div className={"aut-video-container"}>
                            <div className="container">
                                <div className='Flexible-container'>
                                    <video width="500" height="322" controls>
                                        <source src={Video5}/>
                                    </video>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <ul>
                            <li>Dein Abendessen sollte vor 18 Uhr stattfinden. Iss bitte nur eine Kleinigkeit oder
                                verzichte auf die Abendmahlzeit. Du kannst stattdessen eine Tasse Basensuppe oder
                                Basenpulver in Wasser gelöst trinken.</li>
                            <li>Komm am Abend zur Ruhe, am besten bei Entspannungsmusik, einem guten Buch
                                oder einer Entspannungsmeditation.</li>
                            <li>Trink abends 1-2 Tassen Entspannungstee (z.B. Melisse, Lavendel, Orangenblüten)</li>
                            <li>Eine Blaulichtfilter-Brille sorgt dafür, dass nach Sonnenuntergang keine kurzwelligen
                                Blaulichtanteile des elektrischen Lichts auf deine Augennetzhaut treffen. So werden
                                dein Biorhythmus und die zirkadiane Ausschüttung von Melatonin unterstützt. Das
                                fördert den erholsamen Schlaf.</li>
                            <li>Erinnere dich bitte an die Bauchmassage, wenn du im Bett liegst!</li>
                        </ul>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </div>
                </article>
            </main>
            <EomSnackbar/>
        </div>

        </>
    );
}

export default DeinAbendritual;
