import React, {Component} from 'react';

import config from "../config";
import Rezept from "../components/rezept/Rezept";
import AppBarComp from "../components/global/AppBarComp";
import EomSnackbar from "../components/global/EomSnackbar";
import AnalyseFirst from "./AnalyseFirst";
const JSONAPI_ROOT = `${config.base}/jsonapi/`;

class Rezepte extends Component {
    constructor() {
        super();
        this.state = {
            eomType: "initial state",
            rezepte: []
        };
    }

    loadNodeRezeptData =()=> {
        this.fetchJsonApiGetRezept('data', `${JSONAPI_ROOT}node/rezept?sort=-created`, true);
    };

    fetchJsonApiGetRezept =(destination, url)=> {
        fetch(url, {
            method: 'GET',
            //mode: 'cors',
        })
            .then(function(response) {
                return response.json();
            })
            .then(data => {
                let rezepte=[];
                for (let i = 0; i < data.data.length; i++) {
                    rezepte.push([data.data[i].id, data.data[i].attributes.title]);
                }
                this.setState({'rezepte': rezepte});
                localStorage.setItem('rezepte', JSON.stringify(rezepte));
            })
            .catch(err => console.log('API error:', err));
    };

    renderRezepte() {
        let rezepteMarkup = [];
        for (let i = 0; i < this.state.rezepte.length; i++) {
            rezepteMarkup.push(
                <Rezept
                    key={i}
                    eom_type={this.state.eomType}
                    appUserLoggedIn={this.state.appUserLoggedIn}
                    contentType="rezept"
                    id={this.state.rezepte[i][0]}/>
            )
        }
        return (
            <div>
                {rezepteMarkup}
            </div>
        )
    };

    componentWillMount() {
        const eom_type = localStorage.getItem("belly-type");
        this.setState({
            eomType: eom_type
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.loadNodeRezeptData();
    }

    eomColor=()=> {
        if (this.state.eomType.indexOf("A") !== -1) {
            return(
                "eom-a"
            )
        }
        else if (this.state.eomType.indexOf("B") !== -1) {
            return(
                "eom-b"
            )
        }
        else if (this.state.eomType.indexOf("C") !== -1) {
            return(
                "eom-c"
            )
        }
        else if (this.state.eomType.indexOf("D") !== -1) {
            return(
                "eom-d"
            )
        }
        else if (this.state.eomType.indexOf("E") !== -1) {
            return(
                "eom-e"
            )
        }
        else if (this.state.eomType.indexOf("F") !== -1) {
            return(
                "eom-f"
            )
        }
        else if (this.state.eomType.indexOf("G") !== -1) {
            return(
                "eom-g"
            )
        }
    };

    render() {
        return (
            <div>
                {this.state.eomType !== "initial state" && this.state.eomType !== null && this.state.eomType !== undefined &&
                <div className={"app-wrapper"}>
                    <main className={"main"}>
                        <AppBarComp appUserLoggedIn={this.props.appUserLoggedIn} handleLogout={this.props.handleLogout}/>
                        <article className={"article article--with-stripe fade"} style={{paddingBottom: "100px"}}>
                <div className={this.eomColor()}>
                    <div className={"rezepte-colored-stripe"}/>
                    <div className={"article-inner"}>
                        <h1 className={"h1--lined--dark"}>
                            Rezepte
                        </h1>
                    </div>
                    {this.renderRezepte()}
                </div>
                </article>
                    </main>
                    <EomSnackbar/>
                </div>
                }
                {this.state.eomType === "initial state" &&
                    <AnalyseFirst
                        appUserLoggedIn={this.props.appUserLoggedIn} handleLogout={this.props.handleLogout}
                    />
                }
            </div>
        );
    }
}

export default Rezepte;

