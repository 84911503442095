import React from 'react';

import AppBarComp from "./../components/global/AppBarComp";
import CircleLarge from "../assets/images/circle-large.svg";
import EomSnackbar from "../components/global/EomSnackbar";

function FrontpageUnLocked(props) {
    return (
        <div className={"app-wrapper"} id={"app-wrapper"}>
            <div className={"app"}>
                <main className={"main"}>
                    <AppBarComp
                        appUserLoggedIn={props.appUserLoggedIn}
                        handleLogout={props.handleLogout}
                        eomType={props.eomType}/>

                    <div className={"article article--with-stripe fade"} style={{paddingBottom: "100px"}}>
                        <div className={"article-inner"}>
                            <h1 className={"h1--lined--dark"}>Ich zeige dir, in welchem</h1>
                            <h1 className={"h1--lined--dark"}>Element du bist</h1>
                            <h1 className={"h1--lined--dark"}>und was dich fit und gesund hält</h1>
                        </div>

                        <div className={"image-wrapper jamie"} style={{background: `url("knauer.jpg"`}}/>

                        <div className={"article-inner"} style={{paddingTop: "0", paddingBottom: "0"}}>
                            <h1 className={"h1--lined--dark p--small"} style={{marginTop: 0, lineHeight: 2, textAlign: "left", paddingLeft: "10px"}}>Dr. med. Alexandra Knauer</h1>
                        </div>

                        <div className={"article-inner"}>
                            <p>Es liegt ab jetzt in deiner Hand,
                                diese App ehrlich und sinnvoll für dich zu nutzen.
                                Ich stehe dir natürlich unterstützend zur Seite.
                                Dafür muss ich dir ein paar Fragen stellen.</p>
                        </div>

                        <div className={"article-inner"} style={{paddingTop: "0"}}>
                            <h1 className={"h1--lined--dark"}>Ich helfe dir dabei</h1>
                            <h1 className={"h1--lined--dark"}>deine persönliche Mitte zu finden</h1>
                            <h1 className={"h1--lined--dark"}>und fit und gesund zu bleiben</h1>
                        </div>

                        <div className={"article-inner"}>
                         <img src={CircleLarge} alt={"circle-large"} style={{width: "100%"}}/>
                        </div>

                        <div className={"article-inner"}>
                            <p>Mit der <span className={"aut-app-name"}>bellydays–Analyse</span> kann ich feststellen,
                                in welchem Element du bist, welche Lebensmittel
                                dir gut tun und was du zu dir nehmen solltest,
                                um gesund und fit zu bleiben.</p>
                        </div>
                    </div>

                    <EomSnackbar/>

                </main>
            </div>
        </div>
    );
}


export default FrontpageUnLocked;
