import React from 'react';
import AppBarComp from "../components/global/AppBarComp";
import { Link } from "react-router-dom";
import EomSnackbar from "../components/global/EomSnackbar";
import AnalyseFirst from "./AnalyseFirst";

class Profil extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            eomType: localStorage.getItem('belly-type'),
            open: true,
            vertical: "bottom",
            horizontal: "bottom",
            allergien: [],
            ausschlusse: [],
            startDate: null,
            token: JSON.parse(localStorage.getItem('token')),
            eom_done: localStorage.getItem('eom-done'),
        };
    }



    componentWillMount() {
        const startDate = localStorage.getItem("startDate");

        this.setState({
            // allergien: allergien,
            // ausschlusse: ausschlusse,
            startDate: startDate,
        });
    }



    startBellydays() {
        //Start Date
        let startDate = JSON.stringify(new Date().getTime());
        localStorage.setItem("startDate", startDate);
        window.location.href='/profil';
    }

    componentDidMount(){
        window.scrollTo(0, 0);
    }

    eomColor=()=> {
        if (this.state.eomType.indexOf("A") !== -1) {
            return(
                "eom-a"
            )
        }
        else if (this.state.eomType.indexOf("B") !== -1) {
            return(
                "eom-b"
            )
        }
        else if (this.state.eomType.indexOf("C") !== -1) {
            return(
                "eom-c"
            )
        }
        else if (this.state.eomType.indexOf("D") !== -1) {
            return(
                "eom-d"
            )
        }
        else if (this.state.eomType.indexOf("E") !== -1) {
            return(
                "eom-e"
            )
        }
        else if (this.state.eomType.indexOf("F") !== -1) {
            return(
                "eom-f"
            )
        }
        else if (this.state.eomType.indexOf("G") !== -1) {
            return(
                "eom-g"
            )
        }
    };

    render() {
        const getAllergien = localStorage.getItem('allergieServ');
        let allergien;
        if (getAllergien !==null) {
            allergien = getAllergien.split(', ');
        }
        const getAusschlusse = localStorage.getItem('ausschlussServ');
        let ausschlusse;
        if (getAusschlusse !==null) {
            ausschlusse = getAusschlusse.split(', ');
        }

        return (
            <div>
                {this.state.eom_done !== "initial state" && this.state.eom_done !== null && this.state.eom_done !== undefined &&
                <div className={"app-wrapper"}>
                    <div id={"select"}> </div>
                    <main className={"main"}>
                        <AppBarComp appUserLoggedIn={this.props.appUserLoggedIn}
                                    handleLogout={this.props.handleLogout}/>
                        <article className={"article article--with-stripe fade"}>

                            <div>
                                <div className={this.eomColor()}>
                                    <div className={"rezepte-colored-stripe"}/>
                                </div>

                                <div className={"article-inner"}>
                                    <h1 className={"h1--lined--dark"}>Deine Typisierung ist:</h1>

                                    <div className="tcm-text-circle">
                                        <svg viewBox="0 0 140 140" preserveAspectRatio="xMinYMin meet">
                                            <g>
                                                <circle r="50%" cx="50%" cy="50%" className="circle-back white"
                                                        stroke="black" strokeDasharray="0.5" strokeWidth="0.5"/>

                                            </g>
                                            <g className={this.eomColor()}>
                                                <circle r="42%" cx="50%" cy="50%" className="circle-back"/>
                                                <text x="49%" y="52%" textAnchor="middle"
                                                      dy="0.3em">{this.state.eomType}</text>
                                            </g>

                                        </svg>
                                    </div>
                                    <p>Deine Typisierung ist eine grobe Einschätzung deiner grundsätzlichen Konstitution sowie der momentanen körperlichen Verfassung nach <span className={"aut-app-name"}>Traditioneller Chinesischer Medizin</span>.</p>
                                    {/*<p>Denke immer daran, <span className={"aut-app-name"}>es liegt in deiner Hand </span>*/}
                                    {/*    wie du dich fühlst, aussiehst, wie gesund du bist*/}
                                    {/*    und ob du jung alt wirst.</p>*/}

                                    {/*{(this.state.startDate === null) &&*/}
                                    {/*<>*/}
                                    {/*<Button*/}
                                    {/*    style={{margin: "0 auto", width: "100%", textTransform: 'none', background: '#34292D', color: '#ffffff', fontSize: 20, lineHeight: 1.5}}*/}
                                    {/*    variant="contained">*/}
                                    {/*    <div onClick={this.startBellydays} className={this.eomColor()} style={{letterSpacing: '3px',fontWeight: 'bold'}}>bellydays<br/>STARTEN</div>*/}
                                    {/*</Button>*/}
                                    {/*<br/>*/}
                                    {/*</>*/}
                                    {/*}*/}
                                    <br/>

                                    {(allergien && allergien.length > 0 && allergien[0] !== "null" && allergien[0] !== "keine") &&
                                        <div>
                                            <p><strong>Du hast folgende Unverträglichkeiten angegeben:</strong></p>
                                            <ul style={{textAlign: "left", paddingLeft: "18px", marginTop: 0}}>
                                                {allergien.indexOf("Laktose") !== -1 &&
                                                <li style={{paddingBottom: "10px"}}>Laktose</li>}
                                                {allergien.indexOf("Fruktose") !== -1 &&
                                                <li style={{paddingBottom: "10px"}}>Fruktose</li>}
                                                {allergien.indexOf("Gluten") !== -1 &&
                                                <li style={{paddingBottom: "10px"}}>Gluten</li>}
                                                {allergien.indexOf("Histamin") !== -1 &&
                                                <li style={{paddingBottom: "10px"}}>Histamin</li>}
                                            </ul>
                                        </div>
                                    }

                                    {(ausschlusse && ausschlusse.length > 0 && ausschlusse[0] !== "null" && ausschlusse[0] !== "keine" && ausschlusse[0] !== "ich esse alles") &&

                                    <div>
                                        <p><strong>Du isst nicht:</strong></p>
                                        <ul style={{textAlign: "left", paddingLeft: "18px", paddingBottom: "10px", marginTop: 0}}>
                                            {ausschlusse.indexOf("Fleisch") !== -1 &&
                                            <li style={{paddingBottom: "10px"}}>Fleisch</li>}
                                            {ausschlusse.indexOf("Fisch") !== -1 &&
                                            <li style={{paddingBottom: "10px"}}>Fisch</li>}
                                            {ausschlusse.indexOf("Milch und Milchprodukte") !== -1 &&
                                            <li style={{paddingBottom: "10px"}}>Milch und Milchprodukte</li>}
                                            {ausschlusse.indexOf("Eier") !== -1 &&
                                            <li style={{paddingBottom: "10px"}}>Eier</li>}
                                        </ul>
                                    </div>
                                    }

                                    <Link to={"/typisierung"} >
                                        <h1 style={{marginTop: "40px"}} className={"h1--lined--dark drawer-link--dark"}>
                                            MEHR ZUR TYPISIERUNG " <strong>{this.state.eomType.substring(0, this.state.eomType.length - 1)}</strong> "
                                        </h1>
                                    </Link>

                                    {/*<Link to={"/tutorial"} >*/}
                                    {/*    <h1 style={{marginTop: "40px"}} className={"h1--lined--dark drawer-link--dark"}>*/}
                                    {/*        DAS BEDEUTET TYPISIERUNG"*/}
                                    {/*    </h1>*/}
                                    {/*</Link>*/}
                                    <br/>
                                    <p style={{marginTop: "40px", textAlign: "center"}}>Basierend auf deiner Typisierung " <span className={"aut-app-name"}>{this.state.eomType}</span> " geben wir dir folgende Empfehlungen:</p>

                                    <Link to={"/gut-fuer-dich-main"} >
                                        <h1 style={{marginTop: "40px"}} className={"h1--lined--dark drawer-link--dark"}>
                                            GUT FÜR DICH
                                        </h1>
                                    </Link>

                                    <Link to={"/meiden"} >
                                        <h1 style={{marginTop: "40px"}} className={"h1--lined--dark drawer-link--dark"}>
                                            MEIDEN
                                        </h1>
                                    </Link>

                                    <Link to={"/rezepte"} >
                                        <h1 style={{marginTop: "40px"}} className={"h1--lined--dark drawer-link--dark"}>
                                            REZEPTE
                                        </h1>
                                    </Link>


                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                </div>
                            </div>

                        </article>

                        <EomSnackbar/>
                    </main>
                </div>
                }

                {this.state.eom_done === null &&
                <AnalyseFirst
                    appUserLoggedIn={this.props.appUserLoggedIn} handleLogout={this.props.handleLogout}
                />
                }
            </div>
        );
    }
}

export default Profil;
