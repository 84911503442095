import React, {Component} from 'react';

import AppBarComp from "../components/global/AppBarComp";
import DoEomFirst from "../components/doEomFirst/doEomFirst";
import EomSnackbar from "../components/global/EomSnackbar";

import config from "../config";

const JSONAPI_ROOT = `${config.base}/jsonapi/`;

class Phase extends Component {
    constructor() {
        super();
        this.state = {
            eomType: "initial state",
            appState: [],
            allergien: [],
            ausschluss: [],
            getreide: [],
            gewuerze: [],
            obst: [],
            gemuese: [],
            fisch: [],
            fleisch: [],
            ei: [],
            veganeeiweissquelle: [],
            milchprodukt: [],
            getraenk: [],
            alkohol: [],
            fett: [],
        }
    }

    loadNodeAppStateData =()=> {
        this.fetchJsonApiGetAppState('data', `${JSONAPI_ROOT}node/app_state?filter[title]=App%20State`, true);
    };

    fetchJsonApiGetAppState =(destination, url)=> {
        fetch(url, {
            method: 'GET',
            //mode: 'cors',
        })
            .then(function(response) {
                return response.json();
            })
            .then(data => {
                let appState=[];
                for (let i = 0; i < data.data.length; i++) {
                    appState.push(data.data[i].attributes.field_phase1, data.data[i].attributes.field_phase2, data.data[i].attributes.field_phase3);
                }
                this.setState({'appState': appState});
                localStorage.setItem('appState', JSON.stringify(appState));
            })
            .catch(err => console.log('API error:', err));
    };

    loadNodeNahrungsmittelData =()=> {
        this.fetchJsonApiGetNahrungsmittel('data', `${JSONAPI_ROOT}node/nahrungsmittel?sort=title`, true);
    };

    fetchJsonApiGetNahrungsmittel =(destination, url)=> {
        fetch(url, {
            method: 'GET',
            //mode: 'cors',
        })
            .then(function(response) {
                return response.json();
            })
            .then(data => {
                let getreide=[];
                let gewuerze=[];
                let obst=[];
                let gemuese=[];
                let fisch=[];
                let fleisch=[];
                let ei=[];
                let veganeeiweissquelle=[];
                let milchprodukt=[];
                let getraenk=[];
                let alkohol=[];
                let fett=[];

                for (let i = 0; i < data.data.length; i++) {
                    let cat = data.data[i].attributes.field_kategorie; // ARRAY OF CATEGORIEScategory(ies) of current food
                    let phase; // ARRAY OF EOM-TYPES: get all variations of EOM-TYPES that are allowed to eat this food in current phase (view)

                    if (this.props.phase === 1) {
                        phase = data.data[i].attributes.field_phase_1;
                    } else if (this.props.phase === 2) {
                        phase = data.data[i].attributes.field_phase_2;
                    } else if (this.props.phase === 3) {
                        phase = data.data[i].attributes.field_phase_3;
                    }

                    if (phase.indexOf(this.state.eomType) !== - 1) { // If MY EOM-TYPE is in Array of allows EOM-TYPES in this phase for that food
                        let addIt = [];

                        //allergien
                        let allergien = [];
                        for (let x = 0; x < data.data[i].attributes.field_unvertraglichkeiten.length; x++) {
                            allergien.push(data.data[i].attributes.field_unvertraglichkeiten[x]);
                        }
                        let md1 = allergien; // ALLERGIEN OF THIS FOOD
                        let md2 = this.state.allergien; // ALLERGIEN I HAVE
                        // console.log(this.state.allergien);

                        for(let x = 0; x < md2.length; x++){
                            if (md1.indexOf(md2[x]) !== -1) { // check if ONE OF MY ALLERGIES (md2) corresponds to the FOODS'S ALLERGIES(md1)
                                addIt.push("false");
                            } else {
                                addIt.push("true");
                            }
                        }

                        //ausschluss
                        let ausschluss = [];
                        for (let x = 0; x < cat.length; x++) {
                            ausschluss.push(cat[x]);
                        }
                        let ad1 = ausschluss; // holds lebensmittel category
                        let ad2 = this.state.ausschluss; // holds individual ausschluss (category)
                        // console.log(ad2)
                        //
                        if ((ad2.indexOf("Milch und Milchprodukte") !== -1) && (ad2.indexOf("Milchprodukt") === -1)) {
                            ad2.push("Milchprodukt");
                        }

                        if ((ad2.indexOf("Eier") !== -1) && (ad2.indexOf("Ei") === -1)) {
                            ad2.push("Ei");
                        }
                        // console.log(ad1)
                        // console.log("-_________- " + ad1 + ' -> ' + typeof(ad1) + ' -> ' + ad1.indexOf('Gemuese'));
                        // console.log("-_________- " + ad2 + ' -> ' + typeof(ad2) + ' -> ' + ad2.indexOf('Milch und Milchprodukte'));

                        for(let x = 0; x < ad2.length; x++){
                            if (ad1.indexOf(ad2[x]) !== -1) {
                                addIt.push("false");
                            } else {
                                addIt.push("true");
                            }
                        }
                        // console.log('addIt: ' + addIt);
                        // console.log('allergien: ' + allergien);
                        // console.log('ausschluss: ' + ausschluss);

                        //push lebensmittel to corresponding category
                        //AS THIS IS A FOR LOOP, THIS RUNS FOR EVERY SINGLE FOOD
                        //IF THERE IS ONE "true" IN addIT then don't add this food
                        //-1 -> means that at least one of the allergien & ausschlüsse is true
                        if (addIt.indexOf("false") === -1) { //if there is at least one true value
                            if (cat.indexOf("Getreide") !== -1 ) {
                                getreide.push([data.data[i].id, data.data[i].attributes.title]);
                            }
                            if (cat.indexOf("Gewürz") !== -1 ) {
                                gewuerze.push([data.data[i].id, data.data[i].attributes.title]);
                            }
                            if (cat.indexOf("Obst") !== -1 ) {
                                obst.push([data.data[i].id, data.data[i].attributes.title]);
                            }
                            if (cat.indexOf("Gemuese") !== -1 ) {
                                gemuese.push([data.data[i].id, data.data[i].attributes.title]);
                            }
                            if (cat.indexOf("Fisch") !== -1 ) {
                                fisch.push([data.data[i].id, data.data[i].attributes.title]);
                            }
                            if (cat.indexOf("Fleisch") !== -1 ) {
                                fleisch.push([data.data[i].id, data.data[i].attributes.title]);
                            }
                            if (cat.indexOf("Vegane Eiweissquelle") !== -1 ) {
                                veganeeiweissquelle.push([data.data[i].id, data.data[i].attributes.title]);
                            }
                            if (cat.indexOf("Fett") !== -1 ) {
                                fett.push([data.data[i].id, data.data[i].attributes.title]);
                            }
                            if (cat.indexOf("Getränk") !== -1 ) {
                                getraenk.push([data.data[i].id, data.data[i].attributes.title]);
                            }
                            if (cat.indexOf("Alkohol") !== -1 ) {
                                alkohol.push([data.data[i].id, data.data[i].attributes.title]);
                            }
                            if (cat.indexOf("Ei") !== -1 ) {
                                ei.push([data.data[i].id, data.data[i].attributes.title]);
                            }
                            if (cat.indexOf("Milchprodukt") !== -1 ) {
                                milchprodukt.push([data.data[i].id, data.data[i].attributes.title]);
                            }
                        }
                    }
                }


                this.setState({
                    'getreide': getreide,
                    'gewuerze': gewuerze,
                    'obst':obst,
                    'gemuese':gemuese,
                    'fisch':fisch,
                    'fleisch':fleisch,
                    'ei':ei,
                    'veganeeiweissquelle':veganeeiweissquelle,
                    'milchprodukt':milchprodukt,
                    'getraenk':getraenk,
                    'alkohol':alkohol,
                    'fett':fett,
                });
            })
            .catch(err => console.log('API error:', err));
    };

    renderGetreide() {
        let nahrungsmittelMarkup = [];
        for (let i = 0; i < this.state.getreide.length; i++) {
            nahrungsmittelMarkup.push(
                <li><p>{this.state.getreide[i][1]}</p></li>
            )
        }
        if (this.state.getreide.length > 0) {
            return (
                <div>
                    <h1 className={"h1--lined--dark"}>GETREIDE</h1>
                    <ul style={{textAlign: "left", marginBottom: "50px"}}>
                        {nahrungsmittelMarkup}
                    </ul>
                </div>
            )
        }
    };

    renderGewuerze() {
        let nahrungsmittelMarkup = [];
        for (let i = 0; i < this.state.gewuerze.length; i++) {
            nahrungsmittelMarkup.push(
                <li><p>{this.state.gewuerze[i][1]}</p></li>
            )
        }
        if (this.state.gewuerze.length > 0) {
            return (
                <div>
                    <h1 className={"h1--lined--dark"}>GEWÜRZE</h1>
                    <ul style={{textAlign: "left", marginBottom: "50px"}}>
                        {nahrungsmittelMarkup}
                    </ul>
                </div>
            )
        }
    };

    renderObst() {
        let nahrungsmittelMarkup = [];
        for (let i = 0; i < this.state.obst.length; i++) {
            nahrungsmittelMarkup.push(
                <li><p>{this.state.obst[i][1]}</p></li>
            )
        }
        if (this.state.obst.length > 0) {
            return (
                <div>
                    <h1 className={"h1--lined--dark"}>OBST</h1>
                    <ul style={{textAlign: "left", marginBottom: "50px"}}>
                        {nahrungsmittelMarkup}
                    </ul>
                </div>
            )
        }
    };

    renderGemuese() {
        let nahrungsmittelMarkup = [];
        for (let i = 0; i < this.state.gemuese.length; i++) {
            nahrungsmittelMarkup.push(
                <li><p>{this.state.gemuese[i][1]}</p></li>
            )
        }
        if (this.state.gemuese.length > 0) {
            return (
                <div>
                    <h1 className={"h1--lined--dark"}>GEMÜSE</h1>
                    <ul style={{textAlign: "left", marginBottom: "50px"}}>
                        {nahrungsmittelMarkup}
                    </ul>
                </div>
            )
        }
    };

    renderFisch() {
        let nahrungsmittelMarkup = [];
        for (let i = 0; i < this.state.fisch.length; i++) {
            nahrungsmittelMarkup.push(
                <li><p>{this.state.fisch[i][1]}</p></li>
            )
        }
        if (this.state.fisch.length > 0) {
            return (
                <div>
                    <h1 className={"h1--lined--dark"}>FRISCHER FISCH</h1>
                    <ul style={{textAlign: "left", marginBottom: "50px"}}>
                        {nahrungsmittelMarkup}
                    </ul>
                </div>
            )
        }
    };

    renderFleisch() {
        let nahrungsmittelMarkup = [];
        for (let i = 0; i < this.state.fleisch.length; i++) {
            nahrungsmittelMarkup.push(
                <li><p>{this.state.fleisch[i][1]}</p></li>
            )
        }
        if (this.state.fleisch.length > 0) {
            return (
                <div>
                    <h1 className={"h1--lined--dark"}>FLEISCH</h1>
                    <ul style={{textAlign: "left", marginBottom: "50px"}}>
                        {nahrungsmittelMarkup}
                    </ul>
                </div>
            )
        }
    };

    renderEi() {
        let nahrungsmittelMarkup = [];
        for (let i = 0; i < this.state.ei.length; i++) {
            nahrungsmittelMarkup.push(
                <li><p>{this.state.ei[i][1]}</p></li>
            )
        }
        if (this.state.ei.length > 0) {
            return (
                <div>
                    <h1 className={"h1--lined--dark"}>EI</h1>
                    <ul style={{textAlign: "left", marginBottom: "50px"}}>
                        {nahrungsmittelMarkup}
                    </ul>
                </div>
            )
        }
    };

    renderVeganeEiweissquelle() {
        let nahrungsmittelMarkup = [];
        for (let i = 0; i < this.state.veganeeiweissquelle.length; i++) {
            nahrungsmittelMarkup.push(
                <li><p>{this.state.veganeeiweissquelle[i][1]}</p></li>
            )
        }
        if (this.state.veganeeiweissquelle.length > 0) {
            return (
                <div>
                    <h1 className={"h1--lined--dark"}>VEGANE PROTEINQUELLE</h1>
                    <ul style={{textAlign: "left", marginBottom: "50px"}}>
                        {nahrungsmittelMarkup}
                    </ul>
                </div>
            )
        }
    };

    renderMilchprodukt() {
        let nahrungsmittelMarkup = [];
        for (let i = 0; i < this.state.milchprodukt.length; i++) {
            nahrungsmittelMarkup.push(
                <li><p>{this.state.milchprodukt[i][1]}</p></li>
            )
        }
        if (this.state.milchprodukt.length > 0) {
            return (
                <div>
                    <h1 className={"h1--lined--dark"}>MILCHPRODUKTE</h1>
                    <ul style={{textAlign: "left", marginBottom: "50px"}}>
                        {nahrungsmittelMarkup}
                    </ul>
                </div>
            )
        }
    };

    renderFett() {
        let nahrungsmittelMarkup = [];
        for (let i = 0; i < this.state.fett.length; i++) {
            nahrungsmittelMarkup.push(
                <li><p>{this.state.fett[i][1]}</p></li>
            )
        }
        if (this.state.fett.length > 0) {
            return (
                <div>
                    <h1 className={"h1--lined--dark"}>FETT</h1>
                    <ul style={{textAlign: "left", marginBottom: "50px"}}>
                        {nahrungsmittelMarkup}
                    </ul>
                </div>
            )
        }
    };

    renderGetraenk() {
        let nahrungsmittelMarkup = [];
        for (let i = 0; i < this.state.getraenk.length; i++) {
            nahrungsmittelMarkup.push(
                <li><p>{this.state.getraenk[i][1]}</p></li>
            )
        }
        if (this.state.getraenk.length > 0) {
            return (
                <div>
                    <h1 className={"h1--lined--dark"}>GETRÄNKE</h1>
                    <ul style={{textAlign: "left", marginBottom: "50px"}}>
                        {nahrungsmittelMarkup}
                    </ul>
                </div>
            )
        }
    };

    renderAlkohol() {
        let nahrungsmittelMarkup = [];
        for (let i = 0; i < this.state.alkohol.length; i++) {
            nahrungsmittelMarkup.push(
                <li><p>{this.state.alkohol[i][1]}</p></li>
            )
        }
        if (this.state.alkohol.length > 0) {
            return (
                <div>
                    <h1 className={"h1--lined--dark"}>ALKOHOLISCHE GETRÄNKE</h1>
                    <ul style={{textAlign: "left", marginBottom: "30px"}}>
                        {nahrungsmittelMarkup}
                    </ul>
                </div>
            )
        }
    };

    componentWillMount() {
        const eom_type = localStorage.getItem("belly-type");
        // const allergie = localStorage.getItem("allergie");
        // const ausschl = localStorage.getItem("ausschluss");
        //
        // let allergien = [];
        //
        // if (JSON.parse(allergie)[0] === true) {
        //     allergien.push("Laktose");
        // }
        // if (JSON.parse(allergie)[1] === true) {
        //     allergien.push("Fruktose");
        // }
        // if (JSON.parse(allergie)[2] === true) {
        //     allergien.push("Gluten");
        // }
        // if (JSON.parse(allergie)[3] === true) {
        //     allergien.push("Histamin");
        // }
        //
        // let ausschluss = [];
        //
        // if (JSON.parse(ausschl)[0] === true) {
        //     ausschluss.push("Fleisch");
        // }
        // if (JSON.parse(ausschl)[1] === true) {
        //     ausschluss.push("Fisch");
        // }
        // if (JSON.parse(ausschl)[2] === true) {
        //     ausschluss.push("Milchprodukt");
        // }
        // if (JSON.parse(ausschl)[3] === true) {
        //     ausschluss.push("Ei");
        // }

        const getAllergien = localStorage.getItem('allergieServ');
        let allergien = getAllergien.split(', ');
        const getAusschlusse = localStorage.getItem('ausschlussServ');
        let ausschluss = getAusschlusse.split(', ');

        this.setState({
            eomType: eom_type,
            allergien: allergien,
            ausschluss: ausschluss
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.loadNodeNahrungsmittelData();
        this.loadNodeAppStateData();
    }

    eomColor=()=> {
        if (this.state.eomType.indexOf("A") !== -1) {
            return(
                "eom-a"
            )
        }
        else if (this.state.eomType.indexOf("B") !== -1) {
            return(
                "eom-b"
            )
        }
        else if (this.state.eomType.indexOf("C") !== -1) {
            return(
                "eom-c"
            )
        }
        else if (this.state.eomType.indexOf("D") !== -1) {
            return(
                "eom-d"
            )
        }
        else if (this.state.eomType.indexOf("E") !== -1) {
            return(
                "eom-e"
            )
        }
        else if (this.state.eomType.indexOf("F") !== -1) {
            return(
                "eom-f"
            )
        }
        else if (this.state.eomType.indexOf("G") !== -1) {
            return(
                "eom-g"
            )
        }
    };

    render() {
        return (
            <div className={"app-wrapper"}>
                <main className={"main"}>
                    <AppBarComp appUserLoggedIn={this.props.appUserLoggedIn} handleLogout={this.props.handleLogout}/>
                    <article className={"article article--with-stripe fade"}>
                        {/*
                        * ONLINE
                        * if true that phase is online
                        */}
                        {((this.props.phase === 1 && this.state.appState[0] === true) || (this.props.phase === 2 && this.state.appState[1] === true) || (this.props.phase === 3 && this.state.appState[2] === true)) ?
                            <>
                                {(this.state.eomType === null || this.state.eomType === undefined || this.state.eomType === "initial state") &&
                                <DoEomFirst />
                                }

                                {(this.state.eomType !== null && this.state.eomType !== undefined && this.state.eomType !== "initial state") &&

                                <div>
                                    <div className={this.eomColor()}>
                                        <div className={"rezepte-colored-stripe"}/>
                                    </div>
                                    <div className={"article-inner"}>
                                        <h1 className={"h1--notlined"}>
                                            <br/>
                                            <div style={{lineHeight: 1.5}}>GUT FÜR DICH:<br/><strong>{this.props.days}</strong></div>
                                        </h1>
                                    </div>

                                    <div className={"article-inner"}>

                                        {this.renderGetreide()}
                                        {this.renderGemuese()}
                                        {this.renderObst()}
                                        {this.renderGewuerze()}
                                        {this.renderFisch()}
                                        {this.renderFleisch()}
                                        {this.renderEi()}
                                        {this.renderMilchprodukt()}
                                        {this.renderVeganeEiweissquelle()}
                                        {this.renderFett()}
                                        {this.renderGetraenk()}
                                        {this.renderAlkohol()}
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>

                                        {/*<Link to="/gut-fuer-dich" title="eom-profil" className={"cleaned-link"}>*/}
                                        {/*    <h1 className={"h1--lined--dark drawer-link--dark"}>HÜLSENFRÜCHTE</h1>*/}
                                        {/*</Link>*/}

                                        {/*<Link to="/gut-fuer-dich" title="eom-profil" className={"cleaned-link"}>*/}
                                        {/*    <h1 className={"h1--lined--dark drawer-link--dark"}>OBST</h1>*/}
                                        {/*</Link>*/}

                                        {/*<Link to="/gut-fuer-dich" title="eom-profil" className={"cleaned-link"}>*/}
                                        {/*    <h1 className={"h1--lined--dark drawer-link--dark"}>GEMÜSE</h1>*/}
                                        {/*</Link>*/}

                                        {/*<Link to="/gut-fuer-dich" title="eom-profil" className={"cleaned-link"}>*/}
                                        {/*    <h1 className={"h1--lined--dark drawer-link--dark"}>FLEISCH/FISCH</h1>*/}
                                        {/*</Link>*/}

                                        {/*<Link to="/gut-fuer-dich" title="eom-profil" className={"cleaned-link"}>*/}
                                        {/*    <h1 className={"h1--lined--dark drawer-link--dark"}>MILCHPRODUKTE</h1>*/}
                                        {/*</Link>*/}

                                        {/*<Link to="/gut-fuer-dich" title="eom-profil" className={"cleaned-link"}>*/}
                                        {/*    <h1 className={"h1--lined--dark drawer-link--dark"}>VEGANE PROTEINE</h1>*/}
                                        {/*</Link>*/}

                                        {/*<Link to="/gut-fuer-dich" title="eom-profil" className={"cleaned-link"}>*/}
                                        {/*    <h1 className={"h1--lined--dark drawer-link--dark"}>GEWÜRZE</h1>*/}
                                        {/*</Link>*/}

                                        {/*<Link to="/gut-fuer-dich" title="eom-profil" className={"cleaned-link"}>*/}
                                        {/*    <h1 className={"h1--lined--dark drawer-link--dark"}>FETTE</h1>*/}
                                        {/*</Link>*/}

                                        {/*<Link to="/gut-fuer-dich" title="eom-profil" className={"cleaned-link"}>*/}
                                        {/*    <h1 className={"h1--lined--dark drawer-link--dark"}>PROTEINE</h1>*/}
                                        {/*</Link>*/}
                                    </div>
                                </div>
                                }
                            </>



                            :
                            <>
                                {/*
                            * OFFLINE
                            * if false that phase is online
                            */}
                                <br/>
                                <br/>
                                <div className={"article-inner"}>
                                    <p style={{textAlign: 'center'}}>Dieser Inhalt steht derzeit nicht zur Verfügung.</p>
                                    <br/>
                                    <p>Mögliche Gründe:</p>
                                    <ul >
                                        <li>Sie haben noch keine BELLYDAYS Analyse gemacht.</li>
                                        <li>Sie haben möglicherweise keine Internetverbindung.</li>
                                    </ul>
                                </div>
                            </>
                        }
                    </article>
                </main>

                <EomSnackbar/>
            </div>
        );
    }
}

export default Phase;
