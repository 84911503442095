import React from 'react';
import AppBarComp from "./../components/global/AppBarComp";
import Video1 from "../assets/videos/bellydays1.m4v";
import Video2 from "../assets/videos/bellydays2.m4v";
import Video3 from "../assets/videos/bellydays3.m4v";
import Image from "../assets/images/grundausstattung.jpeg";
import EomSnackbar from "../components/global/EomSnackbar";

function DeinTagesablauf(props) {
    return (
        <>
            <div className={"app-wrapper"}>
                <div id={"select"}></div>
                <main className={"main"}>
                    <AppBarComp appUserLoggedIn={props.appUserLoggedIn}
                                handleLogout={props.handleLogout}/>
                    <article className={"article article--with-stripe fade"}>
                        <div className={"article-inner staticcontent"}>
                            <h1 className={"h1--lined--dark"}>Dein
                                Tagesablauf</h1>
                            <br/><br/>
                            <h2 style={{
                                marginLeft: 0,
                                marginBottom: 0,
                                textAlign: 'left'
                            }}>Allgemeines über bellydays</h2>
                            <div className={"aut-video-container"}>
                                <div className="container">
                                    <div className='Flexible-container'>
                                        <video width="500" height="322"
                                               controls>
                                            <source src={Video1}/>
                                        </video>
                                    </div>
                                </div>
                            </div>

                            <br/><br/>
                            <h2 style={{
                                marginLeft: 0,
                                marginBottom: 0,
                                textAlign: 'left'
                            }}>Essen und Trinken</h2>
                            <div className={"aut-video-container"}>
                                <div className="container">
                                    <div className='Flexible-container'>
                                        <video width="500" height="322"
                                               controls>
                                            <source src={Video2}/>
                                        </video>
                                    </div>
                                </div>
                            </div>

                            <br/><br/>
                            <h2 style={{
                                marginLeft: 0,
                                marginBottom: 0,
                                textAlign: 'left'
                            }}>Verdauung und Entgiftung</h2>
                            <div className={"aut-video-container"}>
                                <div className="container">
                                    <div className='Flexible-container'>
                                        <video width="500" height="322"
                                               controls>
                                            <source src={Video3}/>
                                        </video>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <br/>


                            <p>Vor dem Start der bellydays-Kur sind einige
                                Vorbereitungen zu treffen.</p>
                            <br/>
                            <img src={Image} alt={'grundausstattung'}
                                 style={{maxWidth: '100%', width: '100%'}}/>
                            <h3 style={{textAlign: 'left'}}>Empfohlene
                                Grundausstattung:</h3>
                            <ul>
                                <li>Bräter</li>
                                <li>Thermobehälter</li>
                                <li>Körperwaage</li>
                                <li>Körper-Maßband</li>
                                <li>Körperbürste</li>
                                <li>Blaulichtfilter-Brille</li>
                            </ul>
                            <h3 style={{textAlign: 'left'}}>Deine Vorbereitung
                                für bellydays:</h3>
                            <ul>
                                <li>Kurbrot hefefrei, 2 Tage alt</li>
                                <li>Zutaten für die Basensuppe und die Speisen
                                    der ersten Tage (siehe Rezepte)
                                </li>
                                <li>Bittersalz und Nahrungsergänzungsmittel
                                    (siehe Liste)
                                </li>
                            </ul>
                            <h3 style={{textAlign: 'left'}}>Anleitung:</h3>
                            <ul>
                                <li>Wähle den richtigen Zeitpunkt für deine
                                    Darmerholungskur. Beachte, dass die ersten
                                    10 Tage eine starke Umgewöhnung und sowohl
                                    eine geistige als auch körperliche
                                    Herausforderung darstellen.
                                </li>
                                <li>Finde deinen individuellen Tagesrhythmus im
                                    Einklang mit deinen Arbeitszeiten.
                                    Beachte dabei die Einhaltung deiner
                                    Mahlzeiten und der Rituale, die du während
                                    der
                                    bellydays anwenden solltest.
                                </li>
                                <li>Plane den Einkauf und die Zubereitung deiner
                                    Speisen. Das Brot sollte mindestens 2
                                    Tage alt sein und bereite dir regelmäßig die
                                    Basensuppe vor.
                                </li>
                                <li>Nimm dir ausreichend Zeit für deine
                                    Mahlzeiten und finde ein ruhiges Ambiente.
                                    Du
                                    solltest jeden Bissen 30-40 x Kauen. Während
                                    des Essens nicht Trinken!
                                </li>
                                <li>Achte auf die richtige Flüssigkeitszufuhr.
                                    Pro 10 kg Körpergewicht benötigst du einen
                                    halben Liter Wasser. Wenn du 60 kg hast,
                                    solltest du 3 Liter am Tag trinken. Bei 80 kg und mehr sind es 4 Liter, 2/3 der Tagesmenge sollten bis Mittag bereits getrunken sein.
                                </li>
                                <li>Besondere Aufmerksamkeit solltest du deiner
                                    Verdauung schenken. Bittersalz und
                                    Magnesium wirken abführend und kannst du bei
                                    der Einnahme an deine
                                    Stuhlverhältnisse anpassen.
                                </li>
                                <li>Regelmäßige körperliche Bewegung
                                    (Spaziergänge, Wanderungen, Schwimmen, etc.)
                                    unterstützen deine Darmerholungskur positiv.
                                </li>
                                <li>Kontrolliere täglich dein Gewicht und deinen
                                    Bauchumfang!
                                </li>
                            </ul>

                            <br/>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                    </article>
                </main>
                <EomSnackbar/>
            </div>

            </>
    );
}

export default DeinTagesablauf;
