import React from 'react';
import { withStyles } from '@material-ui/styles';

import MobileStepper from "@material-ui/core/MobileStepper";

import theme from "../../theme/theme";

import NextIcon from "../../assets/svg/next";
import SvgIcon from '@material-ui/core/SvgIcon';
import BackIcon from "../../assets/svg/back";

import EomNewWheelAge from './EomNewWheels/EomNewWheelAge';
import EomNewWheelHeight from './EomNewWheels/EomNewWheelHeight';
import EomNewWheelWeight from './EomNewWheels/EomNewWheelWeight';
import EomNewWheelTemperature from './EomNewWheels/EomNewWheelTemperature';

import EomNewWheelCold from './EomNewWheels/EomNewWheelCold';

import EomNewOptions from "./EomNewOptions";
import EomNewOptionsSport from "./EomNewOptionsSport";
import EomNewOptionsCold from "./EomNewOptionsCold";
import EomNewOptionsSchwitzen from "./EomNewOptionsSchwitzen";
import Loader from "../global/Loader";

import EomNewOptionsGewichtsabnahme from "./EomNewOptionsGewichtsabnahme";
import EomNewOptionsErnaehrung from "./EomNewOptionsErnaehrung";
import EomNewOptionsVerzicht from "./EomNewOptionsVerzicht";
import EomNewOptionsAusschluss from "./EomNewOptionsAusschluss";
import EomNewOptionsAllergie from "./EomNewOptionsAllergie";
import EomNewOptionsJokerX from "./EomNewOptionsJokerX";
import EomNewOptionsJokerY from "./EomNewOptionsJokerY";

import Profil from "../../screens/Profil";

import uuid from 'uuid/v4';

let gender = "";
let age = 0;
let height = 0;
let weight = 0;
let bmi = 0;
let bmi_evaluation = "";
let temperature = 0;
let cold = "";
let coldsense = 0;
let sport = "";
let schwitzen = "";
let gewichtsabnahme = "";
let ernaehrung = "";
let verzicht ="";
let ausschluss ="";
let allergie ="";
let jokerx = "";
let jokery = "";

let cold_text = "";
let coldsense_text = "";
let verzicht_text = "";
let ausschluss_text = "";
let allergie_text = "";

let kaelte = 0;
let hitze = 0;
let speicher = 0;
let verbraucher = 0;

let eom_type="";
let eom_uuid = uuid();
// let startDate;


function eomFunc(){
    /*
      GET ITEMS FROM LOCAL STORAGE
    */
    (function getEomAnswers() {
        gender = localStorage.getItem("gender");
        age = parseInt(localStorage.getItem("age"));
        height = parseInt(localStorage.getItem("height"));
        weight = parseInt(localStorage.getItem("weight"));
        temperature = parseInt(localStorage.getItem("temperature"));
        cold = localStorage.getItem("cold");
        coldsense = parseInt(localStorage.getItem("coldsense"));
        sport = localStorage.getItem("sport");
        schwitzen = localStorage.getItem("schwitzen");
        gewichtsabnahme = localStorage.getItem("gewichtsabnahme");
        ernaehrung = localStorage.getItem("ernaehrung");
        verzicht = JSON.parse(localStorage.getItem("verzicht"));
        ausschluss = JSON.parse(localStorage.getItem("ausschluss"));
        allergie = JSON.parse(localStorage.getItem("allergie"));
        jokerx = localStorage.getItem("jokerx");
        jokery = localStorage.getItem("jokery");
    }());

    /*
      CALC EOM-VALUES
    */
    (function calcEomValues() {
        //Start Date
        // startDate = new Date().getTime();

        //BMI
        bmi = Math.round(weight / (Math.pow((height / 100),2)) * 10) / 10;
        if (age <= 18 && bmi >= 17 && bmi <= 28) {
            bmi_evaluation = "Normalwert"
        } else if (age > 18 && bmi >= 17 && bmi <= 38) {
            bmi_evaluation = "Normalwert"
        } else {
            bmi_evaluation = "Ärztliche Empfehlung notwendig"
        }

        if (gender === "weiblich") {
            if (bmi <= 17) {
                verbraucher += 3;
            }
            else if (bmi > 17 && bmi <=19) {
                verbraucher += 2;
            }
            else if (bmi > 19 && bmi <=21) {
                verbraucher += 1;
            }
            else if (bmi > 23 && bmi <=25) {
                speicher += 1;
            }
            else if (bmi > 25 && bmi <=27) {
                speicher += 2;
            }
            else if (bmi > 27 && bmi <=33) {
                speicher += 3;
            }
            else if (bmi > 33) {
                speicher += 4;
            }
        } else if (gender === "männlich") {
            if (bmi <= 17) {
                verbraucher += 3;
            }
            else if (bmi > 17 && bmi <=19) {
                verbraucher += 2;
            }
            else if (bmi > 19 && bmi <=21) {
                verbraucher += 1;
            }
            else if (bmi > 24 && bmi <=27) {
                speicher += 1;
            }
            else if (bmi > 27 && bmi <=30) {
                speicher += 2;
            }
            else if (bmi > 30 && bmi <=35) {
                speicher += 3;
            }
            else if (bmi > 35) {
                speicher += 4;
            }
        }

        //TEMPERATURE
        if (temperature > 36) {
            hitze += 3;
        }
        if (temperature >= 30 && temperature <= 35) {
            hitze += 2;
        }
        if (temperature >= 25 && temperature <= 29) {
            hitze += 1;
        }
        if (temperature >= 12 && temperature <= 17) {
            kaelte += 1;
        }
        if (temperature >= 5 && temperature <= 11) {
            kaelte += 2;
        }
        if (temperature <= 4) {
            kaelte += 3;
        }

        //COLDSENSE
        if (coldsense === 10) {
            hitze += 5;
            coldsense_text = "plus 5";
        }
        if (coldsense === 9) {
            hitze += 4;
            coldsense_text = "plus 4";
        }
        if (coldsense === 8) {
            hitze += 3;
            coldsense_text = "plus 3";
        }
        if (coldsense === 7) {
            hitze += 2;
            coldsense_text = "plus 2";
        }
        if (coldsense === 6) {
            hitze += 1;
            coldsense_text = "plus 1";
        }
        if (coldsense === 5) {
            hitze += 1;
            coldsense_text = "plus 1";
        }
        if (coldsense === 4) {
            kaelte += 1;
            coldsense_text = " minus 1";
        }
        if (coldsense === 3) {
            kaelte += 2;
            coldsense_text = "minus 2";
        }
        if (coldsense === 2) {
            kaelte += 3;
            coldsense_text = "minus 3";
        }
        if (coldsense === 1) {
            kaelte += 4;
            coldsense_text = "minus 4";
        }
        if (coldsense === 0) {
            kaelte += 5;
            coldsense_text = "minus 5";
        }

        //COLD
        if (cold === "Nahezu immer") {
            cold_text = "Nahezu immer";
        }
        if (cold === "Oft") {
            cold_text = "Oft";
        }
        if (cold === "Selten") {
            cold_text = "Selten";
        }
        if (cold === "Nie") {
            cold_text = "Nie";
        }

        // hände - füße
        if (coldsense <= 4) {
            if (cold === "Nahezu immer") {
                kaelte += 4;
                verbraucher += 1;
            }
            if (cold === "Oft") {
                kaelte += 3;
                verbraucher += 1;
            }
            if (cold === "Selten") {
                kaelte += 1;
                verbraucher += 1;
            }
        }

        //SPORT
        if (sport === "Ja, regelmäßig 3x pro Woche oder öfter") {
            verbraucher += 2;
        }
        else if (sport === "Ja, regelmäßig 1-2x pro Woche") {
            verbraucher += 1;
        }
        else if (sport === "Selten bis nie") {
            speicher += 1;
        }
        else if (sport === "Ich würde gerne, habe aber keinen Antrieb dafür") {
            speicher += 2;
        }

        //SCHWITZEN
        if (schwitzen === "Nahezu immer") {
            hitze += 2
        }
        else if (schwitzen === "Oft") {
            hitze += 1
        }

        //GEWICHTSABNAHME
        if (gewichtsabnahme === "Ich nehme leicht zu und schwer ab") {
            if (bmi < 24) {
                speicher += 1
            }
            if (bmi >= 24) {
                speicher += 2
            }
        }
        else if (gewichtsabnahme === "Ich nehme leicht ab und schwer zu") {
            if (bmi < 21) {
                verbraucher += 2
            }
            if (bmi >= 21) {
                verbraucher += 1
            }
        }

        //ERNÄHRUNG
        if (ernaehrung === "Ich esse gerne, aber Hauptsache es schmeckt mir, meine Gesundheit ist dabei nebensächlich") {
            speicher += 1
        }
        else if (ernaehrung === "Ich ernähre mich nur, weil ich sonst verhungere") {
            verbraucher += 1
        }

        //VERZICHT
        if (verzicht[0] === true) {
            speicher += 1;
            verzicht_text += "Brot, ";
        }
        if (verzicht[1] === true) {
            speicher += 1;
            verzicht_text += "Süßigkeiten, ";
        }
        if (verzicht[2] === true) {
            speicher += 1;
            verzicht_text += "Fleisch, ";
        }
        if (verzicht[3] === true) {
            hitze += 1;
            verzicht_text += "Chilli und andere scharfe Gewürze, ";
        }
        if (verzicht[4] === true) {
            hitze += 1;
            verbraucher += 1;
            verzicht_text += "Kaffee, ";
        }
        if (verzicht[5] === true) {
            verzicht_text += "Alkohol";
        }
        if (verzicht[0] === false && verzicht[1] === false && verzicht[2] === false && verzicht[3] === false && verzicht[4] === false && verzicht[5] === false) {
            verzicht_text += "keine"
        }

        //AUSSCHLUSS
        if (ausschluss[0] === true) {
            verbraucher += 1;
            ausschluss_text += "Fleisch, ";
        }
        if (ausschluss[1] === true) {
            ausschluss_text += "Fisch, ";
        }
        if (ausschluss[2] === true) {
            verbraucher += 1;
            ausschluss_text += "Milch und Milchprodukte, ";
        }
        if (ausschluss[3] === true) {
            ausschluss_text += "Eier, ";
        }
        if (ausschluss[4] === true) {
            ausschluss_text += "ich esse alles";
        }
        if (ausschluss[0] === false && ausschluss[1] === false && ausschluss[2] === false && ausschluss[3] === false && ausschluss[4] === false) {
            ausschluss_text += "keine"
        }

        //ALLERGIE
        if (allergie[0] === true) {
            allergie_text += "Laktose, ";
        }
        if (allergie[1] === true) {
            allergie_text += "Fruktose, ";
        }
        if (allergie[2] === true) {
            allergie_text += "Gluten, ";
        }
        if (allergie[3] === true) {
            allergie_text += "Histamin, ";
        }
        if (allergie[4] === true) {
            allergie_text += "andere, bitte besprich das mit deiner Ernährungsberaterin";
        }
        if (allergie[4] === true) {
            allergie_text += "andere, bitte besprich das mit deiner Ernährungsberaterin";
        }
        if (allergie[0] === false && allergie[1] === false && allergie[2] === false && allergie[3] === false && allergie[4] === false) {
            allergie_text += "keine"
        }

        //JOKER X
        if (speicher === verbraucher) {
            if (jokerx === "Mich bringt nichts so schnell aus der Ruhe.") {
                verbraucher -= 1
            }
            else if (jokerx === "Ich lasse mich leicht stressen, werde schnell nervös.") {
                speicher -= 1
            }

        }

        //JOKER Y
        if (hitze === kaelte) {
            if (jokery === "Ich kann schon mal in die Luft gehen, wenn ich mich ärgere.") {
                kaelte -= 1
            }
            else if (jokery === "Ich bin vorsichtig und zurückhaltend, fast schon ängstlich.") {
                hitze -= 1
            }
        }
    }());


}


const styles = {
    article: {
        margin: "0 auto",
        minHeight: "calc(100vh - 67px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "20px 30px",
        paddingBottom: 0,
    },
    articleWrapper: {
        flex: 1,
        display: "flex",
        flexDirection: "column"
    },
    content: {
        flex: "9.5",
        display: "flex",

        justifyContent: "center",
        flexDirection: "column",

    },
    article2: {
        margin: "0 auto",
        minHeight: "calc(100vh - 84px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "20px 30px",
        [theme.breakpoints.down('xs')]: {
            padding: "20px",
        },
    },
    textUnderlined: {
        margin: "10px 0 10px 0",
        lineHeight: "1.2",
        fontFamily: "Stag_Sans-Light",
        fontWeight: "normal",
        borderBottom: "1px solid",
        fontSize: "30px",
        [theme.breakpoints.down('xs')]: {
            fontSize: "22px",
        },
    },
    stepperIcon: {
        fontSize: "20px",
        [theme.breakpoints.down('xs')]: {
            fontSize: "12px",
        },
    },
    buttonWrapper: {
        width: "50%"
    }
};

class EomNew extends React.Component {
    constructor() {

        super();
        this.state = {
            input: {
                title: "EOM Check",
                gender: '',
                age: '',
                weight: ''
            },
            open: false,
            activeStep: 0,
            maxSteps: 29,
            errorMessage: ' ',
            loading: false,
            loaderText: "",
            fade: "",
            eom_done: localStorage.getItem('eom-done'),
        };
        this.postNode = this.postNode.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    postNode() {
        (function saveEomTypeToLocalStorage () {
            //localStorage.setItem("eom-type", eom_type);
            localStorage.setItem("eom-uuid", eom_uuid);
            localStorage.setItem("eom-done", 'true');
            // localStorage.setItem("startDate", startDate);
        }());

        let data = {
            "data": {
                "type": "tcm_evaluierung",
                "attributes": {
                    "title": "EOM Check",
                    "field_geschlecht": gender,
                    "field_alter": age,
                    "field_gewicht": weight,
                    "field_koerpergroesse": height,
                    "field_bmi": bmi,
                    "field_bmi_bewertung": bmi_evaluation,
                    "field_umgebungstemperatur": temperature,
                    "field_kalte_haende_fuesse": cold_text,
                    "field_kaelteempfinden": coldsense_text,
                    "field_sport": sport,
                    "field_leicht_schwitzen": schwitzen,
                    "field_gewichtsabnahme": gewichtsabnahme,
                    "field_ernaehrung": ernaehrung,
                    "field_verzicht": verzicht_text,
                    "field_ausschluss": ausschluss_text,
                    "field_allergie": allergie_text,
                    "field_jokerx": jokerx,
                    "field_jokery": jokery,
                    "field_kaelte": kaelte,
                    "field_hitze": hitze,
                    "field_speicher": speicher,
                    "field_verbraucher": verbraucher,
                    "field_eom_typ": eom_type,
                    "field_uuid": eom_uuid,
                },
            }
        };

        this.props.postNode(data);
    }

    handleChange(event) {
        let newState = Object.assign({}, this.state);
        newState.input[event.target.name] = event.target.value;
        this.setState(newState /*, () => console.log(this.state)*/);
        event.preventDefault();
    }

    handleSubmit(event) {
        // this.postNode();
        event.preventDefault();
    }

    handleNext = () => {
        // window.scrollTo(0, 0);
        window.scrollTo(0,document.body.scrollHeight);
        window.addEventListener("load",function() {
            setTimeout(function(){
                // This hides the address bar:
                window.scrollTo(0, 1);
            }, 0);
        });

        //the first number, in this case 4 ... are the max steps
        if (this.state.activeStep === this.state.maxSteps - 1) {
            // this.showAppBar();
            this.postNode();
            this.setState(prevState => ({
                activeStep: this.state.maxSteps,
            }));
            this.handleLoading();
        } else {
            if (this.state.activeStep === 1 && this.state.input.title === '') {
                this.setState(prevState => ({
                    errorMessage: "Bitte geben Sie einen Titel an."
                }));
            } else {
                this.setState(prevState => ({
                    activeStep: prevState.activeStep + 1,
                    errorMessage: ' '
                }));
            }
        }

// eomFunc();
//         console.log('hitze' + hitze);
//         console.log('kaelte' + kaelte);
//         console.log('verbraucher' + verbraucher);
//         console.log('speicher' + speicher);

    };

    handleBack = () => {
        // window.scrollTo(0, 0);
        window.scrollTo(0,document.body.scrollHeight);
        // this.showAppBar();
        this.setState(prevState => ({
            activeStep: prevState.activeStep - 1,
            errorMessage: ' '
        }));
    };

    handleLoading=()=> {
        this.setState({
            loading: true,
            loaderText: "Bitte um etwas Geduld, ich errechne deinen EOM–Index.",
            fade: "fade",
        });

        //set loading status back to false after longer delay
        setTimeout(() => {
            this.setState({
                loading: false,
            });


        }, 2500);
    };


    hideAppBar =()=> {
        document.getElementsByTagName("HEADER")[0].style.display = "none";
    };

    componentWillMount() {
        const eomType = localStorage.getItem("eom-type");
        this.setState({
            eomType: eomType,
        });
    }


    render() {
        const { activeStep } = this.state;
        const tutorialSteps = [];

        tutorialSteps.push(
            <EomNewOptions
                localStorageItem={"gender"}
                defaultValue={""}
                ariaLabel={"field_geschlecht"}
                values={["weiblich", "männlich"]}
                labels={["Weiblich", "Männlich"]}
            />,

            <EomNewWheelAge
                range={85}
                startingAt={15}
                startingPoint={45}
                question={"Alter"}
                localStorageItem={"age"}
            />,

            <EomNewWheelHeight
                range={210}
                startingAt={140}
                startingPoint={170}
                question={"Körpergröße (cm)"}
                localStorageItem={"height"}
            />,

            <EomNewWheelWeight
                range={140}
                startingAt={40}
                startingPoint={65}
                question={"Gewicht (kg)"}
                localStorageItem={"weight"}
            />,

            <div className={"article-wrapper-stepper_select"} style={{background: "transparent", boxShadow: "none"}}>
                <div className={"fade"} >
                    <p style={{textAlign: "center", fontWeight: "bold"}}>
                        Deine momentane Umgebungstemperatur?
                    </p>
                </div>
            </div>,

            <EomNewWheelTemperature
                range={36}
                startingAt={-10}
                startingPoint={21}
                question={"Grad (Celsius)"}
                localStorageItem={"temperature"}
            />,

            <div className={"article-wrapper-stepper_select"} style={{background: "transparent", boxShadow: "none"}}>
                <div className={"fade"} >
                    <p style={{textAlign: "center", fontWeight: "bold"}}>
                        Hast du kalte Hände oder Füße?
                    </p>
                </div>
            </div>,

            <EomNewOptionsCold
                localStorageItem={"cold"}
                defaultValue={""}
                ariaLabel={"field_cold"}
                values={["Nahezu immer", "Oft", "Selten", "Nie"]}
                labels={["Nahezu immer", "Oft", "Selten", "Nie"]}
            />,

            <div className={"fade"} style={{background: "transparent", boxShadow: "none"}}>
                <div className={"select-wrapper"} style={{background: "transparent", boxShadow: "none"}}>
                    <p style={{textAlign: "center", fontWeight: "bold"}}>
                        Wie ist dein generelles Temperaturempfinden?
                    </p>
                </div>
            </div>,

            <EomNewWheelCold
                range={11}
                question={"Hast du kalte Hände oder Füße?"}
                max={"Mir ist meistens zu warm"}
                min={"Ich friere schnell"}
                localStorageItem={"coldsense"}
            />,

            <div className={"article-wrapper-stepper_select"} style={{background: "transparent", boxShadow: "none"}}>
                <div className={"fade"} >
                    <p style={{textAlign: "center", fontWeight: "bold"}}>
                        Betreibst du Sport?
                    </p>
                </div>
            </div>,

            <EomNewOptionsSport
                localStorageItem={"sport"}
                defaultValue={""}
                ariaLabel={"field_sport"}
                values={["Ja, regelmäßig 3x pro Woche oder öfter", "Ja, regelmäßig 1-2x pro Woche", "Selten bis nie", "Ich würde gerne, habe aber keinen Antrieb dafür"]}
                labels={["Ja, regelmäßig 3x pro Woche oder öfter", "Ja, regelmäßig 1-2x pro Woche", "Selten bis nie", "Ich würde gerne, habe aber keinen Antrieb dafür"]}
            />,

            <div className={"article-wrapper-stepper_select"} style={{background: "transparent", boxShadow: "none"}}>
                <div className={"fade"} >
                    <p style={{textAlign: "center", fontWeight: "bold"}}>
                        Beginnst du zu Schwitzen,
                        sobald du körperlich oder geistig
                        unter Stress stehst?
                    </p>
                </div>
            </div>,

            <EomNewOptionsSchwitzen
                localStorageItem={"schwitzen"}
                defaultValue={""}
                ariaLabel={"field_leicht_schwitzen"}
                values={["Nahezu immer", "Oft", "Selten", "Nie"]}
                labels={["Nahezu immer", "Oft", "Selten", "Nie"]}
            />,

            <div className={"article-wrapper-stepper_select"} style={{background: "transparent", boxShadow: "none"}}>
                <div className={"fade"} >
                    <p style={{textAlign: "center", fontWeight: "bold"}}>
                        Nimmst du leicht zu oder ab?
                    </p>
                </div>
            </div>,

            <EomNewOptionsGewichtsabnahme
                localStorageItem={"gewichtsabnahme"}
                defaultValue={""}
                ariaLabel={"field_gewichtsabnahme"}
                values={["Ich nehme leicht zu und schwer ab", "Ich nehme leicht ab und schwer zu", "Ich halte mein Gewicht recht konstant"]}
                labels={["Ich nehme leicht zu und schwer ab", "Ich nehme leicht ab und schwer zu", "Ich halte mein Gewicht recht konstant"]}
            />,

            <div className={"article-wrapper-stepper_select"} style={{background: "transparent", boxShadow: "none"}}>
                <div className={"fade"} >
                    <p style={{textAlign: "center", fontWeight: "bold"}}>
                        Wie wichtig ist dir Ernährung?
                    </p>
                </div>
            </div>,

            <EomNewOptionsErnaehrung
                localStorageItem={"ernaehrung"}
                defaultValue={""}
                ariaLabel={"field_ernaehrung"}
                values={["Essen ist für mich sehr wichtig und ich lege großen Wert auf Genuss und gute Qualität", "Ich ernähre mich bewusst, weil ich meine Gesundheit fördern möchte", "Ich esse gerne, aber Hauptsache es schmeckt mir, meine Gesundheit ist dabei nebensächlich", "Ich ernähre mich nur, weil ich sonst verhungere"]}
                labels={["Essen ist für mich sehr wichtig und ich lege großen Wert auf Genuss und gute Qualität", "Ich ernähre mich bewusst, weil ich meine Gesundheit fördern möchte", "Ich esse gerne, aber Hauptsache es schmeckt mir, meine Gesundheit ist dabei nebensächlich", "Ich ernähre mich nur, weil ich sonst verhungere"]}
            />,

            <div className={"article-wrapper-stepper_select"} style={{background: "transparent", boxShadow: "none"}}>
                <div className={"fade"} >
                    <p style={{textAlign: "center", fontWeight: "bold"}}>
                        Worauf kannst du schwer verzichten?<br/>
                        (mehrere Antworten möglich)
                    </p>
                </div>
            </div>,

            <EomNewOptionsVerzicht
                localStorageItem={"verzicht"}
                defaultValue={""}
                ariaLabel={"field_verzicht"}
                values={["Brot", "Süßigkeiten", "Fleisch", "Chilli und andere scharfe Gewürze", "Kaffee", "Alkohol"]}
                labels={["Brot", "Süßigkeiten", "Fleisch", "Chilli und andere scharfe Gewürze", "Kaffee", "Alkohol"]}
            />,

            <div className={"article-wrapper-stepper_select"} style={{background: "transparent", boxShadow: "none"}}>
                <div className={"fade"} >
                    <p style={{textAlign: "center", fontWeight: "bold"}}>
                        Gibt es Lebensmittel, die du grundsätzlich nicht isst?<br/>
                        (mehrere Antworten möglich)
                    </p>
                </div>
            </div>,

            <EomNewOptionsAusschluss
                localStorageItem={"ausschluss"}
                defaultValue={""}
                ariaLabel={"field_ausschluss"}
                values={["Fleisch und Fleischprodukte", "Fisch", "Milch und Milchprodukte", "Eier", "Ich esse alle Arten von Lebensmittel"]}
                labels={["Fleisch und Fleischprodukte", "Fisch", "Milch und Milchprodukte", "Eier", "Ich esse alle Arten von Lebensmittel"]}
            />,

            <div className={"article-wrapper-stepper_select"} style={{background: "transparent", boxShadow: "none"}}>
                <div className={"fade"} >
                    <p style={{textAlign: "center", fontWeight: "bold"}}>
                        Hast du Nahrungsmittelunverträglichkeiten oder -allergien?<br/>
                        (mehrere Antworten möglich)
                    </p>
                </div>
            </div>,

            <EomNewOptionsAllergie
                localStorageItem={"allergie"}
                defaultValue={""}
                ariaLabel={"field_allergie"}
                values={["Latkose", "Fruktose", "Gluten", "Histamin", "Andere"]}
                labels={["Latkose", "Fruktose", "Gluten", "Histamin", "Andere"]}
            />,

            <div className={"article-wrapper-stepper_select"} style={{background: "transparent", boxShadow: "none"}}>
                <div className={"fade"} >
                    <p style={{textAlign: "center", fontWeight: "bold"}}>
                        Welche Aussage trifft bei dir eher zu?
                    </p>
                </div>
            </div>,

            <EomNewOptionsJokerX
                localStorageItem={"jokerx"}
                defaultValue={""}
                ariaLabel={"field_jokerx"}
                values={["Mich bringt nichts so schnell aus der Ruhe.", "Ich lasse mich leicht stressen, werde schnell nervös."]}
                labels={["Mich bringt nichts so schnell aus der Ruhe.", "Ich lasse mich leicht stressen, werde schnell nervös."]}
            />,

            <div className={"fade"}>
                <div className={"select-wrapper"} style={{background: "transparent", boxShadow: "none"}}>
                    <p style={{textAlign: "center", fontWeight: "bold", marginTop: "76px"}}>
                        Welche Aussage trifft bei dir eher zu?
                    </p>
                </div>
            </div>,

            <EomNewOptionsJokerY
                localStorageItem={"jokery"}
                defaultValue={""}
                ariaLabel={"field_jokery"}
                values={["Ich kann schon mal in die Luft gehen, wenn ich mich ärgere.", "Ich bin vorsichtig und zurückhaltend, fast schon ängstlich."]}
                labels={["Ich kann schon mal in die Luft gehen, wenn ich mich ärgere.", "Ich bin vorsichtig und zurückhaltend, fast schon ängstlich."]}
            />,

            <div>
                <div className={"fade"}>
                    <div className={"select-wrapper"} style={{background: "transparent", boxShadow: "none"}}>
                        <p style={{textAlign: "center", fontWeight: "bold", marginTop: "76px"}}>
                            Klicke weiter um deine Analyse abzuschließen
                        </p>
                    </div>
                </div>
                {this.state.activeStep === this.state.maxSteps - 1 &&
                    <div>
                        {eomFunc()}
                    </div>
                }
                <div/>
            </div>,
        );
        const maxSteps = this.state.maxSteps;

        return (
            <div>
            {(this.state.eom_done === null || this.state.eom_done === undefined || this.state.eom_done === "initial state") &&
                <div>

                {this.state.activeStep !== maxSteps &&

                <div className={"app-wrapper"} id={"app-wrapper"}>
                    <div className={"app"}>
                        <main className={"main"}>
{/*                            <AppBarComp
                                appUserLoggedIn={this.props.appUserLoggedIn}
                                handleLogout={this.props.handleLogout}/>*/}

                            <article className={"article-wrapper-stepper"}>

                                {tutorialSteps[activeStep]}

                                <MobileStepper
                                    className={"article-wrapper-stepper_navi"}
                                    steps={maxSteps}
                                    variant="progress"
                                    position="static"
                                    activeStep={activeStep}
                                    style={styles.mobileStepper}
                                    nextButton={
                                        <div className={this.props.classes.buttonWrapper}>

                                            {(activeStep < tutorialSteps.length) &&
                                            <button className={"prev-next-button next"}
                                                style={{
                                                    padding: "20px",
                                                    background: "transparent",
                                                    border: "none",
                                                    outline: "none",
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                    alignItems: "flex-start",
                                                    flex: 1
                                                }}
                                                onClick={this.handleNext}>
                                                <SvgIcon viewBox="0 0 16.8 22.512"
                                                         className={this.props.classes.stepperIcon}>
                                                    <NextIcon/>
                                                </SvgIcon>
                                            </button>
                                            }

                                            {activeStep > tutorialSteps.length - 1 &&
                                            <button className={"prev-next-button"}
                                                style={{
                                                    padding: "20px",
                                                    background: "transparent",
                                                    border: "none",
                                                    outline: "none",
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                    alignItems: "flex-start",
                                                    flex: 1
                                                }}
                                                disabled/>
                                            }
                                        </div>
                                    }
                                    backButton={
                                        <div className={this.props.classes.buttonWrapper}>
                                            <button className={"prev-next-button back"}
                                                style={{
                                                    padding: "20px",
                                                    background: "transparent",
                                                    border: "none",
                                                    outline: "none",
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    alignItems: "flex-end",
                                                    flex: 1
                                                }}
                                                onClick={this.handleBack}
                                                disabled={activeStep === 0}>
                                                {activeStep > 0 &&
                                                <SvgIcon viewBox="0 0 16.8 22.512"
                                                         className={this.props.classes.stepperIcon}>
                                                    <BackIcon/>
                                                </SvgIcon>
                                                }
                                            </button>
                                        </div>
                                    }
                                />
                            </article>
                        </main>
                    </div>
                </div>

                }

                {this.state.activeStep === maxSteps &&
                <div>
                    {this.state.loading === true &&
                    <div className={"app-wrapper"} id={"app-wrapper"}>
                        <div className={"app"}>
                            <main className={"main"}>
                                {/*                            <AppBarComp
                                appUserLoggedIn={this.props.appUserLoggedIn}
                                handleLogout={this.props.handleLogout}/>*/}

                                <article className={"article-wrapper-stepper"}>
                                    <Loader
                                        fade={this.state.fade}
                                        loaderText={this.state.loaderText}
                                    />
                                </article>
                            </main>
                        </div>
                    </div>
                    }

                    {this.state.loading === false &&
                    <Profil
                        appUserLoggedIn={this.props.appUserLoggedIn}
                        handleLogout={this.props.handleLogout}
                    />
                    }
                </div>
                }

                </div>
            }

                {(this.state.eom_done !== null && this.state.eom_done !== undefined && this.state.eom_done !== "initial state") &&
                <Profil
                    appUserLoggedIn={this.props.appUserLoggedIn}
                    handleLogout={this.props.handleLogout}/>
                }
            </div>
        );

    }
}

export default withStyles(styles) (EomNew);
