import React from 'react';
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';

import Divider from "@material-ui/core/Divider";
import {withStyles} from "@material-ui/styles";


const styles = theme => ({
    selectWrapperInner: {
        padding: "20px",
        height: "100%"
    },

    backgroundLight:  {
        backgroundColor: "#ffffff"
    },

    formControlLabel: {
        display: "flex",
        flexDirection: "column-reverse",
        paddingTop: "20px",
        paddingBottom: "20px"
    },
});


class EomNewOptionsAusschluss extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fleisch: false,
            fisch: false,
            milch: false,
            eier: false,
            essealles: false,

            ausschluss: []
        };
    }

    hideAppBar =()=> {
        document.getElementsByTagName("HEADER")[0].style.display = "none";
        document.getElementsByTagName("ARTICLE")[0].style.paddingTop = "50px";
    };

    componentWillMount() {
        let ausschluss = JSON.parse(localStorage.getItem("ausschluss"));
        if(ausschluss !== null) {
            this.setState({
                fleisch: ausschluss[0],
                fisch: ausschluss[1],
                milch: ausschluss[2],
                eier: ausschluss[3],
                essealles: ausschluss[4],
            });
        }
    }

    componentDidMount=()=> {
        window.scrollTo(0, 1);
        // this.hideAppBar();
    };

    handleChange=(value)=> {
        switch(value) {
            case "fleisch":
                this.setState({
                    fleisch: !this.state.fleisch
                });
                break;

            case "fisch":
                this.setState({
                    fisch: !this.state.fisch
                });
                break;

            case "milch":
                this.setState({
                    milch: !this.state.milch
                });
                break;

            case "eier":
                this.setState({
                    eier: !this.state.eier
                });
                break;

            case "essealles":
                this.setState({
                    essealles: !this.state.essealles
                });
                break;

            default:
            console.log("nothing valuable clicked")
        }
    };

    componentWillUnmount() {
        localStorage.setItem("ausschluss", JSON.stringify([
            this.state.fleisch, this.state.fisch, this.state.milch, this.state.eier, this.state.essealles
        ]))
    }

    render() {
        return (
            <div className={"article-wrapper-stepper_select"}>
                <div className={"fade"} style={{width: "100%"}}>
                    <div>
                        <div className={"checkbox-group"} >
                            <FormLabel component="legend">Fleisch</FormLabel>
                            <FormControlLabel
                                id={"select-ausschluss"}
                                value={this.state.fleisch}
                                checked={this.state.fleisch}
                                control={<Checkbox color="primary"/>}
                                label=""
                                labelPlacement="top"
                                onChange={() => this.handleChange("fleisch")}
                            />
                        </div>

                            <Divider className={"select-wrapper-divider"}/>

                        <div className={"checkbox-group"}>
                            <FormLabel component="legend">Fisch</FormLabel>
                            <FormControlLabel
                                value={this.state.fisch}
                                checked={this.state.fisch}
                                control={<Checkbox color="primary" />}
                                label=""
                                labelPlacement="top"
                                onChange={() => this.handleChange("fisch")}
                            />
                        </div>

                            <Divider className={"select-wrapper-divider"}/>

                         <div className={"checkbox-group"}>
                            <FormLabel component="legend">Milch und Milchprodukte</FormLabel>
                            <FormControlLabel
                                value={this.state.milch}
                                checked={this.state.milch}
                                control={<Checkbox color="primary" />}
                                label=""
                                labelPlacement="top"
                                onChange={() => this.handleChange("milch")}
                            />
                         </div>

                            <Divider className={"select-wrapper-divider"}/>

                          <div className={"checkbox-group"}>
                            <FormLabel component="legend">Eier</FormLabel>
                            <FormControlLabel
                                value={this.state.eier}
                                checked={this.state.eier}
                                control={<Checkbox color="primary" />}
                                label=""
                                labelPlacement="top"
                                onChange={() => this.handleChange("eier")}
                            />
                          </div>

                            <Divider className={"select-wrapper-divider"}/>

                          <div className={"checkbox-group"}>
                            <FormLabel component="legend">Ich esse alle Arten von Lebensmittel</FormLabel>
                            <FormControlLabel
                                value={this.state.essealles}
                                checked={this.state.essealles}
                                control={<Checkbox color="primary" />}
                                label=""
                                labelPlacement="top"
                                onChange={() => this.handleChange("essealles")}
                            />
                          </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default withStyles(styles) (EomNewOptionsAusschluss);
