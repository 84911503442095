import React from 'react';
import theme from "./../../theme/theme.js";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import dompurify from 'dompurify';

const sanitizer = dompurify.sanitize;

export default class Login extends React.Component {
    constructor(props) {
        super();
        this.state = {
            disabled: false,
            input: {
                username: "",
                password: "",
            },
            loading: false,
            loaderText: "",
            fade: "",
            errorMessage: "",
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        event.preventDefault();
        let newState = Object.assign({}, this.state);
        newState.input[event.target.name] = event.target.value;
        this.setState(newState, () => console.log('this.state'));
        this.setState({
            errorMessage: ""
        });
    }

    handleSubmit(event, loginFunction) {
        event.preventDefault();

        //show loader
        this.setState({
            disabled: true,
            loading: true,
            loaderText: "Bitte warten ...",
            fade: "fade",
            errorMessage: "",
        });

        loginFunction(sanitizer(this.state.input.username), sanitizer(this.state.input.password));

        if (this.props.appUserLoggedIn === false){
            this.setState({
                loading: false,
                disabled: false,
                errorMessage: "",
            });
        } else if (this.props.appUserLoggedIn === true){
            this.setState({
                loading: false,
                disabled: true,
                errorMessage: "",
            });
        }
    }

    handleClickShowPassword=()=> {
        this.setState({
            showPassword: !this.state.showPassword
        });
    };

    componentWillUnmount() {
        window.scrollTo(0, 0);
    }

    render() {
        const classes = {
            errorMessage: {
                color: theme.palette.error.main,
                textAlign: "center"
            }
        };

        return (
            <div>
                <div>
                    {(this.state.loading === false && this.props.appUserLoggedIn === false) &&
                        <div>
                    <div className={"fade-wrapper--secondtext login"}>
                        <h1 className={"h1--notlined align--left"} style={{textAlign: "center"}}>WILLKOMMEN!</h1>
                        <p style={{textAlign: "center"}}>Mit <span className={"aut-app-name"}>bellydays</span> kann ich feststellen, in welchem Element du bist und welche Lebensmittel dir gut tun, um deine Baucherholungskur optimal zu unterstützen.</p>
                        <div className={"image-wrapper jamie"} style={{background: `url("knauer.jpg"`, maxHeight: "200px"}}/>

                        <div className={"article-inner frontpage-name--notdesktop"} style={{paddingTop: "0", paddingBottom: "0"}}>
                            <h1 className={"h1--lined--dark p--small"} style={{marginTop: 0, lineHeight: 2, textAlign: "center", paddingLeft: "10px"}}>Dr. med. Alexandra Knauer</h1>
                        </div>
                    </div>
                    <form style={{marginTop: "30px"}} onSubmit={(event) => { this.handleSubmit(event, this.props.loginFunction) }}>
                        <TextField
                            required
                            style={{width: "100%"}}
                            id="filled-email-input-username"
                            label="Username"
                            autoComplete="email"
                            variant="filled"
                            name="username"
                            defaultValue={this.state.input.username}
                            disabled={this.state.disabled}
                            onChange={(e) => this.handleChange(e)}
                        /><br/><br/>
                        <TextField
                            required
                            style={{width: "100%"}}
                            id="filled-email-input-pass"
                            label="Passwort"
                            autoComplete="email"
                            variant="filled"
                            name="password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            value={this.state.input.password}
                            disabled={this.state.disabled}
                            onChange={(e) => this.handleChange(e)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            aria-label="Toggle password visibility"
                                            onClick={this.handleClickShowPassword}
                                        >
                                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        /><br/><br/>

                        <Button
                            style={{borderRadius: "12px"}}
                            variant="contained"
                            color="primary"
                            className={"button--primary"}
                            name="submit"
                            type="submit">
                            Login
                        </Button>
                    </form>

                        </div>}
                    <br/>
                    <br/>

                    <br/>
                    <p style={classes.errorMessage}>{this.state.errorMessage}</p>

                </div>
            </div>
        );
    }
}
