import React, {Component} from 'react';

import config from "../config";
import AppBarComp from "../components/global/AppBarComp";
import DoEomFirst from "../components/doEomFirst/doEomFirst";
import GutComp from "../components/gut/gut";
import EomSnackbar from "../components/global/EomSnackbar";
const JSONAPI_ROOT = `${config.base}/jsonapi/`;

class Gut extends Component {
    constructor() {
        super();
        this.state = {
            eomType: "initial state",
            tipps: []
        };
    }

    loadNodeTippsData =()=> {
        this.fetchJsonApiGetTipps('data', `${JSONAPI_ROOT}node/gut_fur_mich`, true);
    };

    fetchJsonApiGetTipps =(destination, url)=> {
        fetch(url, {
            method: 'GET',
            //mode: 'cors',
        })
            .then(function(response) {
                return response.json();
            })
            .then(data => {
                let tipps=[];
                for (let i = 0; i < data.data.length; i++) {
                    tipps.push([data.data[i].id, data.data[i].attributes.title]);
                }
                this.setState({'tipps': tipps});
            })
            .catch(err => console.log('API error:', err));
    };

    renderTipps() {
        let tippsMarkup = [];
        for (let i = 0; i < this.state.tipps.length; i++) {
            tippsMarkup.push(
                <GutComp
                    eom_type={this.state.eomType}
                    appUserLoggedIn={this.state.appUserLoggedIn}
                    contentType="tipp"
                    id={this.state.tipps[i][0]}/>
            )
        }
        return (
            <div>
                {tippsMarkup}
            </div>
        )
    };

    componentWillMount() {
        const eom_type = localStorage.getItem("eom-type");
        this.setState({
            eomType: eom_type
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.loadNodeTippsData();
    }

    eomColor=()=> {
        if (this.state.eomType.indexOf("A") !== -1) {
            return(
                "eom-a"
            )
        }
        else if (this.state.eomType.indexOf("B") !== -1) {
            return(
                "eom-b"
            )
        }
        else if (this.state.eomType.indexOf("C") !== -1) {
            return(
                "eom-c"
            )
        }
        else if (this.state.eomType.indexOf("D") !== -1) {
            return(
                "eom-d"
            )
        }
        else if (this.state.eomType.indexOf("E") !== -1) {
            return(
                "eom-e"
            )
        }
        else if (this.state.eomType.indexOf("F") !== -1) {
            return(
                "eom-f"
            )
        }
        else if (this.state.eomType.indexOf("G") !== -1) {
            return(
                "eom-g"
            )
        }
    };

    render() {
        return (
            <div className={"app-wrapper"}>
                <main className={"main"}>
                    <AppBarComp appUserLoggedIn={this.props.appUserLoggedIn} handleLogout={this.props.handleLogout}/>
                    <article className={"article article--with-stripe fade"}>

                        {(this.state.eomType === null || this.state.eomType === undefined || this.state.eomType === "initial state") &&
                        <DoEomFirst />
                        }

                        {(this.state.eomType !== null && this.state.eomType !== undefined && this.state.eomType !== "initial state") &&

                        <div>
                            <div className={this.eomColor()}>
                                <div className={"rezepte-colored-stripe"}/></div>
                            {this.renderTipps()}
                        </div>
                        }
                    </article>
                </main>

                <EomSnackbar/>
            </div>
        );
    }
}

export default Gut;

