import React from 'react';
import AppBarComp from "./../components/global/AppBarComp";
import Video7 from "../assets/videos/bellydays7.m4v";
import Video8 from "../assets/videos/bellydays8.m4v";
import EomSnackbar from "../components/global/EomSnackbar";

function DeineBauchmassage(props) {
    return (
        <>
                <div className={"app-wrapper"}>
                    <div id={"select"}></div>
                    <main className={"main"}>
                        <AppBarComp appUserLoggedIn={props.appUserLoggedIn}
                                    handleLogout={props.handleLogout}/>
                        <article
                            className={"article article--with-stripe fade"}>
                            <div className={"article-inner staticcontent"}>
                                <h1 className={"h1--lined--dark"}>Deine
                                    Bauchmassage</h1>
                                <br/><br/>
                                <div className={"aut-video-container"}>
                                    <div className="container">
                                        <div className='Flexible-container'>
                                            <video width="500" height="322"
                                                   controls>
                                                <source src={Video7}/>
                                            </video>
                                        </div>
                                    </div>
                                </div>

                                <br/><br/>
                                <div className={"aut-video-container"}>
                                    <div className="container">
                                        <div className='Flexible-container'>
                                            <video width="500" height="322"
                                                   controls>
                                                <source src={Video8}/>
                                            </video>
                                        </div>
                                    </div>
                                </div>
                                <br/>

                                <p>Anleitung:</p>
                                <p><strong>1. Schritt</strong></p>
                                <p>Lege die rechte Hand auf den Bereich des
                                    Magens und streiche nach unten bis zum
                                    Nabel. Sobald die rechte Hand beim Nabel
                                    angelangt ist, streiche mit der linken Hand
                                    von oben bis zum Nabel, dann wieder mit der
                                    rechten und so weiter.</p>
                                <p>Arbeite langsam und sanft, die Bewegung soll
                                    angenehm sein. Atme dabei in den Bauch und
                                    versuchen Sie die Bauchdecke zu entspannen.
                                </p>
                                <p>Nach 1 Minute gehe zum nächsten Schritt
                                    über.</p>
                                <br/>

                                <p><strong>2. Schritt</strong></p>
                                <p>Lege die Hände so auf den Bauch, dass die
                                    Daumen zum Nabel zeigen und die freie Fläche
                                    zwischen den Händen am Unterbauch einem
                                    Dreieck gleicht. Atme 3 x langsam in den
                                    Unterbauch, dass sich die Bauchdecke hebt
                                    und senkt. Versuche während der Einatmung
                                    bis 8 zu zählen und ebenso bei der
                                    Ausatmung.
                                </p>
                                <br/>

                                <p><strong>3. Schritt</strong></p>
                                <p>Beginne nun mit der rechten Hand gegen den
                                    Uhrzeigersinn kleine kreisende Bewegungen zu
                                    machen. Die Hand bleibt dabei liegen und
                                    verschiebt lediglich die Haut. Du brauchst
                                    keinen Druck auszuüben, wenn es sich jedoch
                                    gut anfühlt für dich, dann kannst du bei der
                                    Massage auch leichten Druck ausüben. </p>
                                <p>Die linke Hand bleibt vorerst ruhig
                                    liegen.</p>
                                <p>Nach 1 Minute gehe zum nächsten Schritt
                                    über.</p>
                                <br/>

                                <p><strong>4. Schritt</strong></p>
                                <p>Jetzt bleibt die rechte Hand ruhig liegen,
                                    während die linke Hand im Uhrzeigersinn
                                    kleine kreisende Bewegungen macht. Den Druck
                                    kannst du wieder selbst bestimmen.</p>
                                <p>Nach 1 Minute gehe wieder zum nächsten
                                    Schritt über.</p>
                                <br/>

                                <p><strong>5. Schritt</strong></p>
                                <p>Lass nun beide Hände gleichzeitig kreisen –
                                    die rechte entgegen dem Uhrzeigersinn und
                                    die linke Hand mit dem Uhrzeigersinn.
                                    Anfangs erfordert das etwas Konzentration,
                                    aber nach wenigen Sekunden wirst du das
                                    perfekt machen!</p>
                                <p>Nach 1 Minute gehe zum nächsten Schritt
                                    über.</p>
                                <br/>

                                <p><strong>6.Schritt</strong></p>
                                <p>Lege nun die rechte Hand über den Bauchnabel
                                    und die linke Hand unter den Bauchnabel.
                                    Wiederhole die Schritte 3 bis 5 in der neuen
                                    Position jeweils für 1 Minute.</p>
                                <p>Bleib danach noch etwas Liegen und atme
                                    nochmals langsam und ruhig in den
                                    Bauch. </p>
                                <br/>

                                <br/>
                                <br/>
                                <br/>
                                <br/>
                            </div>
                        </article>
                    </main>
                    <EomSnackbar/>
                </div>

        </>
    );
}

export default DeineBauchmassage;
