import React, {Component} from 'react';
import config from '../../config.js';
const JSONAPI_ROOT = `${config.base}/jsonapi/`;

class Tipp extends Component {
    constructor() {
        super();
        this.state = {
            eomType: "initial state",
            nodeTitle: "",
            nodeBody: "",
            nodeImageUrl: "",
            image: "",
            nodeEomTypes: "",
        };
    }

    loadNodeRezeptData =()=> {
        this.fetchJsonApiGetRezept('data', `${JSONAPI_ROOT}node/${this.props.contentType}/${this.props.id}`, true);
    };

    loadImageData =(image)=> {
        this.fetchJsonApiGetImage('data', image, true);
    };

    fetchJsonApiGetRezept =(destination, url)=> {
        fetch(url, {
            method: 'GET',
            //mode: 'cors',
        })
            .then(function(response) {
                return response.json();
            })
            .then(data => {
                this.setState({
                    nodeTitle: data.data.attributes.title,
                    nodeBody: data.data.attributes.field_body.value,
                    nodeImageUrl: data.data.relationships.field_bild.links.related.href,
                    nodeEomTypes: data.data.attributes.field_rezeptzuordnung_eom_typ,
                });
                this.loadImageData(this.state.nodeImageUrl);
            })
            .catch(err => console.log('API error:', err));
    };

    componentWillMount(){
        const eom_type = localStorage.getItem("eom-type");
        this.setState({
            eomType: eom_type
        });
    }

    componentDidMount() {
        this.loadNodeRezeptData();
    }

    fetchJsonApiGetImage =(destination, url)=> {
        fetch(url, {
            method: 'GET',
            //mode: 'cors',
        })

            .then(function(response) {
                return response.json();
            })
            .then(data => {
                this.setState({
                    image: data.data.attributes.uri.url
                });
            })
            .catch(err => console.log('API error:', err));
    };


    render() {
        return (
            <div>
                {this.state.nodeEomTypes.indexOf(this.state.eomType) !== -1 &&
                <div>
                    <div className={"article-inner"}>
                        <h1 className={"h1--lined--dark"} style={{marginTop: "20px"}}>
                            Mein Tipp für dich heute
                        </h1>
{/*                        <h1 className={"h1--lined--dark"}>
                            {this.state.nodeTitle}
                        </h1>*/}
                        <div className="h1--lined--dark--multiline" dangerouslySetInnerHTML={{__html: this.state.nodeBody}}/>
                    </div>
                    <div className={"image-wrapper"} style={{background: `url(${config.base}${this.state.image})`}}/>

                    <br/>
                    <br/>
                </div>
                }
            </div>
        );
    }
}

export default Tipp;

