import React, {Component} from 'react';

import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircleLarge from "../../assets/images/circle2.svg";

const styles = ({
    logoIconLargeContainer: {
        position: "relative",
        textAlign: "center",
        margin: "0 auto"
    },
    logoIconLarge: {
        width: "85%",
        padding: "20px 10% !important"
    },
    logoIconLargeText: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%"
    },
});


class DoEomFirst extends Component {
    render() {
        return (
            <div>
                <div className={"article-inner"}>
                    <h1 className={"h1--lined--dark"}>Mach bitte zuerst</h1>
                    <h1 className={"h1--lined--dark"}>deine Analyse</h1>
                    <h1 className={"h1--lined--dark"}>damit du die Inhalte</h1>
                    <h1 className={"h1--lined--dark"}>dieser Seite siehst.</h1>
                </div>



                <div className={"article-inner"}>
                    <Link to={"/analyse"} style={styles.logoIconLargeContainer}>
                        <Button size="small" style={styles.logoIconLarge} >
                            <img src={CircleLarge} alt={"circle-large"} className={"landingpage-circle"}/>
                            <div style={styles.logoIconLargeText} id={"logo-icon-large-text"}>
                                <h1 className={"h1--lined--dark h1--large"} style={{borderBottom: "none", textTransform: "none"}}>Hier geht es</h1>
                                <h1 className={"h1--lined--dark h1--large"} style={{borderBottom: "none", textTransform: "none"}}>zu deiner</h1>
                                <h1 className={"h1--lined--dark h1--large"} style={{borderBottom: "none", textTransform: "none"}}>persönlichen</h1>
                                <h1 className={"h1--lined--dark h1--large"} style={{borderBottom: "none", textTransform: "none"}}>Analyse</h1>
                            </div>
                        </Button>
                    </Link>
                </div>
            </div>
        );
    }
}

export default DoEomFirst;
