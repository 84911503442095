let base = "";
let base_frontend = "";

if (window.location.hostname === "localhost") {
    base = "http://bellydays.lndo.site"
} else {
    base = "https://app-admin.bellydays.com"
}

if (window.location.hostname === "localhost") {
    base_frontend = "http://localhost:3000/"
} else {
    base_frontend = "https://app.bellydays.com"
}

export default {
    base: base,
    base_frontend: base_frontend,
    oauth: {
        grant_type: 'password',
        client_id: 'f19bf451-36a7-48f3-92ee-81aefe83cf12',  // YOUR CLIENT ID
        client_secret: 'abc123', // YOUR CLIENT SECRET
        scope: 'drupal_dev', // USER ROLE
    }
};