import React from 'react';
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Checkbox from '@material-ui/core/Checkbox';

import FormLabel from '@material-ui/core/FormLabel';

import Divider from "@material-ui/core/Divider";
import {withStyles} from "@material-ui/styles";

const styles = theme => ({
    selectWrapperInner: {
        padding: "20px",
        height: "100%"
    },

    backgroundLight:  {
        backgroundColor: "#ffffff"
    },

    formControlLabel: {
        display: "flex",
        flexDirection: "column-reverse",
        paddingTop: "20px",
        paddingBottom: "20px"
    },
});


class EomNewOptionsVerzicht extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            brot: false,
            sweets: false,
            fleisch: false,
            chilli: false,
            kaffee: false,
            alkohol: false,

            verzicht: []
        };
    }

    hideAppBar =()=> {
        document.getElementsByTagName("HEADER")[0].style.display = "none";
        document.getElementsByTagName("ARTICLE")[0].style.paddingTop = "50px";
    };

    componentWillMount() {
        let verzicht = JSON.parse(localStorage.getItem("verzicht"));
        if(verzicht !== null) {
            this.setState({
                brot: verzicht[0],
                sweets: verzicht[1],
                fleisch: verzicht[2],
                chilli: verzicht[3],
                kaffee: verzicht[4],
                alkohol: verzicht[5],
            });
        }
    }

    componentDidMount=()=> {
        window.scrollTo(0, 1);
        // this.hideAppBar();
    };

    handleChange=(value)=> {
        switch(value) {
            case "brot":
                this.setState({
                    brot: !this.state.brot
                });
                break;

            case "sweets":
                this.setState({
                    sweets: !this.state.sweets
                });
                break;

            case "fleisch":
                this.setState({
                    fleisch: !this.state.fleisch
                });
                break;

            case "chilli":
                this.setState({
                    chilli: !this.state.chilli
                });
                break;

            case "kaffee":
                this.setState({
                    kaffee: !this.state.kaffee
                });
                break;

            case "alkohol":
                this.setState({
                    alkohol: !this.state.alkohol
                });
                break;

            default:
            console.log("nothing valuable clicked")
        }
    };

    componentWillUnmount() {
        localStorage.setItem("verzicht", JSON.stringify([
            this.state.brot, this.state.sweets, this.state.fleisch, this.state.chilli, this.state.kaffee, this.state.alkohol
        ]))
    }

    render() {
        return (
            <div className={"article-wrapper-stepper_select"}>
                <div className={"fade"} style={{width: "100%"}}>
                    <div>
                        <div className={"checkbox-group"} >
                            <FormLabel component="legend">Brot</FormLabel>
                            <FormControlLabel
                                id={"select-verzicht"}
                                value={this.state.brot}
                                checked={this.state.brot}
                                control={<Checkbox color="primary"/>}
                                label=""
                                labelPlacement="top"
                                onChange={() => this.handleChange("brot")}
                            />
                        </div>

                            <Divider className={"select-wrapper-divider"}/>

                        <div className={"checkbox-group"}>
                            <FormLabel component="legend">Süßigkeiten</FormLabel>
                            <FormControlLabel
                                value={this.state.sweets}
                                checked={this.state.sweets}
                                control={<Checkbox color="primary" />}
                                label=""
                                labelPlacement="top"
                                onChange={() => this.handleChange("sweets")}
                            />
                        </div>

                            <Divider className={"select-wrapper-divider"}/>

                         <div className={"checkbox-group"}>
                            <FormLabel component="legend">Fleisch</FormLabel>
                            <FormControlLabel
                                value={this.state.fleisch}
                                checked={this.state.fleisch}
                                control={<Checkbox color="primary" />}
                                label=""
                                labelPlacement="top"
                                onChange={() => this.handleChange("fleisch")}
                            />
                         </div>

                            <Divider className={"select-wrapper-divider"}/>

                          <div className={"checkbox-group"}>
                            <FormLabel component="legend">Chilli und andere scharfe Gewürze</FormLabel>
                            <FormControlLabel
                                value={this.state.chilli}
                                checked={this.state.chilli}
                                control={<Checkbox color="primary" />}
                                label=""
                                labelPlacement="top"
                                onChange={() => this.handleChange("chilli")}
                            />
                          </div>

                            <Divider className={"select-wrapper-divider"}/>

                          <div className={"checkbox-group"}>
                            <FormLabel component="legend">Kaffee</FormLabel>
                            <FormControlLabel
                                value={this.state.kaffee}
                                checked={this.state.kaffee}
                                control={<Checkbox color="primary" />}
                                label=""
                                labelPlacement="top"
                                onChange={() => this.handleChange("kaffee")}
                            />
                          </div>

                            <Divider className={"select-wrapper-divider"}/>

                           <div className={"checkbox-group"}>
                            <FormLabel component="legend">Alkohol</FormLabel>
                            <FormControlLabel
                                value={this.state.alkohol}
                                checked={this.state.alkohol}
                                control={<Checkbox color="primary" />}
                                label=""
                                labelPlacement="top"
                                onChange={() => this.handleChange("alkohol")}
                            />
                           </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default withStyles(styles) (EomNewOptionsVerzicht);
