import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Divider from "@material-ui/core/Divider";
import {withStyles} from "@material-ui/styles";
import UpPlaceholder from "../../assets/images/up_placeholder.svg";

const styles = theme => ({

});


class EomNewOptionsCold extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            input: {
                value: ""
            },
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        let newState = Object.assign({}, this.state);
        newState.input[event.target.name] = event.target.value;
        this.setState(newState, /*, () => console.log(this.state)*/);

        localStorage.setItem(this.props.localStorageItem, newState.input[event.target.name]);
        event.preventDefault();
    }

    componentWillMount() {
        // First time the app is used, set respective localStorageItem to startingpoint
        const item = localStorage.getItem(this.props.localStorageItem);
        if (item === null) {
            localStorage.setItem(this.props.localStorageItem, this.props.defaultValue);
            this.setState({
                input: {
                    value: this.props.defaultValue
                }
            })
        } else {
            this.setState({
                input: {
                    value: item
                }
            })
        }
    }

    _renderFormControlLabels =()=> {
        const labels = [];
        for (let i = 0; i < this.props.values.length; i++) {
            if (i < (this.props.values.length - 1)) {
                labels.push(
                    <div>
                        <FormControlLabel className={this.props.classes.formControlLabel} value={this.props.values[i]} control={<Radio/>} label={this.props.labels[i]} labelPlacement="top"/>
                        <Divider className={"select-wrapper-divider"}/>
                    </div>
                )
            } else {
                labels.push(
                    <div>
                        <FormControlLabel className={this.props.classes.formControlLabel} value={this.props.values[i]} control={<Radio/>} label={this.props.labels[i]} labelPlacement="top"/>
                    </div>
                )
            }
        }
        return (
            <div>{labels}</div>
        );
    };

    componentDidMount=()=> {
        window.scrollTo(0, 0);
        // this.hideAppBar();
    };

    hideAppBar =()=> {
        document.getElementsByTagName("HEADER")[0].style.display = "none";
    };


    render() {
        const { localStorageItem, ariaLabel } = this.props;

        return (
            <div className={"article-wrapper-stepper_select"}>
                <div className={"fade"} style={{width: "100%"}}>
                    <img src={UpPlaceholder} alt={"up"} className={"up-down-button"}/>
                    <div>
                        <FormControl component="fieldset" style={{height: "100%",}} className={"fade"}>
                            <RadioGroup
                                name={localStorageItem}
                                aria-label={ariaLabel}
                                defaultValue={this.state.input.value}
                                value={this.state.value}
                                onChange={this.handleChange}
                                style={{justifyContent: "center", height: "100%",}}
                            >
                                {this._renderFormControlLabels()}
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles) (EomNewOptionsCold);
