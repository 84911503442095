import React from 'react';
import AppBarComp from "./../components/global/AppBarComp";

import Video6 from "../assets/videos/bellydays6.m4v";
import BiogenaLogo from "../assets/images/biogena_logo.png";
import EomSnackbar from "../components/global/EomSnackbar";

function DeineNahrungsergaenzung(props) {
    return (
        <>

            <div className={"app-wrapper"}>
                <div id={"select"}></div>
                <main className={"main"}>
                    <AppBarComp appUserLoggedIn={props.appUserLoggedIn}
                                handleLogout={props.handleLogout}/>
                    <article className={"article article--with-stripe fade"}>
                        <div className={"article-inner staticcontent"}>
                            <h1 className={"h1--lined--dark"}>Deine
                                Nahrungsergänzung</h1>
                            <br/><br/>
                            <div className={"aut-video-container"}>
                                <div className="container">
                                    <div className='Flexible-container'>
                                        <video width="500" height="322"
                                               controls>
                                            <source src={Video6}/>
                                        </video>
                                    </div>
                                </div>
                            </div>
                            <br/>

                            <p><strong>Einkaufliste:</strong></p>
                            <p><strong>Erhältlich in der Apotheke:</strong></p>
                            <ul>
                                <li>Bittersalz (Magnesium sulfuricum) 100 g
                                    oder
                                </li>
                                <li>Magnesium citricum 100 g</li>
                            </ul>
                            <br/>

                            <p><strong>Erhältlich bei Biogena:</strong></p>
                            <div style={{textAlign: 'left'}}>
                                <img src={BiogenaLogo} alt="Biogena Logo"/>
                            </div>
                            <ul className="biogena-links">
                                <li><a
                                    href="https://www.biogena.com/de-AT/produkte/antioxidans-formula-60-kapseln.html?afid=V23266"
                                    rel="noopener noreferrer" target="_blank"
                                    title="Antioxidans Formula">Antioxidans
                                    Formula 1-2 Kapseln</a></li>
                                <li><a
                                    href="https://www.biogena.com/de-AT/produkte/leber-galle-formula.html?afid=V23266"
                                    rel="noopener noreferrer" target="_blank"
                                    title="Leber/Galle Formula">Leber/Galle
                                    Formula 1 Kapsel</a></li>
                                <li><a
                                    href="https://www.biogena.com/de-AT/produkte/basogena-5-energet-basenpulver.html?afid=V23266"
                                    rel="noopener noreferrer" target="_blank"
                                    title="Basogena 5e Basenpulver">Basogena 5e
                                    Basenpulver</a></li>
                                <li><a
                                    href="https://www.biogena.com/de-AT/produkte/omni-lactis-10-caps.html?afid=V23266"
                                    rel="noopener noreferrer" target="_blank"
                                    title="Omni Lactis">Omni Lactis 1 Kapsel</a>
                                </li>
                                <li><a
                                    href="https://www.biogena.com/de-AT/produkte/siebensalz-magnesium.html?afid=V23266      "
                                    rel="noopener noreferrer" target="_blank"
                                    title="Siebensalz Magnesium">Siebensalz
                                    Magnesium 1-2 Kapseln</a></li>
                            </ul>

                            <p>Optional:</p>
                            <ul className="biogena-links">
                                <li><a
                                    href="https://www.biogena.com/de-AT/produkte/ester-c-gold-90-kapseln.html?afid=V23266"
                                    rel="noopener noreferrer" target="_blank"
                                    title="ster C Gold">Ester C Gold</a></li>
                                <li><a
                                    href="https://www.biogena.com/de-AT/produkte/neurogesan-b-komplex-active-60-kapseln.html?afid=V23266"
                                    rel="noopener noreferrer" target="_blank"
                                    title="Neurogesan B-Komplex active">Neurogesan
                                    B-Komplex active</a></li>
                                <li><a
                                    href="https://www.biogena.com/de-AT/produkte/kaliumcitrat-120-kapseln.html?afid=V23266"
                                    rel="noopener noreferrer" target="_blank"
                                    title="Kaliumcitrat">Kaliumcitrat</a></li>
                            </ul>
                            <br/>
                            <br/>

                            <p><strong>Anleitung:</strong></p>
                            <p>Unmittelbar nach dem Aufstehen 1 TL Bittersalz
                                und 1 TL Basenpulver in ¼ Liter lauwarmen Wasser
                                auflösen und zügig trinken. Danach kannst du
                                dein Morgenritual fortsetzen.</p>
                            <p>Vor dem Frühstück sind folgende
                                Nahrungsergänzungsmittel mit reichlich
                                Flüssigkeit einzunehmen:</p>
                            <ul>
                                <li>Antioxidans Formula 1-2 Kapseln</li>
                                <li>Siebensalz Magnesium 1-2 Kapseln</li>
                                <li>Leber/Galle Formula 1 Kapsel</li>
                                <li>Omni Lactis 1 Kapsel</li>
                            </ul>

                            <p>Optional:</p>
                            <ul>
                                <li>Ester C Gold</li>
                                <li>Neurogesan B-Komplex active</li>
                                <li>Kaliumcitrat</li>
                            </ul>

                            <p>Wenn du an Verstopfung leidest, empfehle ich
                                zusätzlich 1 Kapsel Kaliumcitrat.</p>
                            <p>Bei starken Entgiftungserscheinungen
                                (Kopfschmerzen, Unwohlsein, Hautrötungen, etc.)
                                empfehle ich zusätzlich 1 Kapsel Ester-C und 1
                                Kapsel Neurogesan am Tag.</p>
                            <p>Abends kann statt einer weiteren Mahlzeit 1 TL
                                Basenpulver in ¼ l Wasser aufgelöst getrunken
                                werden. Basenpulver sollte niemals gemeinsam mit
                                einer Mahlzeit eingenommen werden – hier sollte
                                nach dem Essen ein Abstand von 2 Stunden
                                beachtet werden.</p>

                            {/*                        <a href="https://www.biogena.com?afid=V23266" target="_blank" rel="noopener noreferrer" title="Zum Biogena Onlineshop">
                            <h1 className="h1--lined--dark drawer-link--dark" style={{marginTop: '40px'}}>Zum Biogena Onlineshop</h1>
                            <br />
                            <img src={BiogenaLogo} alt="Biogena Logo" />
                        </a>*/}
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                    </article>
                </main>
                <EomSnackbar/>
            </div>

                </>
    );
}

export default DeineNahrungsergaenzung;
