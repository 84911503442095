import React, {Component} from 'react';

import config from "../config";
import AppBarComp from "../components/global/AppBarComp";
import DoEomFirst from "../components/doEomFirst/doEomFirst";
import Tipp from "../components/tipp/tipp";
import EomSnackbar from "../components/global/EomSnackbar";
import CircleLarge from "../assets/images/circle-large.svg";
const JSONAPI_ROOT = `${config.base}/jsonapi/`;

class Type extends Component {
    constructor() {
        super();
        this.state = {
            eomType: "initial state",
            eomText: [],
            tipps: []
        };
    }

    loadNodeTippsData =()=> {
        this.fetchJsonApiGetTipps('data', `${JSONAPI_ROOT}node/tipp`, true);
    };

    fetchJsonApiGetTipps =(destination, url)=> {
        fetch(url, {
            method: 'GET',
            //mode: 'cors',
        })
            .then(function(response) {
                return response.json();
            })
            .then(data => {
                let tipps=[];
                for (let i = 0; i < data.data.length; i++) {
                    tipps.push([data.data[i].id, data.data[i].attributes.title]);
                }
                this.setState({'tipps': tipps});
            })
            .catch(err => console.log('API error:', err));
    };

    renderTipps() {
        let tippsMarkup = [];
        for (let i = 0; i < this.state.tipps.length; i++) {
            tippsMarkup.push(
                <Tipp
                    eom_type={this.state.eomType}
                    appUserLoggedIn={this.state.appUserLoggedIn}
                    contentType="tipp"
                    id={this.state.tipps[i][0]}/>
            )
        }
        return (
            <div>
                {tippsMarkup}
            </div>
        )
    };

    componentWillMount() {
        const eom_type = localStorage.getItem("belly-type");
        this.setState({
            eomType: eom_type
        });

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.loadNodeTippsData();

        if (this.state.eomType.indexOf("A") !== -1) {
            this.setState({
                eomText: [
                    "ist ein Abenteurer mit hoher Individualität. Wegen seiner Neugier und Flexibilität\n" +
                    "ist er nicht selten ein Pionier in seinem Gebiet. Aus ganzheitsmedizinischer Sicht überwiegt\n" +
                    "bei dieser Typisierung der Faktor Hitze. Die häufigste Ursache für verstärkte Hitze im Körper\n" +
                    "sind Emotionen, aber auch klimatische Bedingungen (Hitze von außen). Vorbestehende\n" +
                    "entzündliche Erkrankungen können diese Tendenz verstärken. Hitze bewegt und\n" +
                    "beschleunigt, deshalb ist Typ A ständig aktiv und hat einen natürlichen Antrieb bei relativ\n" +
                    "geringem Schlafbedürfnis. Durch Trinken von kalten Getränken wird oft versucht, die innere\n" +
                    "Hitze regelrecht zu löschen.",

                    "Gesundheitlich ist das größte Risiko ohne Gegenmaßnahmen die Neigung zu Bluthochdruck.\n" +
                    "Auch können bestehende entzündliche Erkrankungen, die ganzheitsmedizinische als\n" +
                    "Hitzeerkrankungen beschrieben sind, wiederum verstärkt werden. Das gesundheitliche Ziel\n" +
                    "für Typ A ist, die innere Hitze zu kühlen. Das funktioniert am besten über eine kühlende\n" +
                    "\n" +
                    "Ernährungsweise und durch beruhigende Ausgleichsmethoden wie Meditation, Qi Gong und\n" +
                    "einen erholsamen Schlaf."
                ]
            });
        }
        else if (this.state.eomType.indexOf("B") !== -1) {
            this.setState({
                eomText: [
                    "ist der Kreative mit vielen Ideen. In der Regel zeichnen ihn Eigenschaften wie\n" +
                    "Lebenslust, Offenheit und Geselligkeit aus. Aufgrund seiner beschleunigten\n" +
                    "Stoffwechselaktivität laufen bestimmte Prozesse schneller ab als bei anderen. Beispielsweise\n" +
                    "verlangt Typ B nicht selten nach mehreren Mahlzeiten, weil er rasch wieder Hunger\n" +
                    "bekommt. Zudem überwiegt die Hitze und dadurch besteht eine gewisse innere Unruhe. Es\n" +
                    "kommt vor, dass sich Typ B schwer auf eine Sache konzentrieren und fokussiert arbeiten\n" +
                    "kann. Er ist ungern tatenlos und hat einen natürlichen Bewegungsdrang. Darüber hinaus ist\n" +
                    "er tendenziell stressanfällig und verträgt Kaffee und Alkohol, die das System zusätzlich\n" +
                    "anregen, nicht sehr gut.",

                    "Ohne Gegenmaßnahmen kann ein erhöhtes Risiko für hormonelle Störungen und\n" +
                    "Folgeerkrankungen der Stressbelastung wie Herzrhythmusstörungen und Bluthochdruck\n" +
                    "bestehen. Aus gesundheitlicher Sicht ist das Ziel für Typ B, die innere Hitze zu kühlen und\n" +
                    "gezielte Körperfunktionen durch Erholungsphasen und ausgleichende Bewegungsformen wie\n" +
                    "Yoga, Qi Gong zu harmonisieren. Die Ernährung konzentriert sich auf Lebensmittel, die einen\n" +
                    "kühlenden und zugleich nährenden Effekt haben."
                ]
            });
        }
        else if (this.state.eomType.indexOf("C") !== -1) {
            this.setState({
                eomText: [
                    "ist der Aktive und Sportive. Bei seiner vernünftigen Lebenshaltung hat er eine\n" +
                    "gesunde Lebensweise und Ernährung im Fokus, dafür wurden bestimmt auch schon\n" +
                    "verschiedene Diäten oder Sportarten ausprobiert. Häufig hat Typ C eine starke Ausprägung\n" +
                    "von Disziplin und Präzision, deshalb finden sich unter dieser Typisierung häufig\n" +
                    "Ausdauersportler oder Menschen mit einem Fitness-Abo. Typ C ist meist gut strukturiert und\n" +
                    "hat klare Vorstellungen, wie er sich ernähren möchte (z.B. vegane Ernährung). Dabei kann er\n" +
                    "aber auch an einer bestimmten Moral festhalten. Wenn er seine Überzeugungen übertreibt,\n" +
                    "werden dabei die körperlichen Grenzen nicht selten ausgereizt. Darunter kann auch die\n" +
                    "Regenerationsfähigkeit leiden.",

                    "Ohne Gegenmaßnahmen sind das größte Risiko von Typ C mögliche Nährstoff-Defizite. Das\n" +
                    "kann sich indirekt über Erschöpfung bemerkbar machen. Am ehesten ist aus\n" +
                    "gesundheitlicher Sicht bei Typ C darauf zu achten, dass er ausreichend Erholung und\n" +
                    "Ruhephasen findet. In Bezug auf die Ernährung sollte der Fokus auf nährende Mahlzeiten in\n" +
                    "guter Gesellschaft legen, bei der die Genussfähigkeit im Vordergrund steht. Denn Liebe geht\n" +
                    "durch den Magen!"
                ]
            });
        }
        else if (this.state.eomType.indexOf("D") !== -1) {
            this.setState({
                eomText: [
                    "ist der Disziplinierte mit einem erhöhten Anspruch an Sicherheit. Um den Körper\n" +
                    "fit zu halten, erfolgt körperliche Aktivität eher durch ein Pflichtbewusstsein als durch reinen\n" +
                    "Bewegungsdrang. Rhythmen und regelmäßige Mahlzeiten sind für das Wohlbefinden\n" +
                    "wichtig, deshalb wird der Nahrungsaufnahme eine bestimmte Aufmerksamkeit geschenkt.\n" +
                    "Von der Persönlichkeit her zeichnet sich Typ D durch ein hohes Maß an Loyalität,\n" +
                    "Beständigkeit und Zuverlässigkeit aus. Auch die Tradition hat einen hohen Stellenwert.",

                    "Gesundheitlich neigt Typ D bei fehlender Einhaltung seiner Rhythmen rasch zu Problemen im\n" +
                    "Verdauungstrakt. Aus ganzheitsmedizinischer Sicht überwiegt bei dieser Typisierung der\n" +
                    "Faktor Kälte, vor allem im Bereich der Verdauungsfähigkeit. Das führt zu einer Sensibilität\n" +
                    "\n" +
                    "des Magen-Darm-Trakts. Häufig sind bestimmte Lebensmittel sowie rohe und kalte Speisen\n" +
                    "nicht gut verträglich. Das kann letztendlich Nährstoffdefizite zur Folge haben.\n" +
                    "Aus gesundheitlicher Sicht ist es das Ziel für Typ D, dem Verdauungstrakt Wärme\n" +
                    "zuzuführen. Das geht am besten mit regelmäßigen warmen und nährenden Mahlzeiten."
                ]
            });
        }
        else if (this.state.eomType.indexOf("E") !== -1) {
            this.setState({
                eomText: [
                    "ist der Gemütliche mit Sinn für Häuslichkeit und Familie. Mitgefühl und Führsorge\n" +
                    "werden ihm in die Wiege gelegt, so kümmert er sich gerne und liebevoll um Mensch\n" +
                    "und/oder Tier. Er hat wenig Eigenantrieb, deshalb bleibt er auch lieber zu Hause als dass er\n" +
                    "ausgeht. Harmonie ist ihm wichtiger als Aktivität und Bewegung. Sport ist nur selten auf der\n" +
                    "Liste der Hobbys, denn lieber macht es sich Typ E auf der Couch gemütlich und knabbert\n" +
                    "etwas oder schläft noch ein bisschen.",

                    "Ohne geeignete Maßnahmen neigt Typ E am ehesten zu Übergewicht und einer vermehrten\n" +
                    "Schleimbildung aufgrund einer erschöpftten Verdauungsleistung. Das kann die Neigung zu\n" +
                    "chronischen Atemwegserkrankungen zur Folge haben. Da Süßigkeiten und Eisgekühltes von\n" +
                    "der Veranlagung her den Verdauungstrakt am meisten schwächen, sollten speziell diese\n" +
                    "Lebensmittel gemieden werden. Stattdessen können zur Gesundheitsförderung warme und\n" +
                    "scharfe Gewürze die Konstitution positiv beeinflussen. Um körperlich in die Gänge zu\n" +
                    "kommen, ist zu Motivationszwecken Bewegung in der Gruppe von Vorteil."
                ]
            });
        }
        else if (this.state.eomType.indexOf("F") !== -1) {
            this.setState({
                eomText: [
                    "ist ein guter Zuhörer und Gesprächspartner mit hoher Aufmerksamkeit. Wegen\n" +
                    "seiner Hingabe ist er der perfekte Freund und Partner. Wenn es um die Arbeit geht, zeichnen\n" +
                    "ihn Ausdauer, Durchhaltevermögen und Beharrlichkeit aus. Diese Eigenschaften sind aber\n" +
                    "ebenso im familiären Kontext von Vorteil, weil Typ F gerne für das Allgemeinwohl sorgt und\n" +
                    "dabei oft viel Geduld beweist. Es dominiert der geistige Anspruch im Verhältnis zur\n" +
                    "körperlichen Aktivität. Nicht selten sind unter diesem Typus Philosophen, Denker und\n" +
                    "Pädagogen zu finden.",

                    "Typ F neigt bei falscher Ernährung zu Übergewicht und/oder erhöhten Cholesterinwerten.\n" +
                    "Da Sport nicht sehr beliebt ist, kann das die Entstehung von Diabetes zur Folge haben. Um\n" +
                    "die Gesundheit zu fördern und den Stoffwechsel in Schwung zu bringen, sind regelmäßige\n" +
                    "Bewegungseinheiten erforderlich. Wegen seiner hohen Aufnahmefähigkeit im geistigen\n" +
                    "Sinne, sollte er beim Essen etwas zurückhaltender sein und sich den Spruch zu Herzen\n" +
                    "nehmen: Weniger ist mehr!"
                ]

            });
        }
        else if (this.state.eomType.indexOf("G") !== -1) {
            this.setState({
                eomText: [
                    "ist ein Macher mit Durchsetzungsvermögen und Ausdauer. Sein Mut und Fleiß\n" +
                    "führen in den meisten Fällen zum Erfolg. Dabei spielt Status eine Rolle und er kann einen\n" +
                    "gewissen Dominanzanspruch entwickeln. Leistungsfähigkeit und Kraft können Typ G dazu\n" +
                    "verleiten, die eigenen Grenzen zu übersehen. Sowohl beim Essen, als auch beim Sport oder\n" +
                    "der Arbeit neigt er gerne zum Übertreiben. Dabei hat er wenig körperliche Ansprüche und\n" +
                    "klagt selten über Beschwerden. Dank seiner Selbstbestimmung und Durchsetzungskraft kann\n" +
                    "Typ G bei entsprechenden Gegenmaßnahmen wiederum schnell regenerieren.",

                    "Das größte Risiko bei diesem Typ sind ohne Gegenmaßnahmen (oft stille) entzündliche\n" +
                    "Erkrankungen. Darunter fallen unter anderem Wohlstandserkrankungen wie Diabetes und\n" +
                    "Herz-Kreislauf-Erkrankungen. Da bei Typ G die Hitze dominiert, können dieser Konstitution\n" +
                    "kühlende Lebensmittel entgegenwirken. Auszeiten sowie regelmäßige Fasten- und\n" +
                    "\n" +
                    "Bewegungsprogramme können das aktive Leben von Typ G den besten Ausgleich und\n" +
                    "nachhaltig gesundheitliche Vorteile bringen."
                ]
            });
        }
    }

    eomColor=()=> {
        if (this.state.eomType.indexOf("A") !== -1) {
            return(
                "eom-a"
            )
        }
        else if (this.state.eomType.indexOf("B") !== -1) {
            return(
                "eom-b"
            )
        }
        else if (this.state.eomType.indexOf("C") !== -1) {
            return(
                "eom-c"
            )
        }
        else if (this.state.eomType.indexOf("D") !== -1) {
            return(
                "eom-d"
            )
        }
        else if (this.state.eomType.indexOf("E") !== -1) {
            return(
                "eom-e"
            )
        }
        else if (this.state.eomType.indexOf("F") !== -1) {
            return(
                "eom-f"
            )
        }
        else if (this.state.eomType.indexOf("G") !== -1) {
            return(
                "eom-g"
            )
        }
    };

    render() {
        return (
            <div className={"app-wrapper"}>
                <main className={"main"}>
                    <AppBarComp appUserLoggedIn={this.props.appUserLoggedIn} handleLogout={this.props.handleLogout}/>
                    <article className={"article article--with-stripe"}>

                        {(this.state.eomType === null || this.state.eomType === undefined || this.state.eomType === "initial state") &&
                        <DoEomFirst />
                        }

                        {(this.state.eomType !== null && this.state.eomType !== undefined && this.state.eomType !== "initial state") &&

                        <div>
                            <div className={this.eomColor()}>
                                <div className={"rezepte-colored-stripe"}/>
                            </div>
                            <div className={"article-inner"}>
                                <h1 className={"h1--lined--dark"} style={{marginTop: "20px"}}>
                                    Typisierung
                                </h1>
                            </div>

                            <div className={"article-inner"}>
                                <p>Anhand deiner Körperreaktionen wird die Typisierung A bis G erfasst, die dir aufzeigt, in
                                    welchem Gesamtzustand du dich momentan befindest und zu welchen Erkrankungsmustern
                                    du neigst. Die Ausprägung von 1 bis 3 zeigt an, wie weit du dich von deiner Mitte und somit
                                    aus deiner inneren Balance entfernt hast. Je näher du der Mitte bist, desto gesünder bist du.</p>
                            </div>

                            <div className={"article-inner"}>
                                <img src={CircleLarge} alt={"circle-large"} style={{width: "100%"}}/>
                            </div>

                            <div className={"article-inner"}>
                                <p>Durch die Typisierung kann ermittelt werden, mit welchen Lebensmitteln du am besten deinen
                                    momentanen Zustand ausgleichen und somit deine Gesundheit fördern kannst.</p>
                                <p>Jeder Mensch ist mehr oder weniger ein Mischtyp, d.h. nicht alle beschriebenen Eigenschaft
                                    müssen unbedingt auf dich zutreffen. Deine Analyse zeigt auf, welche Typisierung derzeitig
                                    vorherrscht und somit als Maßgabe für die empfohlenen Lebensmittel dient.</p>
                                <p>Bei der Beschreibung der einzelnen „Typen“ wird zur einfachen Lesbarkeit bewusst auf ein
                                    Gendering verzichtet!</p>

                                {this.state.eomText.map((paragraph, index) => index === 0 ? (
                                        <p key={index}>Der <strong>Typ {this.state.eomType.substring(0, this.state.eomType.length - 1)} </strong> {paragraph}</p>
                                    ) : (
                                        <p key={index}>{paragraph}</p>
                                    )
                                )}
                            </div>

                            {/*{this.renderTipps()}*/}

                            <br/>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                        }
                    </article>
                </main>

                <EomSnackbar/>
            </div>
        );
    }
}

export default Type;

